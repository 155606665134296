import { render, staticRenderFns } from "./InputInsertentity.vue?vue&type=template&id=010278b8&scoped=true&"
import script from "./InputInsertentity.vue?vue&type=script&lang=js&"
export * from "./InputInsertentity.vue?vue&type=script&lang=js&"
import style0 from "./InputInsertentity.vue?vue&type=style&index=0&id=010278b8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010278b8",
  null
  
)

export default component.exports