<template>
    <div class="relation-recommend-item">
        <div class="relation-recommend-switch-outer">
            <div class="relation-recommend-switch">
                <span class="relation-recommend-switch-pre">{{$t('flowComponent.isOpenRecommend')}}</span>
                <span class="relation-recommend-switch-after">{{$t('flowComponent.noSupportMini')}}</span>
            </div>
            <!-- <el-switch
                size="mini"
                @change="changeStatus"
                v-model="unit.content.recommend.status"
                active-color="#366AFF"
                inactive-color="#C0C4CC"
            >
            </el-switch> -->
            <div class="icon-switch-box-true" v-if="unit.content.recommend.status" @click.stop="
                changeStatus(false)
            ">
                <i class="el-icon-check"></i>
                <div></div>
            </div>
            <div class="icon-switch-box-false false" v-if="!unit.content.recommend.status" @click.stop="
                changeStatus(true)
            ">
                <div></div>
                <i class="el-icon-close"></i>
            </div>
        </div>
        <div  v-if="unit.content.recommend.status" class="relation-recommend-content">
            <div class="relation-recommend-content-des">
                <div class="relation-recommend-content-des-title">
                  {{$t('flowComponent.recommendDes')}}
                </div>
                <el-input size="small" ref="recommendfocus" v-model="unit.content.recommend.description"
                    :placeholder="$t('flowComponent.recommendDesPla')"></el-input>
            </div>
            <div v-if="unit.content.recommend.list.length != 0" class="relation-recommend-content-title">
                <span class="relation-recommend-content-title-pre">{{$t('flowComponent.associationIntention')}}</span>
                <span class="relation-recommend-content-title-aft">{{$t('flowComponent.recommendText')}}</span>
            </div>

            <div :class="['relation-recommend-content-list', 'relation-recommend-content-list' + index]">
                <div 
                    v-for="(recommendIntent, recommendIntentIndex) in unit.content.recommend.list" 
                    :key="recommendIntentIndex" 
                    class="relation-recommend-content-item">
                    <div :class="['relation-recommend-content-item-sort', 'relation-recommend-content-item-sort' + index]">
                        <i class="iconfont guoran-tongyichicun-16-10-paixu icon-handle-card"></i>
                    </div>
                    <div class="relation-recommend-content-item-intent">
                        <el-cascader 
                            v-if="!isBotLink" 
                            size="small"
                            :show-all-levels="true" 
                            :key="'rri' + index + recommendIntentIndex"
                            @change="intentSelectedValue($event,recommendIntentIndex)"
                            v-model="recommendIntent.ext.intentValue" 
                            :placeholder="$t('botIntent.searchOrSelectIntent')"
                            :options="intentSAkillTypeOptionsNoChildren"
                            filterable>
                        </el-cascader>
                        <template v-else>
                            <el-popover
                                :key="'rri' + index + recommendIntentIndex"
                                :ref="'popover_' + index + recommendIntentIndex"
                                placement="bottom-start"
                                width="300"
                                trigger="click"
                                popper-class="set-welcome-message-search-intent-poprver"
                                :visible-arrow="false"
                                @show="showPover(recommendIntent)">
                                <el-input
                                    class="search-intent-input"
                                    size="small"
                                    :placeholder="$t('botIntent.searchOrSelectIntent')"
                                    v-model="recommendIntent.ext.intentValueText"
                                    slot="reference"
                                    @input="inputFn($event,recommendIntent)">
                                    <i slot="suffix" class="el-icon-arrow-down"></i>
                                </el-input>
                                 <!-- v-show="recommendIntent.ext.chooseIntentVisible" -->
                                <el-tree
                                    :key="'rri' + index + recommendIntentIndex"
                                    class="choose-intent-tree"
                                    style="width:300px;height:300px;overflow-x:hidden;overflow-y:auto;"
                                    :data="intentSAkillTypeOptionsNoChildren"
                                    :props="{
                                        children: 'children',
                                        label: 'name',
                                        id:'id',
                                        isLeaf: 'isLeafNode',
                                    }"
                                    :filter-node-method="filterNode"
                                    node-key="id"
                                    ref="tree">
                                    <span :class="['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick(data,node,recommendIntent,recommendIntentIndex,index)">
                                        <span class="custom-tree-node-left">
                                            <span :class="['data-label']">{{ data.name }}</span>
                                        </span>
                                        <i class="el-icon-check" v-if="data.checked"></i>
                                    </span>
                                </el-tree>
                            </el-popover>
                        </template>
                    </div>
                    <div class="relation-recommend-content-item-text-type">
                        <el-select size="small" v-model="recommendIntent.type" placeholder="请选择" @change="
                            recommendIntentCypeChange(
                                $event,
                                recommendIntentIndex
                            )
                        ">
                            <el-option v-for="item in [
                                { value: 0, label: '自定义' },
                                { value: 1, label: '跟随意图' },
                            ]" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="relation-recommend-content-item-text">
                        <el-input size="small" :disabled="recommendIntent.type == 1" v-model="recommendIntent.text"
                            :placeholder="$t('common.inputPlaceholder')"></el-input>
                    </div>
                    <div class="relation-recommend-content-item-handle">
                        <span class="answer-text-cell-handle-icon-delete" @click="deleteText(recommendIntentIndex)">
                            <i class="el-icon-close"></i></span>
                        <!-- <span v-show="recommendIntentIndex == 0" class="answer-text-cell-handle-icon-add"
                            @click="addText">
                            <i class="el-icon-plus"></i>
                        </span> -->
                    </div>
                </div>
                <div class="add-rrcl">
                    <span @click="addText" class="add-rrcl-btn"><i class="add-text el-icon-plus"></i></span>
                </div>
            </div>
            <div v-if="unit.content.recommend.list.length == 0" class="empty-intent-r">
                {{$t('flowComponent.noRecommendSet')}}
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from "sortablejs";
export default {
    props: ["unit", "intentSAkillTypeOptionsNoChildren", "index","isBotLink"],
    data() {
        return {
            isCheck:true,
        };
    },
    // watch: {
    //     intentSAkillTypeOptionsNoChildren:{
    //         handler(n){
    //             console.log(n,'nnnnn');
    //         },
    //         deep:true,
    //         immediate:true
    //     },
    //     unit:{
    //        handler(n){
    //             console.log(n,'unit');
    //         },
    //         deep:true,
    //         immediate:true 
    //     }
    // },
    methods: {
        rowDrop() {
            console.log(106, this.index);
            const box = document.querySelector(".relation-recommend-content-list" + this.index);
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".relation-recommend-content-item-sort" + this.index,
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit
                        .content.recommend.list.splice(oldIndex, 1);
                    _this.unit
                        .content.recommend.list.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.unit.content.recommend.list = [..._this.unit.content.recommend.list];
        },
        changeStatus(val) {
            console.log(val);
            this.$set(this.unit.content.recommend, 'status', val);
            if (val) {
                this.$nextTick(() => {
                    this.$refs.recommendfocus.focus();
                    this.rowDrop();
                })
            }
        },
        intentSelectedValue($event, recommendIntentIndex) {
            this.unit.content.recommend.list[recommendIntentIndex].id =
                $event[1];
            if (
                this.unit.content.recommend.list[recommendIntentIndex].type ==
                "1"
            ) {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    this.filterRecoText(
                        this.unit.content.recommend.list[recommendIntentIndex]
                            .id
                    );
            } else {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    "";
            }
        },
        recommendIntentCypeChange(val, recommendIntentIndex) {
            this.unit.content.recommend.list[recommendIntentIndex].type = val;
            if (val == "1") {
                if (
                    this.unit.content.recommend.list[recommendIntentIndex].id !=
                    ""
                ) {
                    this.unit.content.recommend.list[
                        recommendIntentIndex
                    ].text = this.filterRecoText(
                        this.unit.content.recommend.list[recommendIntentIndex]
                            .id
                    );
                } else {
                    this.unit.content.recommend.list[
                        recommendIntentIndex
                    ].text = "";
                }
            } else {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    "";
            }
        },
        filterRecoText(id) {
            console.log(id,'id');
            let name = "";
            if(this.isBotLink){
                this.intentSAkillTypeOptionsNoChildren.forEach(botItem => {
                    botItem.children.forEach(skillItem => {
                        skillItem.children.forEach(typeItem => {
                            typeItem.children.forEach(intentItem => {
                                if(id == intentItem.id){
                                    name = intentItem.exampleQuestion == "" ? intentItem.label : intentItem.exampleQuestion;
                                } 
                            })
                        })
                    })
                })
            } else {
                this.intentSAkillTypeOptionsNoChildren.forEach((item) => {
                    item.children.forEach((intent) => {
                        if (intent.id == id) {
                            name =
                                intent.exampleQuestion == ""
                                    ? intent.label
                                    : intent.exampleQuestion;
                        }
                    });
                });
            }
            
            return name;
        },
        // 添加文本答案输入框
        addText(index) {
            this.unit.content.recommend.list.push({
                id: "",
                type: 0,
                text: "",
                ext: {
                    intentValue: [],
                },
            });
            this.$nextTick(() => {
                this.rowDrop();
            })
        },
        // 删除文本答案输入框
        deleteText(index) {
            this.unit.content.recommend.list.splice(index, 1);
        },
        // 判断是否还是初始化推荐
        isInitRec(rec) {
            let flag = true;
            if (rec.description != "") {
                flag = false;
            } else {
                if (rec.list.length != 0) {
                    rec.list.forEach((item) => {
                        if (item.id != "" || item.text != "") {
                            flag = false;
                        }
                    });
                }
            }
            return flag;
        },
        inputFn(val,item){
            this.$refs.tree[0].filter(val);
            this.$forceUpdate()
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick(data,node,item,recommendIntentIndex,index){
            if(data.id.indexOf('B') > -1 || data.id.indexOf('S') > -1 || data.id.indexOf('T') > -1 ) return false;
            this.$set(item.ext,'intentValueText',data.name);
            this.$set(item.ext,'intentValue',[node.parent.parent.parent.data.id,node.parent.parent.data.id,node.parent.data.id,data.id])
            this.$set(item,'id',data.id);
            this.$set(data,'checked',true);
            this.$set(item,'apiKey',data.apiKey)
            this.$refs['popover_' + index + recommendIntentIndex][0].doClose()
            this.intentSelectedValue([node.parent.data.id,data.id],recommendIntentIndex);
        },
        showPover(recommendIntent){
            let list = JSON.parse(JSON.stringify(this.intentSAkillTypeOptionsNoChildren))
            list.forEach(botItem => {
                console.log(botItem,'botItem');
                botItem.children.forEach(skillItem => {
                    skillItem.children.forEach(typeItem => {
                        typeItem.children.forEach(intentItem => {
                            intentItem.checked = false;
                            if(recommendIntent.id == intentItem.id){
                                intentItem.checked = true;
                            } 
                        })
                    })
                })
            })
            this.intentSAkillTypeOptionsNoChildren =  JSON.parse(JSON.stringify(list))
        },
        // 点击prover元素时绑定click事件,以此来实现点击除prover元素之外的区域隐藏prover
        // hiddeenProver(){
        //     document.onclick =(e) => {
        //         let clickDom = e.target;
        //         console.log(Array.from(clickDom.parentNode.classList).indexOf('search-intent-input') !== -1);
        //         if(Array.from(clickDom.parentNode.classList).indexOf('search-intent-input') !== -1){
        //            this.$set( this.unit.content.recommend.list[this.activeIndex].ext,'chooseIntentVisible',true);
        //         } else {
        //             this.unit.content.recommend.list.forEach(item => {
        //                 this.$set(item.ext,'chooseIntentVisible',false);
        //             })
        //         }
        //         console.log(this.unit,'document.onclick');
        //         this.$forceUpdate()
        //     }
        // }
    },
    mounted(){
        this.isCheck = false;
        // this.hiddeenProver();
        this.rowDrop();
    }
};
</script>
 <style lang="less">
.el-popover.set-welcome-message-search-intent-poprver{
    z-index: 3333 !important;
    .el-tree{
        overflow-y: auto;
        color: #606266;
        .el-tree-node__content{
            height: 33px!important;
            display: flex;
            align-items: center;
        }
        .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer !important;
            &.disabled{
                cursor: not-allowed !important;
            }
            .data-label{
               &.checked{
                    color: #366AFF
                } 
            }
            .el-icon-check{
                color: #366AFF
            }
        }
    }
}
</style>
<style lang="less" scoped>
@import "./../../../../assets/less/main/new_ui.less";

.relation-recommend-item {
    margin-top: 8px;
    color: #616161;
    background-color: #fbfcfd;

    // .icon-switch-box{
    //     div{
    //         width: 14px;
    //         height: 14px;
    //         background: #FFFFFF;
    //         box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.18);
    //         border-radius: 50%;
    //         // margin-left: 5px;
    //     }
    //     &.false{
    //         background-color: rgb(226, 226, 226);
    //         color: #6E6B7B;
    //     }
    // }

    .icon-switch-box-true {
        display: none;
        height: 24px;
        background: #366AFF;
        border-radius: 12px;
        padding: 0 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #fff;
        font-size: 16px;

        div {
            width: 14px;
            height: 14px;
            background: #FFFFFF;
            box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.18);
            border-radius: 50%;
            margin-left: 5px;
        }
    }

    .icon-switch-box-false {
        display: none;
        height: 24px;
        background: #366AFF;
        border-radius: 12px;
        padding: 0 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        background-color: rgb(226, 226, 226);
        color: #6E6B7B;

        div {
            width: 14px;
            height: 14px;
            background: #FFFFFF;
            box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.18);
            border-radius: 50%;
            margin-right: 5px;
        }
    }

    .relation-recommend-switch-outer {
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        height: 39px;
        line-height: 39px;
        align-items: center;

        .relation-recommend-switch {
            .relation-recommend-switch-pre {
                margin-right: 4px;
                color: #616161;
            }

            .relation-recommend-switch-after {
                margin-left: 10px;
                color: #a9b3c6;
                font-size: 13px;
                margin-right: 8px;
            }
        }
    }

    .relation-recommend-content {
        border-top: dashed 1px #e0e6f7;
        padding: 12px;

        .relation-recommend-content-des {
            .relation-recommend-content-des-title {
                text-align: left;
                margin-bottom: 8px;
            }
        }

        .relation-recommend-content-title {
            display: flex;
            justify-content: flex-start;
            text-align: left;
            margin-top: 12px;
            margin-bottom: 4px;

            .relation-recommend-content-title-pre {
                flex: none;
                width: 160px;
                margin-left: 28px;
            }
        }

        .bottom-plus-btn {
            margin-top: 8px;
            font-size: 26px;
        }

        .empty-intent-r {
            margin-top: 16px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
        }

        .relation-recommend-content-list {
            margin-top: 4px;

            .add-rrcl {
                display: flex;
                justify-content: center;

                .add-rrcl-btn {
                    flex: none;
                    margin-top: 10px;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    color: #749bff;
                    text-align: center;
                    background: #ffffff;
                    border: 1px solid rgba(116, 155, 255, 0.4);
                    border-radius: 50%;
                    margin-right: 4px;
                    cursor: pointer;

                    i {
                        color: #366aff;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .relation-recommend-content-item {
                margin-top: 4px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .relation-recommend-content-item-sort {
                    text-align: left;

                    i {
                        margin-right: 12px;
                        color: #366aff;
                        cursor: pointer;
                    }
                }

                .relation-recommend-content-item-intent {
                    flex: none;
                    width: 146px;
                     /deep/.el-input .el-input__suffix .el-icon-arrow-down{
                        line-height: 32px;
                    }
    
                }

                .relation-recommend-content-item-text-type {
                    margin-left: 14px;
                    width: 100px;
                    flex: none;
                }

                .relation-recommend-content-item-text {
                    flex: auto;
                    margin-left: 14px;
                }

                .relation-recommend-content-item-handle {
                    flex: none;
                    display: flex;
                    align-items: center;

                    .answer-text-cell-handle-icon-delete-inner {
                        margin-left: 6px;
                        margin-right: -6px;

                        i {
                            color: #366aff;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
</style>