<template>
    <div class="select-addnode">
        <el-select
            v-model="nodeId"
            :popper-append-to-body="false"
            filterable
            size="small"
            @visible-change="
                (v) => visibleChange(v, nodeSelectARef, cascaderClick)
            "
            @focus="nodeSelectFocus"
            @blur="nodeSelectBlur"
            @change="changeSelectNode"
            :ref="nodeSelectARef"
            :placeholder="$t('flowComponent.webhook.searchNodeName')"
        >
            <el-option
                v-for="item in nodeOptions"
                :key="item.id"
                :popper-class="'pop_my' + item.classPop + item.parentNum"
                :label="item.name"
                :value="item.id"
            ></el-option>
        </el-select>
        <popup v-if="addnodeDia" @closeEvent="addnodeDia = false">
            <div slot="popup-name">{{$t('flowComponent.webhook.createNode')}}</div>
            <div slot="popup-tip">{{$t('flowComponent.webhook.createNodeTip')}}</div>
            <div slot="popup-con">
                <div class="add-node-cell">
                    <span class="add-node-cell-title">
                        <span class="add-node-cell-title-pre">*</span>
                        {{$t('flowComponent.webhook.nodeName')}}
                    </span>
                    <div class="add-node-cell-content">
                        <el-input
                            size="small"
                            ref="selectAddNodeFocus"
                            v-model="nodePreName"
                            :placeholder="$t('common.inputPlaceholder')"
                        ></el-input>
                    </div>
                </div>
                <div class="add-node-cell">
                    <span class="add-node-cell-title"> {{$t('flowComponent.webhook.nodePre')}} </span>
                    <div class="add-node-cell-content">
                        <el-select
                            v-model="nodeParentId"
                            :popper-append-to-body="false"
                            size="small"
                            :placeholder="$t('flowComponent.webhook.nodePre')"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :key="item.id"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="addnodeDia = false" plain size="small"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" @click="saveAddNode" size="small"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
export default {
    // nodeId 选择节点Id
    // nodeSelectARef select ref 由于使用于下拉选输入回显dom选择，需保证页面中存在唯一性
    // nodeOptions 节点树 JSON
    // intentId 当前创建节点意图Id
    // 元数据定位 index 用于更新父节点数据
    // defaultParentNodeId 默认父节点
    props: [
        "nodeId",
        "nodeSelectARef",
        "nodeOptions",
        "intentId",
        "index",
        "defaultParentNodeId",
        "isBotLink"
    ],
    components: { popup },
    data() {
        return {
            addnodeDia: false,
            nodePreName: "",
            nodeParentId: "",
        };
    },
    mounted() {},
    methods: {
        changeSelectNode(value) {
            this.$emit("setAddNodeId", value, this.index);
        },
        // 保存创建节点
        saveAddNode() {
            if (this.nodePreName.trim().length === 0) {
                this.$message.error(this.$t('flowComponent.webhook.nodeNameRequired'));
            } else {
                this.FetchPost(
                    this.requestUrl.chatFlowNode.addNode,
                    {
                        intentId: this.intentId,
                        name: this.nodePreName.trim(),
                        parentId: this.nodeParentId,
                    },
                    { emulateJSON: true }
                ).then((res) => {
                    if (res.code === "0" || res.code === "OPMANYREQ") {
                        this.$message.success(this.$t('flowComponent.webhook.saveNodeSuccess'));
                        this.addnodeDia = false;
                        // 用于更新父节点节点树数据
                        this.$emit("getNodeOptions");
                        this.$nextTick(() => {
                            // 用于更新父节点数据
                            this.$emit("setAddNodeId", res.data, this.index);
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        /**
         * 为element-ui的Select和Cascader添加弹层底部操作按钮
         * @param visible
         * @param refName  设定的ref名称
         * @param onClick  底部操作按钮点击监听
         */
        visibleChange(visible, refName, onClick) {
            if (visible && !this.isBotLink) {
                const ref = this.$refs[refName];
                let popper = ref.$refs.popper;
                if (popper.$el) popper = popper.$el;
                if (
                    !Array.from(popper.children).some(
                        (v) => v.className === "el-cascader-menu__list"
                    )
                ) {
                    const el = document.createElement("ul");
                    el.className = "el-cascader-menu__list";
                    el.style =
                        "border-top: solid 1px #E4E7ED; padding:0; color: #606266;";
                    el.innerHTML = `<li class="el-cascader-node el-cascader-node-createnode" style="height:38px;line-height: 38px; padding: 0 16px;color: #366aff"><span class="el-cascader-node__label" style="${this.$i18n.locale === 'en' ? 'width:90px' : ''}">${this.$t('flowComponent.webhook.createNode')}</span> <span ref="ecn_prename" style="color: #366aff" class="el-cascader-node__prename ${this.nodeSelectARef}">${this.nodePreName}</span></li>`;
                    popper.appendChild(el);
                    el.onclick = () => {
                        // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
                        onClick && onClick();

                        // 以下代码实现点击后弹层隐藏 不需要可以删掉
                        if (ref.toggleDropDownVisible) {
                            ref.toggleDropDownVisible(false);
                        } else {
                            ref.visible = false;
                        }
                    };
                }
            }
        },
        cascaderClick() {
            this.nodeParentId = this.defaultParentNodeId;
            this.addnodeDia = true;
            this.$nextTick(() => {
                this.$refs.selectAddNodeFocus.focus();
            })
        },
        nodeSelectFocus(e) {
            setTimeout(() => {
                this.addNodeTimer = setInterval(() => {
                    let el = document.getElementsByClassName(
                        this.nodeSelectARef
                    )[0];
                    this.nodePreName =
                        this.$refs[
                            this.nodeSelectARef
                        ].$children[0].$refs.input.value;
                    el ? el.innerHTML = `<span>${this.nodePreName}</span>` : el.innerHTML = '';
                }, 500);
            }, 1);
        },
        nodeSelectBlur() {
            clearTimeout(this.addNodeTimer);
        },
    },
};
</script>
<style lang="less" scoped>
.select-addnode {
  flex: 1;
    .el-select {
        width: 100%;
    }
    .add-node-cell {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        /deep/.el-input__inner {
            border: 1px solid #dcdfe6 !important;
            padding-right: 0;
        }
        /deep/.el-input__inner:focus {
            border: 1px solid #366aff !important;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: block !important;
        }
        .add-node-cell-title {
            margin-bottom: 6px;
            text-align: left;
            .add-node-cell-title-pre {
                color: red;
            }
        }
        .add-node-cell-content {
            /deep/.el-select {
                width: 100%;
            }
        }
    }
    /deep/.el-cascader-node-createnode {
        display: flex !important;
        justify-content: flex-start !important;
        .el-cascader-node__label {
            flex: none;
            width: 60px;
            color: #366aff;
            padding: 0;
        }
        .el-cascader-node__prename {
            height: 38px;
            flex: auto;
            text-align: left;
            padding-left: 16px;
        }
    }
}
</style>