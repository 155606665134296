function blogScope(webhookId, value, scope) {

    if (scope == null || scope.length != 2) {
        return false;
    }
    let scopeWbId = scope[0];
    let scopeVal = scope[1];

    return scopeWbId === webhookId && value.indexOf(scopeVal) === 0 && value!==scopeVal;
}

const filterSingleWithScope = function (list, scope) {

    if (list == null || list.length === 0) {
        return list;
    }

    let newList = [...list];


    console.debug('newList', newList);
    for (let i = 0, len = newList.length; i < len; i++) {
        let configs = newList[i].configs;
        if (configs != null && configs.length > 0) {
            let newConfigs = [];
            let children = [];
            for (let j = 0; j < configs.length; j++) {
                let webhookId = newList[i].id;
                if (configs[j] == null) {
                    continue;
                }
                // 属于作用域 或者 是 单值的
                if (configs[j].multivalued === false || blogScope(webhookId, configs[j].value, scope)) {
                    newConfigs.push(configs[j]);
                    children.push(configs[j]);
                }
            }
            newList[i].configs = newConfigs;
            newList[i].children = newConfigs;
        }
    }

    return newList;
};


export {
    filterSingleWithScope
}