<template>
    <div class="ck-rich-text">
        <!-- <span @click="templateConfigVisibleHandle('usual-richtext-placeholder')">插入变量</span> -->
        <ckeditor :editor="ckeditor.editor" v-model="unit.content.html" :config="desEditorConfig" @ready="readyFn"
            @focus="onEditorFocus" :ref="'auRichCkeditor_' + index">
        </ckeditor>
        <!-- <filelist :attachmentList="attachmentList" @previewImg="previewDescImgList" @attachDeleteAttch="attachDeleteAttch">
        </filelist> -->
        <div @click="showStopTip" v-show="index == aiLearningIndex && !inserting" class="ask-crt-mode">
            <div v-show="!inserting" class="ask-crt-mode-loading">
                <img src="https://static.guoranbot.com/cdn-lightning-web/dev/img/loading.3ab3bd5a.gif" alt="" srcset="">
              {{$t('flowComponent.answerLoading')}}
            </div>
        </div>
        <div @click="showStopTip" v-show="index == aiLearningIndex && inserting" class="ask-crt-mode-inserting">
        </div>

        <popup v-if="insertLinkDialog" @closeEvent="insertLinkDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('flowComponent.richTextLink')}}</div>
            <div slot="popup-con">
                <div class="crt-insert-link-cell">
                    <span class="insert-link-cell-title">{{$t('flowComponent.linkUrl')}}：</span>
                    <el-input size="small" :placeholder="$t('flowComponent.linkPla')" v-model="insertLinkHref">
                    </el-input>
                </div>
                <div class="crt-insert-link-cell">
                    <span class="insert-link-cell-title">{{$t('flowComponent.linkTitle')}}：</span>
                    <el-input size="small" :placeholder="$t('flowComponent.linkTitlePla')" v-model="insertLinkName">
                    </el-input>
                </div>
                <div class="crt-insert-link-cell">
                    <span class="insert-link-cell-title">{{$t('flowComponent.openType')}}：</span>
                    <el-select size="small" v-model="insertLinkTarget" :placeholder="$t('common.selectPlaceholder')">
                        <el-option v-for="item in targetOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="insertLinkDialog = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" size="small" @click="saveInsertLink">{{$t('common.save')}}</el-button>
            </div>
        </popup>
        <div v-if="emojiBoxVisible" id="ask-emoji-box-i" v-clickoutside:[classList]="closeIconBlock" class="ask-emoji-box">
            <el-tabs size="mini" type="card">
                <el-tab-pane v-for="item in askEmojiArr" :key="item.name" :label="item.name">
                    <div class="ask-emoji-type-box">
                        <span class="ask-emoji-type-b-cell" v-for="(cell, cellIndex) in item.iconArr" :key="cellIndex"
                            @click="selectEmoji(cell.icon)">{{ cell.icon }}</span>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};
// item.sendNotify.groupMsgNotify.title
import { MyCustomUploadAdapterPlugin } from "./js/ckeditor";
import MyFileUpload from './js/plugin_file';
import ResButton from './js/plugin-response-button/responseButton';
import InsertLink from "./js/plugin-insert-link/responseButton";
import InsertEmoji from "./js/plugin-ask-emoji/responseButton";
import CKEDITOR from "ckeditor";
import filelist from "./fielListView.vue";
import TurndownService from 'turndown';
import { v4 as uuidv4 } from "uuid";
import { multipartUpload, ossFileUrl } from "./js/AliyunIssUtil";

import { emojiArr } from "./js/emojiArr.js";

import Popup from "./../../../../components/popup";
const turnDownService = new TurndownService();
export default {
    props: ["unit", "index", "aiLearningIndex", "intentId"],
    components: { filelist, Popup },
    data() {
        return {
            insertLinkDialog: false,
            insertLinkName: "",
            insertLinkHref: "",
            insertLinkTarget: "_blank",
            targetOptions: [{
                value: '_blank',
                label: '新窗口'
            }, {
                value: '_self',
                label: '当前窗口'
            }],
            askEmojiArr: [],
            emojiBoxVisible: false,
            classList: ['ask-emoji-box-i'],

            markdown: '',
            currentEditorPosition: "",
            currentEditorId: "",
            ckeditor: {
                editor: CKEDITOR.ClassicEditor,
            },

            desEditorConfig: {
                shouldNotGroupWhenFull: false,
                // extraPlugins: [MyFileUpload, MyCustomUploadAdapterPlugin, ResButton, InsertLink, InsertEmoji],
                extraPlugins: [MyFileUpload, MyCustomUploadAdapterPlugin, ResButton, InsertLink, InsertEmoji],
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        'fontfamily',
                        'fontsize',
                        'alignment',
                        "insertEmoji",
                        "fontColor",
                        "fontBackgroundColor",
                        "italic",
                        "insertLinkButton",
                        // "|",
                        // 'link',
                        "imageUpload",
                        'responseButton',
                        'MyFileUpload',
                        "bulletedList",
                        "numberedList",
                        "Undo",
                        "Redo",
                    ],
                    shouldNotGroupWhenFull: true
                },
                // toolbar: [
                //     "heading",
                //     "|",
                //     "bold",
                //     'fontfamily',
                //     'fontsize',
                //     'alignment',
                //     'link',
                //     // "insertEmoji",
                //     "fontColor",
                //     "fontBackgroundColor",
                //     // "insertLinkButton",
                //     "imageUpload",
                //     'responseButton',
                //     '-',
                //     'MyFileUpload',
                //     "bulletedList",
                //     "numberedList",
                //     "Undo",
                //     "Redo",
                //     '-',
                //     "italic",
                // ],
                fontFamily: {
                    options: [
                        // 'default',
                        '宋体',
                        '新宋体',
                        '仿宋',
                        '楷体',
                        '微软雅黑',
                        '黑体',
                        '华文仿宋',
                        '华文楷体',
                        '华文隶书',
                        '华文宋体',
                        '华文细黑',
                        '华文新魏',
                        '华文行楷',
                        '华文中宋',
                        // '隶书',
                        // '苹方 常规',
                        // '幼圆',
                    ],
                },
                language: 'zh',
                askPluginListener: [
                    {
                        event: 'INSERTLBUTTON',
                        process: this.showInsertLinkDia,
                    }, 
                    {
                        event: 'INSERTEMOJIBUTTON',
                        process: this.showInsertEmojiDia,
                    },
                    {
                        event: 'UPLOAD',
                        process: this.desUploadImageAndFile,
                    }, {
                        event: 'BUTTON',
                        process: this.templateConfigVisibleHandle,
                    },]
            },

            attachmentList: [],
            detailCurrentEditor: null,
            imgList: [],
            showPreview: false,

            // evtsource
            evtSource: null,
            evtSourceMessageDataList: [],
            evtSourceMessageDataListCopy: [],
            messageTimer: null,
            isStop: false,
            usedAIGC: false,
            onecEvent: '',

            inserting: false, // 是否已经开始生成
            ossConfig: {
                region: "oss-cn-zhangjiakou",
                //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
                accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
                accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
                // stsToken: '<Your securityToken(STS)>',
                bucket: "guoranopen-zjk",
            }
        };
    },
    mounted() {
        console.log(emojiArr);
        this.askEmojiArr = emojiArr;
        setTimeout(() => {
            console.log(this.$refs['auRichCkeditor_' + this.index].$el);
            // this.$refs['auRichCkeditor_' + this.index].$el.focus();
            this.detailCurrentEditor.editing.view.focus();
            setTimeout(() => {
                this.currentEditorId = currentEditor.id;
                this.currentEditorPosition = "end";
            }, 100);
        }, 330);
    },
    methods: {
        showInsertEmojiDia() {
            setTimeout(() => {
                this.emojiBoxVisible = true;
            }, 200);
            // let command = currentEditor.commands.get("insertAskComponent");
            // command.execute({
            //     tag: "span",
            //     options: {
            //         className: 'ask-span-emoji',
            //         name: "😄",
            //         data: "😄",
            //     },
            // });
        },
        closeIconBlock() {
            this.emojiBoxVisible = false;
        },
        selectEmoji(emoji) {
            this.detailCurrentEditor.model.change((writer) => {
                this.detailCurrentEditor.model.insertContent(writer.createText(emoji));
            });
            this.emojiBoxVisible = false;
        },
        showInsertLinkDia() {
            this.insertLinkDialog = true;
            this.insertLinkName = "";
            this.insertLinkHref = "";
            this.insertLinkTarget = "_blank";
        },
        saveInsertLink() {
            if (this.insertLinkHref.trim() == "") {
                this.$message.error("请输入链接地址");
            } else {
                let command = currentEditor.commands.get("insertAskComponent");
                command.execute({
                    tag: "a",
                    options: {
                        href: this.insertLinkHref.trim(),
                        name: this.insertLinkName.trim() == "" ? this.insertLinkHref.trim() : this.insertLinkName.trim(),
                        target: this.insertLinkTarget, // _blank _self
                    }
                });
                this.insertLinkDialog = false;
            }
        },
        onEditorFocus(zh, editor) {
            this.detailCurrentEditor = editor;
            this.currentEditor = editor;
            changeEditor(editor);
        },
        templateConfigVisibleHandle(type) {
            console.log(this.index, this.$refs['auRichCkeditor_' + this.index]);
            this.$refs['auRichCkeditor_' + this.index].$el.focus();
            this.detailCurrentEditor.editing.view.focus();
            // 获取当前editor光标位置
            this.currentEditorPosition = currentEditor.model.document.selection.getFirstPosition();
            // 1 聚焦当前编辑文本答案文本框 变更currentEditor
            this.$refs['auRichCkeditor_' + this.index].$el.focus();
            this.$refs['auRichCkeditor_' + this.index].$el.blur();
            setTimeout(() => {
                // 如果当前 currentEditor 也是上一次编辑 currentEditor 设置因为聚焦改变的光标位置为已记录的最后光标位置
                if (currentEditor.id === this.currentEditorId) {
                    currentEditor.model.change(writer => writer.setSelection(this.currentEditorPosition))
                } else {
                    // 如果当前 currentEditor 不是上一次编辑的 currentEditor 将光标定位至最后
                    currentEditor.model.change((writer) => {
                        writer.setSelection(writer.createPositionAt(currentEditor.model.document.getRoot(), 'end'))
                    })
                    this.currentEditorId = currentEditor.id;
                }

                let param = { type: 'usual-richtext-placeholder' };
                param.unitIndex = this.index;
                param.lastEditor = lastEditor;
                param.multiple = false;
                param.webhookId_key = [""];
                param.cellIndex = this.index;
                console.log(param);
                this.$emit("templateConfigVisibleHandle", param);
            }, 500);
        },
        addConfirmDataResponse() {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: "单个webhook返回值",
                    editable: false,
                    data: webHookDataHolder(51, "data.name"),
                },
            });
        },

        addWebHookSinglePlace(data, webhookId) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },
        addConfirmDataEntity(data, editable) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: entityDataHolder(data.name),
                },
            });
        },
        showStopTip() {
            this.$message({
                showClose: true,
                type: "warning",
                message: '请等待答案生成完成后再操作！'
            });
        },
        startEvtsourceFn() {
            this.$nextTick(() => {
                this.evtsourceFn();
            })
        },
        readyFn(editor) {
            this.detailCurrentEditor = editor;
            this.detailCurrentEditor.editing.view.document.on('clipboardInput', (evt, data) => {
                if (data.dataTransfer._native.effectAllowed === "move") { // 拖动阻止生成富文本内容
                    evt.stop();
                }
            })
        },

        desUploadImageAndFile({ type, param }) {
            console.log(type, param);
            if (param.type == 'error') {
                this.$message({
                    showClose: true,
                    type: "warning",
                    message: '请上传500M以内的文件！'
                });
            } else if (param.type == 'pending') {
                this.attachmentList.push({
                    url: '',
                    name: param.name,
                    uid: param.uid
                })
                let command = this.detailCurrentEditor.commands.get("insertAskComponent");
                command.execute({
                    tag: "section",
                    options: {
                        className: 'self-p-section',
                        data: 'self-p-section'
                    },
                });
                command.execute({
                    tag: "span-editable",
                    options: {
                        name: `${param.name}正在上传...`,
                        data: param.uid,
                        editable: false,
                        type: 'upload'
                    },
                })
            } else {
                let command = this.detailCurrentEditor.commands.get("insertAskComponent");
                command.execute({
                    tag: "section",
                    options: {
                        className: 'self-p-section',
                        data: 'self-p-section'
                    },
                });
                command.execute({
                    tag: "a",
                    options: {
                        href: param.url,
                        name: param.name,
                    },
                });
                this.attachmentList.forEach(item => {
                    if (item.uid == param.uid) {
                        item.url = param.url
                        delete item.uid
                    }
                })
                // 删除loading 节点
                this.delChildrenNode(param.uid)
            }
        },
        delChildrenNode(uid) {
            let root = this.detailCurrentEditor.model.document.getRoot()
            let children = root.getChildren()
            for (let child of children) {
                for (let index = 0; index < child._children._nodes.length; index++) {
                    if (child._children._nodes[index].name == 'askComponentPlaceholderContainer') {
                        if (child._children._nodes[index]._children._nodes[0]) {
                            let attrs = child._children._nodes[index]._children._nodes[0]._attrs
                            if (attrs) {
                                let attrsList = [...attrs.keys()]
                                for (let j = 0; j < attrsList.length; j++) {
                                    if (attrsList[j] == 'data') {
                                        if (attrs.get(attrsList[j]) == uid) {
                                            this.detailCurrentEditor.model.change(writer => {
                                                writer.remove(child._children._nodes[index].parent);
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        attachDeleteAttch(index) {
            this.attachmentList.splice(index, 1)
        },
        previewDescImgList(imgList, url) {
            let richtext = JSON.parse(JSON.stringify(this.content.content.html))
            this.imgList = imgList;
            richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                this.imgList.push(capture);
            });
            /*当前点击的图片作为第一个图片*/
            let index = this.imgList.indexOf(url);
            this.imgList.splice(index, 1);
            this.imgList.unshift(url);
            this.showPreview = true;
        },
        // eventSource处理
        evtsourceFn() {
            /*  id=uid，为第一次没有生成完成，下一次生成时带上uid，去做后续消息的关联
               id=finish，为标识当前这次是否生成完成，data不是stop，则出“继续生成”的按钮，参数带上uid
               id=[DONE]，标识这次请求结束，会关闭当前EventSource，前端可忽略，下次请求请重新调用
               id=chatcmpl-6rRcmIi9rShkKlQsjmhudO8dSfOh6类似值，为正常返回结果，需要将里面的content拼接起来显示。
           */
            this.evtSource && this.evtSource.close();
            this.evtSource = null;
            this.evtSourceMessageDataList = [];
            clearTimeout(this.messageTimer);
            this.messageTimer = null;
            this.onecEvent = 'isOpen';
            this.timeoutCreateData(true)
            this.isStop = false;

            let evtSourceUrl = "/api/llmgen/reply/stream?intentId=" + this.intentId;
            this.evtSource = new EventSource(evtSourceUrl, { withCredentials: true });
            this.evtSource.addEventListener("message", (event) => {
                this.isContinue = false;
                if (event.lastEventId === 'finish') {
                    let finishStatus = JSON.parse(event.data).content;
                    this.isStop = true;
                    console.log(finishStatus, 'finishStatus');
                    this.evtSource.close();
                    this.lastEventId = '';
                    this.isContinue = false;
                    this.onecEvent = 'isClose'
                    this.timeoutCreateData(false)
                } else if (event.lastEventId === 'exception') {
                    clearTimeout(this.messageTimer);
                    this.messageTimer = null;
                    this.lastEventId = '';
                    this.isContinue = false;
                    this.onecEvent = 'isClose'
                    this.timeoutCreateData(false)
                    this.messageFn('error', '请求异常,请重试!');

                } else {
                    let data = JSON.parse(event.data).content || ' ';
                    this.lastEventId = '';
                    this.evtSourceMessageDataListCopy.push(data);
                    let reg = /\n/g
                    let strList = data.match(reg)

                    this.isAiginWrite = false;
                    if (strList) {
                        for (let index = 0; index < data.length; index++) {
                            if (data[index] == '\n') {
                                this.getMessageList('<br/>')
                            } else {
                                this.getMessageList(data[index])
                            }
                        }
                    } else {
                        this.getMessageList(data);
                    }
                }
            });
            this.evtSource.onerror = this.errorFn;
        },
        getMessageList(data) {
            if (!this.isStop) {
                this.evtSourceMessageDataList.push(data);
            } else {
                this.evtSourceMessageDataList = [];
                this.evtSourceMessageDataListCopy = [];
                clearTimeout(this.messageTimer);
                this.messageTimer = null;
            }
        },
        timeoutCreateData(flag) {
            // 开起接收数据轮询，此时创建定时器 轮询evtSourceMessageDataList 数据
            this.messageTimer = setTimeout(() => {
                if (this.onecEvent == 'isClose') {
                    flag = false
                }
                // 因为可能存在后端推送时间的偏差，假如 前端本次轮训数据完毕，但实际后端正在推送
                // 直接清除会导致推送数据回显不准确。此时应有两个变量控制，后端推送开起轮训状态 & 前端接受数据轮训结束状态
                if (this.evtSourceMessageDataList.length != 0) {
                    this.getCreateData(...this.evtSourceMessageDataList.splice(0, 1))
                    flag = true;
                }
                if (!flag) {
                    clearTimeout(this.messageTimer);
                    this.messageTimer = null;

                    if (this.lastEventId === 'continue') {
                        this.isContinue = true;
                    } else {
                        this.isStop = true;
                        this.lastEventId = '';
                        this.$emit("resetAiLearningIndex");
                        this.inserting = false;
                    }
                    return
                }
                this.timeoutCreateData(flag);
            }, 40)
        },
        // 获取AI协作的生成内容
        getCreateData(data) {
            if (data !== '' && data !== undefined) {
                // this.currentEditor.editing.view.focus();
                if (data.indexOf('<br/>') !== -1) {
                    let command = this.detailCurrentEditor.commands.get("insertAskComponent");
                    // 需要回调通知父组件 上传成功
                    command.execute({
                        tag: "section",
                        options: {
                            className: 'self-p-section',
                            data: 'self-p-section'
                        },
                    });
                    // this.currentEditor.execute('enter') // 会产生多余的空格
                } else {
                    this.detailCurrentEditor.model.change((writer) => {
                        this.detailCurrentEditor.model.insertContent(writer.createText(data));
                        this.inserting = true;
                    });
                }
                // this.$nextTick(() => {
                //     this.setCkeditorFocusPate('getPositionData');
                //     let scrollDom = this.getScrollDom();
                //     let ckeditorDom = this.getCkeditorDom();
                //     if (Array.from(ckeditorDom.children).length === this.ckPath[0] + 1) {
                //         scrollDom.scrollTop = scrollDom.scrollHeight
                //     }
                // })
            }
        },
        errorFn(type) {
            if (this.evtSource) {
                this.evtSource.close();
                if (type === 'close') {
                    this.evtSource.close();
                    this.evtSource = null;
                    this.evtSourceMessageDataList = [];
                    clearTimeout(this.messageTimer);
                    this.messageTimer = null;

                } else {
                    console.log('AI生成失败,请稍后重试');
                }
            }
        },
        strToModel(str) {
            console.log(this.detailCurrentEditor);
            let rtfContent = str
            rtfContent = rtfContent.replace(/<!--[\s\S]*?-->/gmi, '');
            rtfContent = rtfContent.replace(/ style=".*?"/g, '')
            let viewFragment = this.detailCurrentEditor.data.processor.toView(rtfContent);
            let modelFragment = this.detailCurrentEditor.data.toModel(viewFragment);
            this.detailCurrentEditor.model.insertContent(modelFragment);
        },
        async fileInsert(fileList) {
            for (let index = 0; index < fileList.length; index++) {
                const file = fileList[index];
                let uid = uuidv4()
                this.desUploadImageAndFile({
                    type: "UPLOAD",
                    param: {
                        uid: uid,
                        type: 'pending',
                        name: file.name
                    }
                })
                await multipartUpload(this.ossConfig,
                    file,
                    null,
                ).then(resp => {
                    let url = ossFileUrl(this.ossConfig, resp.name)
                    this.desUploadImageAndFile({
                        type: "UPLOAD",
                        param: {
                            uid: uid,
                            name: file.name,
                            type: 'success',
                            url: url
                        }
                    })
                })
            }
        },
        // setCkeditorFocusPate(type) {
        // 	if(this.currentEditor) {
        // 		this.ckPath = this.currentEditor.model.document.selection.focus.path
        // 	} else {
        // 		this.ckPath = [0, 0]
        // 	}
        // 	if(type === 'getPositionData'){
        // 		this.getCkeditorButtonPosition('getPositionData');
        // 	}
        // },
    },
    watch: {
        unit: {
            handler(newVal, oldVal) {
                this.markdown = turnDownService.turndown(newVal.content.html);
                // console.debug('ueditor value change ,html:', newVal.content.html, 'markdown:', this.markdown);
                this.unit.content.markdown = this.markdown;
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
<style lang="less">
// .ck-balloon-panel_visible {
//     display: none !important;
// }
.crt-insert-link-cell {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    text-align: left;

    .insert-link-cell-title {
        flex: none;
        width: 100px;
        margin-bottom: 6px;
    }

    .el-input,
    .el-select {
        width: 100%;
        flex: none;
    }
}
</style>
<style lang="less" scoped>
.ck-rich-text {
    position: relative;

    .ask-emoji-box {
        position: absolute;
        top: 38px;
        background-color: white;
        border: solid 1px lightgray;

        .ask-emoji-type-box {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .ask-emoji-type-b-cell {
                flex: none;
                width: 32px;
                height: 32px;
                cursor: pointer;
                font-size: 18px;
            }
        }
    }

    /deep/.ck-content {
        min-height: 160px !important;
        max-height: calc(100vh - 300px);

        h2 {
            font-size: 25px;
        }

        h3 {
            font-size: 21px;
        }

        h4 {
            font-size: 18px;
        }

        a {
            font-weight: bolder;
        }

        .ask-component-img {
            width: 100%;
        }
    }

    .ask-crt-mode-inserting {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
    }

    .ask-crt-mode {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);

        .ask-crt-mode-loading {

            height: 28px;
            line-height: 28px;
            color: white;
            width: 154px;
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 0 12px;
            margin: 0 auto;

            img {
                opacity: 0.8;
                border-radius: 50%;
                height: 14px;
                width: 14px;
                margin-right: 4px;
            }
        }
    }

    /deep/.ck-button__icon {
        font-size: 30px;
        width: 20px;
    }

    /deep/.ck-dropdown {
        position: relative;
    }

    /deep/.ck-insert-table-dropdown__grid {
        width: 200px;
        height: 200px;

        .ck-insert-table-dropdown-grid-box {
            width: 16px;
        }
    }
}
</style>