<template>
    <div id="action_alert_iframe">
        <div class="simple-cell-satisfaction">
            <div class="title">
                <span>{{$t('flowComponent.alertIframe.title')}}</span>
            </div>
            <div class="jump-cell">
                <el-input
                    size="mini"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="unit.content.description"
                    :placeholder="$t('flowComponent.alertIframe.desPlaceholder')"
                ></el-input>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <div class="scs-title">
                <span class="pre-name"
                    >{{$t('flowComponent.alertIframe.link')}}
                    <span class="pre-name-des">
                        {{$t('flowComponent.alertIframe.linkTip')}}</span
                    ></span
                >
                <div
                    @click="templateConfigVisibleHandle('alert-placeholder')"
                    class="insert-param"
                >
                  {{$t('taskContent.insertVariable')}}
                </div>
            </div>
            <div class="action-confirm-des">
                <div class="single-cell html-cell">
                    <div class="cell-in-content">
                        <ckeditor
                            :editor="editorInline"
                            :config="editorConfig"
                            v-model="unit.content.template"
                            @focus="onEditorFocus"
                        ></ckeditor>
                    </div>
                </div>
            </div>
        </div>
        <div class="simple-cell-satisfaction-d-box">
            <div class="simple-cell-satisfaction-r">
                <div class="title">
                    <span>{{$t('flowComponent.alertIframe.iframeWidth')}}</span>
                </div>
                <div class="action-confirm-des">
                    <el-input
                        size="small"
                        v-model="unit.content.width"
                    ></el-input>
                </div>
            </div>
            <div class="simple-cell-satisfaction-r margin-l-12">
                <div class="title">
                    <span>{{$t('flowComponent.alertIframe.iframeHeight')}}</span>
                </div>
                <div class="action-confirm-des">
                    <el-input
                        size="small"
                        v-model="unit.content.height"
                    ></el-input>
                </div>
            </div>
        </div>

        <div v-if="false" class="simple-cell-satisfaction-r">
            <div class="title">
                <span>是否在 iframe 中显示滚动条</span>
            </div>
            <div class="action-confirm-des">
                <el-radio-group size="mini" v-model="unit.content.scrolling">
                    <el-radio label="yes">yes</el-radio>
                    <el-radio label="no">no</el-radio>
                    <el-radio label="auto">auto</el-radio>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
    name: "action_alert_iframe",
    props: ["unit", "nodeOptions", "intentOptions", "unitIndex"],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            editorDataInline: "",
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: ["undo", "redo"],
            },
        };
    },
    methods: {
        jumpTypeValueChange() {
            this.unit.content.goToAction.value = "";
        },
        templateConfigVisibleHandle(type) {
            let param = { type: type };
            param.unitIndex = this.unitIndex;
            param.lastEditor = lastEditor;
            param.multiple = false;
            param.webhookId_key = [""];
            this.$emit("templateConfigVisibleHandle", param);
        },
        onEditorFocus(zh, editor) {
            changeEditor(editor);
        },
        addConfirmDataResponse() {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: "单个webhook返回值",
                    editable: false,
                    data: webHookDataHolder(51, "data.name"),
                },
            });
        },

        addWebHookSinglePlace(data, webhookId) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },

        addConfirmDataEntity(data, editable) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: data.name,
                    editable: editable,
                    data: entityDataHolder(data.name),
                    type: data.inputType,
                    editable_class: "arsenal_icon arsenalkebianji",
                },
            });
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
#action_alert_iframe {
    .simple-cell-satisfaction-d-box {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
        .margin-l-12 {
            margin-left: 12px;
        }

        .simple-cell-satisfaction-r {
            flex: 1;
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 8px;
                margin-right: 32px;
                line-height: 20px;
                height: 20px;
                color: #616161;
            }
        }
    }

    .simple-cell-satisfaction {
        display: flex;
        flex-direction: column;
        .scs-title {
            margin-top: 8px;
            text-align: left;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .pre-name {
                display: inline-block;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #616161;
                .pre-name-des {
                    color: #a9b3c6;
                    font-size: 13px;
                }
            }
            .insert-param {
                text-align: center;
                //width: 72px;
                height: 23px;
                //line-height: 24px;
                display: flex;
                align-items: center;
                padding: 0 6px;
                flex: none;
                border: 1px solid #a1b9ff;
                border-radius: 12px;
                color: #366aff;
                cursor: pointer;
            }
        }
        .action-confirm-des {
            border: 1px solid #e0e6f7;
            border-radius: 4px;
            .html-cell {
                border-radius: 6px;
                display: flex;
                background-color: white;
                min-height: 100px;
                .cell-in-content {
                    flex: auto;
                    display: flex;
                    justify-content: space-between;
                    .ck-content {
                        width: 100%;
                    }
                }
            }
        }
        .title {
            margin-top: 0px;
            text-align: left;
            margin-bottom: 8px;
            color: #616161;
        }
    }
}
</style>
