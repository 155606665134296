var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"departmentTree"},[_c('div',{staticClass:"staff-customer-input"},[_c('el-input',{attrs:{"placeholder":"搜索","size":"small"},on:{"input":_vm.isNullSearch},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchDepartment.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.isShowStaff)?_c('el-tab-pane',{attrs:{"label":"员工部门","name":"staffDept"}},[_c('el-tree',{ref:"staffDataTree",staticClass:"filter-tree",attrs:{"props":{
                        isLeaf: 'leaf',
                        label: 'label',
                        children: 'children',
                    },"data":_vm.staffDataTree,"load":_vm.loadStaffNode,"lazy":"","node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node",on:{"click":function($event){$event.stopPropagation();return _vm.onNodeClick(data, data, node, '')}}},[_c('span',{staticClass:"custom-tree-node-left"},[(
                                    data.isWeWorkThirdData ||
                                    _vm.isUseOpenData(node)
                                )?_c('span',{staticClass:"data-label"},[_c('open-data',{attrs:{"type":'departmentName',"openid":data.label}})],1):_c('span',{staticClass:"data-label",class:data.dataType == 'hasNext'?'load-more-node':''},[_vm._v(" "+_vm._s(data.label))])]),(data.checked)?_c('span',[_c('i',{class:['el-icon-check']})]):_vm._e()])}}],null,false,310171631)}),(_vm.keyword && _vm.staffHasNext)?_c('span',{staticClass:"hasNextPage",on:{"click":_vm.loadMore}},[_vm._v(" 查看更多 ")]):_vm._e()],1):_vm._e(),(_vm.isShowUpDown)?_c('el-tab-pane',{attrs:{"label":"选择上下游","name":"upDown"}},[_c('el-tree',{ref:"upDownTree",staticClass:"filter-tree",attrs:{"props":{
                        isLeaf: 'leaf',
                        label: 'name',
                        children: 'children',
                    },"data":_vm.updownDateTree,"load":_vm.loadNode,"lazy":"","node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node",on:{"click":function($event){$event.stopPropagation();return _vm.onNodeClick(data, data, node, 'updown')}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('span',{staticClass:"data-label"},[_vm._v(_vm._s(data.name))])]),(data.checked)?_c('span',[_c('i',{class:['el-icon-check']})]):_vm._e()])}}],null,false,3257771982)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }