<template>
    <div class="rich_text_pro_v2">
        <div v-if="false" class="rich_text_pro_cell_row">
            <span class="rich_text_pro_cell_title">选择卡片模版</span>
            <div class="rich_text_pro_cell_content">
                <el-select v-model="rich_text_pro_info.content.tamplateId" size="small" placeholder="请选择模版">
                    <el-option v-for="item in modelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
        </div>
        <div class="tamplate_image_box">
            <div class="tamplate_image">
                <img height="100%"
                    src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2020/10/28/10/44/1eeb31f8-1d9b-4d70-8cfd-4750f48c95ee.png"
                    alt="" sizes="" srcset="" />
            </div>
            <div class="tamplate_des">
                <div class="tamplate_des-box">
                    <p>{{$t('flowComponent.richTextPro.des')}}：</p>
                    <p>1.{{$t('flowComponent.richTextPro.tip1')}}；</p>
                    <p>2.{{$t('flowComponent.richTextPro.tip2')}}</p>
                </div>
            </div>
        </div>
        <div class="rich_text_pro_cell_row">
            <span class="rich_text_pro_cell_title">{{$t('flowComponent.richTextPro.cardNumber')}}：</span>
            <div class="rich_text_pro_cell_content_num">
                <el-radio-group v-model="rich_text_pro_info.content.multiple" @change="multipleChange">
                    <el-radio :label="false">{{$t('flowComponent.richTextPro.singleCard')}}</el-radio>
                    <el-radio :label="true">{{$t('flowComponent.richTextPro.multipleCard')}}</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div v-if="rich_text_pro_info.content.multiple == true" class="rich_text_pro_cell_row">
            <span class="rich_text_pro_cell_title">{{$t('flowComponent.richTextPro.link')}}</span>
            <div class="rich_text_pro_cell_content">
                <el-cascader size="small" @change="webhookMultivaluedCascChange"
                    v-model="rich_text_pro_info.content.webhookId_key" :options="webhookMultivaluedCascList_"
                    :props="{ label: 'name' }"></el-cascader>
            </div>
        </div>
        <div class="rich_text_pro_cell_col" v-if="false && rich_text_pro_info.content.multiple == true">
            <span class="rich_text_pro_cell_title">样式：</span>
            <div class="rich_text_pro_cell_content">
                <el-radio v-model="rich_text_pro_info.content.style" label="slide">轮播</el-radio>
                <el-radio v-model="rich_text_pro_info.content.style" label="list">列表</el-radio>
            </div>
        </div>
        <div class="handle-insert-box" v-if="rich_text_pro_info.content.multiple == false ||
            rich_text_pro_info.content.webhookId_key.length > 0
            ">
            <el-button :disabled="rich_text_pro_info.content.content.htmlList.length == 0
                " @click="templateConfigVisibleHandle('placeholder')" size="mini" plain>{{$t('flowComponent.richTextPro.variable')}}</el-button>
            <el-button :disabled="rich_text_pro_info.content.content.htmlList.length == 0
                " @click="templateConfigVisibleHandle('link')" size="mini" plain>{{$t('flowComponent.richTextPro.link')}}</el-button>
            <el-button :disabled="rich_text_pro_info.content.content.htmlList.length == 0
                " @click="templateConfigVisibleHandle('icon')" size="mini" plain>{{$t('flowComponent.richTextPro.icon')}}</el-button>

            <el-button @click="addCellCou('SINGLE')" type="primary" style="background: white; color: #366aff" plain
                size="mini">{{$t('flowComponent.richTextPro.singleInput')}}</el-button>
            <el-button v-if="rich_text_pro_info.content.version != 1" @click="addCellCou('DOUBLE')" type="primary" style="background: white; color: #366aff" plain
                size="mini">{{$t('flowComponent.richTextPro.dualInput')}}</el-button>
            <el-dropdown v-if="rich_text_pro_info.content.version == 1" @command="doubleHandleCommand">
                <el-button type="primary" style="background: white; color: #366aff; margin-left: 10px; margin-right: 10px;"
                    plain size="mini">
                  {{$t('flowComponent.richTextPro.dualInput')}}
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="11">1 : 1</el-dropdown-item>
                    <el-dropdown-item command="12">1 : 2</el-dropdown-item>
                    <el-dropdown-item command="13">1 : 3</el-dropdown-item>
                    <el-dropdown-item command="21">2 : 1</el-dropdown-item>
                    <el-dropdown-item command="31">3 : 1</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button @click="addCellCou('TRIPLE')" type="primary" style="background: white; color: #366aff" plain
                size="mini">{{$t('flowComponent.richTextPro.threeInputs')}}</el-button>
            <el-button @click="addCellCou('QUADRUPLE')" type="primary" style="background: white; color: #366aff" plain
                size="mini">{{$t('flowComponent.richTextPro.fourInputs')}}</el-button>
            <el-button @click="addCellCou('image')" type="primary" style="background: white; color: #366aff" plain
                size="mini">{{$t('flowComponent.richTextPro.image')}}</el-button>
        </div>
        <div class="html-box-rich" v-if="(rich_text_pro_info.content.multiple == false ||
            rich_text_pro_info.content.webhookId_key.length > 0) &&
            rich_text_pro_info.content.content.htmlList.length > 0
            ">
            <div class="html-box-rich-cell" v-for="(cell, index) in rich_text_pro_info.content.content
                .htmlList" :key="index">
                <span class="
                        iconfont
                        guoran-tongyichicun-16-10-paixu
                        icon-handle-card
                    "></span>
                <div class="image-cell" v-if="cell.type == 'image'">
                    <div class="cell-in-content-image">
                        <div class="rich-text-outer-box">
                            <img v-if="cell.src.trim() !== '' &&
                                cell.dynamic == 'false'
                                " :src="cell.src.replace('\'', '').replace('\'', '')
        " height="100px" width="100px" alt="" srcset="" class="sig-image-box" />
                            <span v-if="cell.src.trim() === ''" class="el-icon-picture-outer">
                                <i class="el-icon-picture"></i>
                            </span>
                            <span v-if="cell.dynamic == 'true'" class="el-icon-picture-outline"></span>
                        </div>
                        <div @click="templateConfigVisibleHandle('url', index)" class="rich-text-url-upload">
                            URL
                        </div>
                        <el-upload class="rich-text-image-upload" :show-file-list="showFileList" :action="importFileUrl"
                            :before-upload="beforeImageMUpload" :on-success="(response, file, fileList) =>
                                handlePictureUploadSuccess(
                                    response,
                                    file,
                                    fileList,
                                    index
                                )
                                ">
                            <span>{{$t('flowComponent.richTextPro.image')}}</span>
                        </el-upload>
                    </div>
                    <span @click="deleteHtmlCell(index)" class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "></span>
                </div>
                <div class="single-cell html-cell" v-if="cell.type == 'SINGLE'">
                    <div class="cell-in-content">
                        <ckeditor :editor="editorInline" :config="editorConfig" v-model="cell.list[0]"
                            @focus="onEditorFocus"></ckeditor>
                    </div>
                    <span @click="deleteHtmlCell(index)" class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "></span>
                </div>
                <div class="double-cell html-cell" v-if="cell.type == 'DOUBLE' && rich_text_pro_info.content.version == 1">
                    <div :style="{'width': 520*cell.style[innerCellIndex].col/cell.style[innerCellIndex].colums + 'px', 'height': '100%'}" v-for="(innerCell, innerCellIndex) in cell.list"
                        :key="innerCellIndex" class="cell-in-content">
                        <ckeditor :config="editorConfig" :editor="editorInline" v-model="cell.list[innerCellIndex]"
                            @focus="onEditorFocus"></ckeditor>
                    </div>
                    <span @click="deleteHtmlCell(index)" class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "></span>
                </div>
                <div class="double-cell html-cell" v-if="cell.type == 'DOUBLE' && rich_text_pro_info.content.version != 1">
                    <div style="width: 261px; height: 100%" v-for="(innerCell, innerCellIndex) in cell.list"
                        :key="innerCellIndex" class="cell-in-content">
                        <ckeditor :config="editorConfig" :editor="editorInline" v-model="cell.list[innerCellIndex]"
                            @focus="onEditorFocus"></ckeditor>
                    </div>
                    <span @click="deleteHtmlCell(index)" class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "></span>
                </div>
                <div class="triple-cell html-cell" v-if="cell.type == 'TRIPLE'">
                    <div style="width: 168px;" v-for="(innerCell, innerCellIndex) in cell.list" :key="innerCellIndex"
                        class="cell-in-content">
                        <ckeditor :config="editorConfig" :editor="editorInline" v-model="cell.list[innerCellIndex]"
                            @focus="onEditorFocus"></ckeditor>
                    </div>
                    <span @click="deleteHtmlCell(index)" class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "></span>
                </div>
                <div class="quadrauple-cell html-cell" v-if="cell.type == 'QUADRUPLE'">
                    <div style="width: 100px;" v-for="(innerCell, innerCellIndex) in cell.list" :key="innerCellIndex"
                        class="cell-in-content">
                        <ckeditor :config="editorConfig" :editor="editorInline" v-model="cell.list[innerCellIndex]"
                            @focus="onEditorFocus"></ckeditor>
                    </div>
                    <span @click="deleteHtmlCell(index)" class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "></span>
                </div>
            </div>
        </div>
        <div class="rich_text_pro_cell_row" v-if="rich_text_pro_info.content.multiple == false ||
            rich_text_pro_info.content.webhookId_key.length > 0
            ">
            <span class="rich_text_pro_cell_title">{{$t('flowComponent.richTextPro.button')}}</span>
            <div :id="'btn-list-box-sort' + activeARichtextproIndex" class="btn-box-bg">
                <!-- <div
                    v-for="(buttonCell, buttonCellIndex) in rich_text_pro_info
                        .content.content.buttonList"
                    :key="buttonCellIndex"
                    class="rich_text_pro_cell_content button_box"
                >
                    <el-input
                        size="small"
                        v-model="buttonCell.name"
                        placeholder="请输入名称"
                    ></el-input>
                    <el-button
                        v-if="buttonCell.name !== ''"
                        @click="rtpAddBottondiaVisible(buttonCellIndex, buttonCell)"
                        type="primary"
                        size="mini"
                        >点击事件</el-button
                    >
                    <span
                        @click="deleteRtpBtn(buttonCellIndex)"
                        class="
                            icon-delete-rich
                            iconfont
                            guoran-tongyichicun-16-09-shanchu2
                        "
                    ></span>
                </div> -->
                <div :key="buttonCellIndex" v-for="(buttonCell, buttonCellIndex) in rich_text_pro_info
                    .content.content.buttonList" class="btn-list-cell">
                    <span v-if="buttonCell.name == ''" class="btn-list-cell-text"
                        @click="rtpAddBottondiaVisible(buttonCellIndex, buttonCell)">{{$t('flowComponent.richTextPro.editRadio')}}</span>
                    <span v-else class="btn-list-cell-text" @click="rtpAddBottondiaVisible(buttonCellIndex, buttonCell)">{{
                        buttonCell.name
                    }}</span>
                    <span class="sortIcon my-handle-btn iconfont guoran-a-16-10"
                        @mouseenter="sortClickBtn($event, buttonCell, activeARichtextproIndex)">
                    </span>
                    <span @click="
                        deleteRtpBtn(buttonCellIndex)
                        " class="my-handle-close iconfont guoran-a-16-09">
                    </span>
                </div>
                <span @click="addRtpBtn" class="rtp-add-btn"><i class="add-text el-icon-plus"></i></span>
            </div>
        </div>
    </div>
</template>
<script>
import Sortable from "sortablejs";
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
    name: "Rich_text_pro_info",
    props: [
        "rich_text_pro_info",
        "webhookMultivaluedCascList_",
        "activeARichtextproIndex",
    ],
    data() {
        return {
            uploadSrc: "",
            showFileList: false,
            importFileUrl: location.origin + "/api/oss",
            modelList: [
                {
                    name: "自定义消息卡片模版",
                    id: "1",
                },
            ],
            // currentEditor: null,
            // lastEditor: null,
            editorDataInline: "",
            // editor: CKEDITOR.ClassicEditor,
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "fontSize",
                    "fontColor",
                    "fontBackgroundColor",
                    "alignment",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    // "indent",
                    // "outdent",
                    // "|",
                    // "imageUpload",
                    // "blockQuote",
                    // "insertTable",
                    // "mediaEmbed",
                    "undo",
                    "redo",
                ],

                // askPluginListener: [
                //   beforeButtonInsert,
                //   beforePlaceholderInsert,
                //   beforeLoopBlockInsert,
                // ], // 注册监听
            },
        };
    },
    methods: {
        doubleHandleCommand(commend) {
            let colums = Number(String(commend).split("")[0]) + Number(String(commend).split("")[1]);
            this.addCellCou('DOUBLE', [{ col: Number(String(commend).split("")[0]), colums: colums }, { col: Number(String(commend).split("")[1]), colums: colums  }])
        },
        sortClickBtn(e, items, index) {
            console.log(1333333)
            e.stopPropagation();
            let _this = this;
            let idname = "btn-list-box-sort" + this.activeARichtextproIndex;
            let box = document.getElementById(idname);
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn",
                group: "shared",
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.rich_text_pro_info
                        .content.content.buttonList.splice(
                            oldIndex,
                            1
                        );
                    _this.rich_text_pro_info
                        .content.content.buttonList.splice(
                            newIndex,
                            0,
                            item[0]
                        );
                },
            });
            this.rich_text_pro_info
                .content.content.buttonList =
                _this.rich_text_pro_info
                    .content.content.buttonList;
        },
        webhookMultivaluedCascChange() {
            console.log("change+++++++++++++++++++++");
            this.rich_text_pro_info.content.content.htmlList = [];
            this.rich_text_pro_info.content.content.buttonList = [];
        },
        multipleChange() {
            console.log("change+++++++++++++++++++++");
            this.rich_text_pro_info.content.content.htmlList = [];
            this.rich_text_pro_info.content.content.buttonList = [];
        },
        templateConfigVisibleHandle(type, cellIndex) {
            let param = { type: type };
            if (type == "url") {
                param.cellIndex = cellIndex;
            }
            param.webhookId_key = this.rich_text_pro_info.content.webhookId_key;
            param.activeARichtextproIndex = this.activeARichtextproIndex;
            param.multiple = this.rich_text_pro_info.content.multiple;
            param.lastEditor = lastEditor;
            this.$emit("templateConfigVisibleHandle", param);
        },
        rtpAddBottondiaVisible(index, info) {
            let webHookSCope = this.rich_text_pro_info.content.webhookId_key;
            console.log("webHookSCope", webHookSCope, info);
            // console.log(index, info,this.rich_text_pro_info);
            this.$emit("rtpAddBottondiaVisible", {
                index: index,
                info: JSON.parse(JSON.stringify(info)),
                webHookSCope: webHookSCope,
                uniIndex: this.activeARichtextproIndex,
            });

            let param = { type: "placeholder-pre" };
            param.webhookId_key = this.rich_text_pro_info.content.webhookId_key;
            param.activeARichtextproIndex = this.activeARichtextproIndex;
            param.multiple = this.rich_text_pro_info.content.multiple;
            // param.lastEditor = lastEditor;
            console.log(387, param);
            this.$emit("templateConfigVisibleHandle", param);
        },
        addImageUrl(index) { },
        addCellCou(type, listStyle) {
            console.log(418, listStyle);
            switch (type) {
                case "image":
                    this.rich_text_pro_info.content.content.htmlList.push({
                        type: "image",
                        src: "",
                        dynamic: "",
                    });
                    break;
                case "SINGLE":
                    this.rich_text_pro_info.content.content.htmlList.push({
                        type: "SINGLE",
                        list: [""],
                        style: [{ col: 1 }]
                    });
                    break;
                case "DOUBLE":
                    this.rich_text_pro_info.content.content.htmlList.push({
                        type: "DOUBLE",
                        list: ["", ""],
                        style: listStyle
                    });
                    break;
                case "TRIPLE":
                    this.rich_text_pro_info.content.content.htmlList.push({
                        type: "TRIPLE",
                        list: ["", "", ""],
                        style: [{ col: 1 }, { col: 1 }, { col: 1 }]
                    });
                    break;
                case "QUADRUPLE":
                    this.rich_text_pro_info.content.content.htmlList.push({
                        type: "QUADRUPLE",
                        list: ["", "", "", ""],
                        style: [{ col: 1 }, { col: 1 }, { col: 1 }, { col: 1 }]
                    });
                    break;
                default:
                    break;
            }
            this.$nextTick(() => {
                this.cardSortClickBtn();
            })
        },
        deleteHtmlCell(index) {
            this.rich_text_pro_info.content.content.htmlList.splice(index, 1);
        },
        addRtpBtn() {
            this.rich_text_pro_info.content.content.buttonList.push({
                name: "",
                scope: "NODE",
                value: "",
                assignments: [
                    {
                        webhookId_key: "",
                        _full_entity_entity: "",
                    },
                ],
            });
        },
        deleteRtpBtn(index) {
            this.rich_text_pro_info.content.content.buttonList.splice(index, 1);
        },
        // 图片上传成功
        handleIconUploadSuccess(response, file, fileList) {
            if (response.code == 0) {
                console.log(response.data);
            }
        },
        handlePictureUploadSuccess(response, file, fileList, index) {
            if (response.code == 0) {
                // this.addImage(response.data);
                this.rich_text_pro_info.content.content.htmlList[index].src =
                    response.data;
                this.rich_text_pro_info.content.content.htmlList[
                    index
                ].dynamic = "false";
                console.log(response.data);
                this.rich_text_pro_info.content.content.htmlList = [
                    ...this.rich_text_pro_info.content.content.htmlList,
                ];
            }
        },
        beforeImageMUpload(file) {
            const isJPG =
                file.type === "image/JPG" ||
                file.type === "image/JPEG" ||
                file.type === "image/PNG" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/GIF" ||
                file.type === "image/gif";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            if (!isLt2M) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            return isJPG && isLt2M;
        },
        onEditorFocus(zh, editor) {
            changeEditor(editor);
        },
        addEntityPlace(data) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.label,
                    data: entityDataHolder(data.value),
                },
            });
        },
        addWebHookSinglePlace(data, webhookId) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },
        addWebHookPlaceWithScope(data) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolderWithScope(data.value),
                },
            });
        },
        addImage(url) {
            console.log(currentEditor);
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "img",
                options: {
                    dynamic: "true",
                    src: url,
                    alt: "alt",
                    height: "100px",
                },
            });
        },
        addIcon(url) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "img",
                options: {
                    dynamic: "false",
                    src: url,
                    alt: "alt",
                    width: "20px",
                    height: "20px",
                },
            });
        },
        addImageData() {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "img",
                // 这里用webhook返回值举例
                options: {
                    src: webHookDataHolder(51, "data.img"),
                    alt: "alt",
                    width: "100px",
                    height: "100px",
                },
            });
        },
        addHref(data) {
            console.log(data, 11111111);
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "a",
                options: data,
            });
        },
        addHrefData() {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "a",
                // 这里用webhook返回值举例
                options: {
                    href: webHookDataHolder(51, "data.img"),
                    name: "超链接",
                },
            });
        },
        cardSortClickBtn() {
            const box = document.querySelector(".html-box-rich");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-card",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item =
                        _this.rich_text_pro_info.content.content.htmlList.splice(
                            oldIndex,
                            1
                        );
                    _this.rich_text_pro_info.content.content.htmlList.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                    // 下一个tick就会走patch更新
                },
            });
            this.rich_text_pro_info.content.content.htmlList =
                _this.rich_text_pro_info.content.content.htmlList;
        },
    },
    mounted() {
        this.$nextTick(function () {
            this.cardSortClickBtn();
            this.$on("addEntityPlace", function (data) {
                console.log(data);
                let command = currentEditor.commands.get("insertAskComponent");
                command.execute({
                    tag: "span",
                    options: {
                        name: data.label,
                        data: entityDataHolder(data.value),
                    },
                });
            });
        });
    },
};
</script>
<style lang="less">
.ck-balloon-panel {
    .ck-button__icon {
        width: var(--ck-icon-size) !important;
    }
}

.rich_text_pro_v2 {
    a {
        color: blue !important;
    }

    a:visited {
        color: blue !important;
    }

    a:link {
        color: blue !important;
    }

    .rich_text_pro_cell_row {
        display: flex;
        flex-direction: column;

        .rich_text_pro_cell_title {
            text-align: left;
            margin-top: 8px;
            color: #616161;
        }

        .btn-box-bg {
            margin-top: 10px;
            background: #fbfcfd;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 12px 10px;

            .btn-list-cell:hover {
                .my-handle-btn {
                    display: inline-block;
                }

                .my-handle-close {
                    display: inline-block;
                }
            }

            .btn-list-cell {
                height: 28px;
                line-height: 28px;
                border-radius: 14px;
                padding: 0;
                margin-right: 6px;
                margin-top: 12px;
                border: solid 1px #366aff;
                color: #366aff;
                cursor: pointer;
                position: relative;
                background-color: white;

                .btn-list-cell-text {
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    padding: 0 16px;
                }

                .my-handle-btn {
                    display: none;
                    position: absolute;
                    top: -8px;
                    right: 26px;
                    height: 16px;
                    line-height: 16px;
                    width: 16px;
                    border-radius: 8px;
                    background-color: white;
                }

                .my-handle-close {
                    display: none;
                    position: absolute;
                    top: -8px;
                    right: 3px;
                    height: 16px;
                    line-height: 16px;
                    width: 16px;
                    border-radius: 8px;
                    background-color: white;
                }
            }

            .button_box {
                margin-top: 0px;
                display: flex;
                padding: 8px;

                .el-button {
                    margin-left: 8px;
                }

                .icon-delete-rich {
                    margin-top: 8px;
                    margin-left: 8px;
                    color: #366aff;
                    cursor: pointer;
                }
            }

            .rtp-add-btn {
                flex: none;
                margin-top: 10px;
                width: 29px;
                height: 29px;
                line-height: 29px;
                color: #749bff;
                text-align: center;
                background: #ffffff;
                border: 1px solid rgba(116, 155, 255, 0.4);
                border-radius: 50%;
                margin-right: 4px;

                i {
                    color: #366aff;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }

        .rich_text_pro_cell_content {
            margin-top: 8px;

            .el-select {
                width: 100%;
            }

            .el-cascader {
                width: 100%;
            }
        }

        .rich_text_pro_cell_content_num {
            margin-top: 12px;
            background: #fbfcfd;
            border-radius: 5px;
            text-align: left;
            padding: 18px;
        }

    }

    .rich_text_pro_cell_col {
        display: flex;
        flex-direction: row;

        .rich_text_pro_cell_title {
            text-align: left;
            margin-right: 20px;
            line-height: 19.2px;
            height: 19.2px;
            margin-top: 8px;
        }

        .rich_text_pro_cell_content {
            margin-top: 8px;

            .el-select {
                width: 100%;
            }
        }
    }

    .tamplate_image_box {
        margin: 8px 0;
        border-radius: 5px;
        background: #fff8f4;
        display: flex;
        padding: 15px;

        .tamplate_image {
            border-radius: 4px;
            overflow: hidden;
            flex: none;
            width: 230px;
            border: 1px solid #ffd9c5;
        }

        .tamplate_des {
            flex: auto;
            color: #ff9555;
            background-color: #fff8f4;
            border: 1px solid #ffd9c5;
            padding: 8px;
            margin-left: 8px;
            border-radius: 4px;
            text-align: left;
            display: flex;
            align-items: center;

            .tamplate_des-box {
                margin-bottom: 20px;

                p {
                    margin: 12px 0;
                    font-size: 12px;
                }
            }
        }
    }

    .handle-insert-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0;
        background: #f5f7fb;
        padding: 10px;

        /deep/.el-button--primary.is-plain {
            background: white !important;
        }

        .rich-text-image-upload {
            margin: 0 8px;
        }
    }

    .html-box-rich {
        background: #fbfcfd;
        padding: 8px 0;

        .html-box-rich-cell {
            display: flex;
            align-items: center;

            .icon-handle-card {
                margin-left: 12px;
                color: #366aff;
                cursor: pointer;
            }

            .html-cell {
                display: flex;
                align-items: center;
                flex: auto;

                .cell-in-content {
                    flex: auto;
                    margin: 8px;
                    display: flex;
                    justify-content: space-between;
                    background: white;
                    border-radius: 5px;
                    border: solid 1px rgba(0, 89, 255, 0.2);

                    .ck-content {
                        width: 100%;

                        p {
                            margin: 8px;
                        }
                    }
                }

                .icon-delete-rich {
                    height: 51px;
                    line-height: 51px;
                    color: #366aff;
                    margin-right: 16px;
                    cursor: pointer;
                }
            }

            .image-cell {
                margin: 8px;
                height: 100px;
                display: flex;
                flex: auto;

                .cell-in-content-image {
                    flex: auto;
                    display: flex;
                    margin-left: 180px;
                    align-items: center;

                    .rich-text-outer-box {
                        flex: auto;
                        max-width: 150px;
                        overflow: hidden;
                        margin-right: 12px;

                        .sig-image-box {
                            max-width: 150px;
                            overflow: hidden;
                            border-radius: 5px;
                            border: solid 1px #e0e6f7;

                            img {
                                border-radius: 5px;
                            }
                        }

                        .el-icon-picture-outer {
                            border-radius: 5px;
                            border: solid 1px #e0e6f7;
                            height: 100px;
                            line-height: 60px;
                            width: 100px;

                            i {
                                font-size: 50px;
                            }
                        }

                        .el-icon-picture-outline {
                            border-radius: 5px;
                            border: solid 1px #e0e6f7;
                            height: 100px;
                            width: 100px;
                        }

                        .ck-content {
                            height: 100px !important;
                            border: solid 1px rgba(0, 89, 255, 0.2);
                        }

                        span {
                            color: rgba(0, 89, 255, 0.2);
                            font-size: 100px;
                        }
                    }

                    .rich-text-url-upload {
                        width: 61px;
                        height: 29px;
                        line-height: 29px;
                        background: #e8f0ff;
                        color: #366aff;
                        border-radius: 14px;
                        cursor: pointer;
                    }

                    .rich-text-image-upload {
                        margin-right: 50px;
                        margin-left: 8px;
                        width: 61px;
                        height: 29px;
                        line-height: 29px;
                        background: #e8f0ff;
                        color: #366aff;
                        border-radius: 14px;
                        cursor: pointer;

                        .el-upload {
                            width: 50px !important;

                            .el-icon-upload {
                                color: rgba(0, 89, 255, 0.5);
                                font-size: 30px;
                            }
                        }
                    }
                }

                .icon-delete-rich {
                    flex: none;
                    text-align: left;
                    margin-left: 8px;
                    width: 22px;
                    height: 100px;
                    line-height: 100px;
                    color: #366aff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>