function answerInit(type, isStartOrEnd, custonerOptions, customerServiceOfflineIntentId,isBotLink) {
    console.log(type);
    let obj = {
        action_enforce: {
            // 强制执行
            conditionList: [],
            content: {
                gotoList: [{
                    scope: "NODE", // INTENT NODE
                    value: "",
                }]
            },
            type: "action_enforce",
        },
        action_mail: {
            // 发送邮件
            conditionList: [],
            content: {
                mails: "", // 为null表示当前对话用户邮箱
                subject: "", // 主题
                content: "", // 邮件内容,富文本?
                attachments: [], // 附件url
                mailNotExitsActionType: "QUESTION", // QUESTION/INTENT/NODE
                questionContent: {
                    questions: [""],
                    questionNum: 1
                },
                gotoContent: "", // 节点/意图ID
                onSuccess: {
                    scope: "NODE", // INTENT, NODE, CONTENT
                    value: "" // 意图ID/节点ID/回复文本内容
                },
                onFail: {
                    scope: "NODE", // INTENT, NODE, CONTENT
                    value: "" // 意图ID/节点ID/回复文本内容
                }
            },
            type: "action_mail",
        },
        action_random_jump: {
            // 随机跳转
            conditionList: [],
            content: {
                gotoList: [{
                    scope: isStartOrEnd ? "INTENT" : "NODE", // INTENT NODE
                    value: "",
                }]
            },
            type: "action_random_jump",
        },
        action_feedback: {
            // 答案反馈
            conditionList: [],
            content: {
                positiveFeedback: {
                    scope: "NODE", // INTENT NODE CONTENT NONE
                    value: "",
                    text: "有用",
                },
                negativeFeedback: {
                    scope: "NODE", // INTENT NODE CONTENT NONE 😄
                    value: "",
                    text: "无用",
                }
            },
            type: "action_feedback",
        },
        action_alert_iframe: {
            // 弹窗
            conditionList: [],
            content: {
                version: 1,
                template: "",
                description: "",
                height: 400,
                width: 320,
                scrolling: "yes", // yes/no/auto(是否在 iframe 中显示滚动条)
                sandbox: [], // "预留","启用一系列对 <iframe> 中内容的额外限制"，"参考 https://www.w3school.com.cn/tags/att_iframe_sandbox.asp"
                frameborder: "0", // 0/1(是否显示框架周围的边框)
                displayStyle: "" // iframe 样式(点击弹出/嵌入iframe 字符串类型后端未做限制),
            },
            type: "action_alert_iframe",
        },
        action_confirm_value: {
            // 确认值
            conditionList: [],
            content: {
                template: "",
                goToAction: {
                    // 跳转参数
                    scope: "NODE",
                    value: "",
                },
            },
            type: "action_confirm_value",
        },
        action_form: {
            // 提交表单
            conditionList: [],
            content: {
                form: ["", ""], // 表单 form[0] 表单类型 form[1]表单id
                submitSuccess: {
                    // 提交成功
                    scope: "NODE", // INTENT NODE CONTENT
                    value: "",
                },
                submitError: {
                    // 提交失败
                    scope: "NODE", // INTENT NODE CONTENT
                    value: "",
                },
                relation: [
                    // 表单字段和实体关联, display 客户端是否显示
                ],
                originForm: {
                    formShowName: "",
                },
            },
            type: "action_form",
        },
        answer_card_template: {
            type: "answer_card_template",
            conditionList: [],
            content: {
                version: 1,
                scope: "system",
                tamplateId: "1",
                style: "slide",
                webhookId_key: "",
                multiple: false,
                content: {
                    htmlList: [

                    ],
                    buttonList: [

                    ],
                },
            },
        },
        action_set_entity: {
            // 设置实体
            conditionList: [],
            content: { entity: ["", ""], value: "" },
            type: "action_set_entity",
        },

        answer_rich_text_pro: {
            // 消息卡片
            conditionList: [],
            content: {
                value: "",
            },
            type: "answer_rich_text_pro",
        },
        action_webhook: {
            // webhook
            conditionList: [],
            content: {
                value: "",
                webHookId: "",
                failedAction: {
                    scope: "NODE",
                    value: "",
                },
                params: [
                    {
                        key: "",
                        type: "ENTITY",
                        value: "",
                        defaultValue: "",
                        entities: [],
                        actionNoParam: {
                            type: "",
                            radioContent: {
                                description: "",
                                options: [
                                    {
                                        name: "点击编辑点选",
                                        onclick: "SEND_MSG_TO_BOT",
                                        content: "",
                                    },
                                ],
                            },
                            questionContent: {
                                questions: [""],
                                questionNum: -1,
                            },
                            gotoContent: {
                                type: "",
                                value: "",
                            },
                            content: {},
                        },
                    },
                    {
                        key: "",
                        type: "WEB_HOOK_RESPONSE",
                        value: "",
                        defaultValue: "",
                        webHookId: "",
                        webHookResponseName: "",
                        webHookShowInfo: ["", ""],
                        actionNoParam: {
                            type: "",
                            radioContent: {
                                description: "",
                                options: [
                                    {
                                        name: "点击编辑点选",
                                        onclick: "SEND_MSG_TO_BOT",
                                        content: "",
                                    },
                                ],
                            },
                            questionContent: {
                                questions: [""],
                                questionNum: -1,
                            },
                            gotoContent: {
                                type: "",
                                value: "",
                            },
                            content: {},
                        },
                    },
                ],
            },
            type: "action_webhook",
        },
        answer_rich_text: {
            // 富文本
            conditionList: [],
            content: {
                html: "",
                version: 1
            },
            type: "answer_rich_text",
        },
        answer_text: {
            // 文本
            conditionList: [],
            content: {
                list: [""],
                extInfos: [{ expectedIntention: "", notAsExpectedReply: "" }],
                version: 1
            },
            type: "answer_text",
        },
        answer_welcometext: {
            // 欢迎语
            conditionList: [],
            content: {
                list: [""],
                extInfos: [{ expectedIntention: "", notAsExpectedReply: "" }],
                version: 1
            },
            type: "answer_welcometext",
        },
        answer_video: {
            // 视频
            conditionList: [],
            content: {
                url: "",
                videoFlag: false,
                //是否显示进度条
                videoUploadPercent: 0,
                //进度条的进度，
                isShowUploadVideo: false,

                nodownload: false,
            },
            type: "answer_video",
        },
        answer_image: {
            // 图片
            conditionList: [],
            content: {
                url: "",
            },
            type: "answer_image",
        },
        answer_voice: {
            // 音频
            conditionList: [],
            content: {
                url: "",
                filenName: "",
                extInfos: [{
                    videoFlag: false,
                    //是否显示进度条
                    videoUploadPercent: 0,
                    //进度条的进度，
                    isShowUploadVideo: false,
                    duration: 0,
                    avCurrentTime: new Date().getTime() + Math.floor( Math.random() * 10001)
                }]
            },
            type: "answer_voice",
        },
        answer_radio: {
            // 点选
            conditionList: [],
            content: {
                description: "",
                groupVisible: false, // 是否分组展示
                groupNumber: 5, // 每组展示数量
                options: [
                    // {
                    //     name: "点击编辑点选",
                    //     scope: isStartOrEnd ? "INTENT" : "NODE",
                    //     value: "",
                    //     type: "", // FIXED LOOP
                    //     text: "", // 不包含标签的文本
                    //     variable: "", // 变量名
                    // },
                ],
            },
            type: "answer_radio",
        },
        action_jump: {
            // 跳转
            conditionList: [],
            content: {
                list: [""],
                scope: isStartOrEnd ? "INTENT" : "NODE",
                value: "",
            },
            type: "action_jump",
        },
        action_question: {
            // 反问
            conditionList: [],
            content: {
                questions: [""],
                entity: [],
                askIfExist: 0,
                questionNum: 1,
                goToAction: {
                    scope: "NODE",
                    value: ""
                },
                entityNotFountAction: {
                    type: "RECOGNIZE_INTENT",
                    recognizeIntent: {
                        roundToContinue: 3
                    },
                    gotoNode: {
                        id: ""
                    },
                    gotoIntent: {
                        id: ''
                    },
                    sendMsgToUser: {
                        content: ""
                    }
                },
                version: 1
            },
            type: "action_question",
        },
        // 转人工
        action_transfer: {
            content: {
                type: 2,
                random: {
                    text: "",
                },
                ifOffline: {
                    scope: "INTENT", // INTENT NODE CONTENT
                    value: customerServiceOfflineIntentId,
                },
                select: {
                    text:
                        "不好意思，机器人暂时没能理解您的意思呢，将为您转人工客服处理，请选择客服组：",
                    options: [],
                },
                direct: {
                    text: "",
                    customer:
                        custonerOptions != null && custonerOptions.length > 0
                            ? custonerOptions[0].id
                            : "",
                },
            },
            type: "action_transfer",
            conditionList: [],
        },
        action_satisfaction: {
            // 满意度
            conditionList: [],
            content: {
                satisfactionDes: "",
                satisfactoryAnswer: "",
                unsatisfiedAnswer: "",
                answerRadio: [],
            },
            type: "action_satisfaction",
        },
        action_fast_select: {
            // 快捷选择
            conditionList: [],
            content: {
                list: [{
                    text: "",
                    action: {
                        scope: isStartOrEnd ? "INTENT" : "NODE",
                        value: ""
                    }
                }]
            },
            type: "action_fast_select",
        },
        action_transfer_2_ewechat: {
            // 转企业微信
            conditionList: [],
            content: {
                text: [""],
                offlineAction: {
                    scope: "INTENT",// NODE/INTENT/SEND_MSG_TO_USER
                    value: customerServiceOfflineIntentId
                }
            },
            type: "action_transfer_2_ewechat",
            effectiveTime: {}
        },
        answer_intellect_guide: {
            // 智能引导
            type: "answer_intellect_guide",
            conditionList: [],
            content: {
                typesetting: "vertical", // 排版方式 vertical 竖向 horizontal 横向
                description: "", // 描述
                descriptionVisible: true, // 是否展示描述
                typesVisible: true, // 是否展示分类信息
                groupVisible: true, // 是否分组展示
                groupNumber: 3, // 每组展示数量
                options: [
                    {
                        name: '',
                        recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                        recommendIntentList: [{ questionName: "", intentId: "" }], // recommendType = 0
                        highFrequency: { // recommendType = 1
                            rang: [], // 推荐范围
                            number: 20, // 最多推荐问题数
                        },
                        intelligenceRecommend: { // recommendType = 2
                            recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                            rang: [], // 推荐范围
                            number: 20, // 最多推荐问题数
                        },
                        types: [
                            {
                                name: "",
                                recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                                recommendIntentList: [{ questionName: "", intentId: "" }], // recommendType = 0
                                highFrequency: { // recommendType = 1
                                    rang: [], // 推荐范围
                                    number: 20, // 最多推荐问题数
                                },
                                intelligenceRecommend: { // recommendType = 2
                                    recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                                    rang: [], // 推荐范围
                                    number: 20, // 最多推荐问题数
                                },
                            }
                        ]
                    }
                ], // 分类和意图数组 二维数组
                version: 1
            },
        },
    };
    obj[type].effectiveTime = {
        range: "PERMANENT",
        startTime: "",
        endTime: "",
        repeat: ["1", "2", "3", "4", "5", "6", "7"],
        filterHolidays: true,
        balanceHolidays: true,
        timeRange: [new Date("Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"), new Date("Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)")],
    }
    if (type == "answer_rich_text" || type == "answer_text" || type == "answer_image" || type == "answer_video" || type == "answer_voice" || type == "answer_intellect_guide") {
        if (isBotLink && type === 'answer_intellect_guide') {
            obj[type].content.options[0].types[0].recommendIntentList[0] = { questionName: "", intentId: "", intentName:"" }
        }
        obj[type].content.recommend = {
            list: [{
                id: "", type: 0, text: "", // type 0 自定义 1 跟随意图
                ext: {
                    intentValue: [], // 用于回显 对后端没用
                }
            }],
            status: false, // 推荐开关
            description: "" // 推荐描述
        }
        
    }
    if (type === 'action_webhook' && isBotLink) { 
        obj[type].content.failedAction.scope = 'INTENT'
    }
    return obj[type];
}

export { answerInit }