var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-fast-select"},[_c('div',{staticClass:"fast-select-des"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('flowComponent.actionFastSelect.tip')))])]),_c('div',{staticClass:"fast-select-content"},[_c('div',{staticClass:"select-cell-title"},[_c('span',{staticClass:"select-cell-title-name"},[_vm._v(_vm._s(_vm.$t('flowComponent.actionFastSelect.name')))]),_c('span',{staticClass:"select-cell-title-action"},[_vm._v(_vm._s(_vm.$t('flowComponent.actionFastSelect.action')))])]),_c('div',{staticClass:"fast-select-content-sort"},_vm._l((_vm.unit.content.list),function(item,formIndex){return _c('div',{key:formIndex,staticClass:"fast-select-content-cell"},[_vm._m(0,true),_c('div',{staticClass:"fast-select-content-cell-form"},[_c('div',{staticClass:"select-cell-pre"},[_c('el-input',{attrs:{"size":"small","maxlength":"20","placeholder":_vm.$t('flowComponent.actionFastSelect.namePla')},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}})],1),_c('div',{staticClass:"select-cell"},[_c('div',{staticClass:"select-cell-sub-content"},[_c('div',{staticClass:"select-cell-sub-content-pre"},[_c('el-select',{attrs:{"size":"small","placeholder":_vm.$t('common.selectPlaceholder')},on:{"change":function($event){return _vm.changeScope(formIndex)}},model:{value:(item.action.scope),callback:function ($$v) {_vm.$set(item.action, "scope", $$v)},expression:"item.action.scope"}},_vm._l((_vm.jumpTypeAddSendOptionsFast),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"select-cell-sub-content-aft"},[(
                                            item.action.scope ===
                                                'SEND_MSG_TO_BOT' ||
                                            item.action.scope ===
                                                'SEND_MSG_TO_USER' ||
                                            item.action.scope === 'LINK'
                                        )?_c('el-input',{attrs:{"size":"small","placeholder":item.action.scope === 'LINK'
                                                ? _vm.$t('flowComponent.actionFastSelect.linkPla')
                                                : _vm.$t('flowComponent.actionFastSelect.sendContentPla')},model:{value:(item.action.value),callback:function ($$v) {_vm.$set(item.action, "value", $$v)},expression:"item.action.value"}}):_vm._e(),(item.action.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":item.action.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionFast_select_${formIndex}_${_vm.unitIndex}`,"intentId":_vm.intentId,"index":formIndex},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),(!_vm.isBotLink)?_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(item.action.scope === 'INTENT'),expression:"item.action.scope === 'INTENT'"}],key:`actionFast_cascader_${formIndex}_${_vm.unitIndex}`,attrs:{"filterable":"","size":"small","options":_vm.intentTree4Radio,"placeholder":_vm.$t('flowComponent.confirmValue.jumpIntentPla'),"props":{
                                            emitPath: false,
                                            label: 'name',
                                            value: 'id',
                                        }},model:{value:(item.action.value),callback:function ($$v) {_vm.$set(item.action, "value", $$v)},expression:"item.action.value"}}):_vm._e(),(item.action.scope === 'INTENT' && _vm.isBotLink)?[_c('el-popover',{key:`actionFast_popover_${formIndex}_${_vm.unitIndex}`,ref:`actionFast_popover_${formIndex}_${_vm.unitIndex}`,refInFor:true,attrs:{"placement":"bottom","width":"300","trigger":"click","popper-class":"set-welcome-message-search-intent-poprver","visible-arrow":false},on:{"show":function($event){return _vm.showPover(item.action)}}},[_c('el-input',{staticClass:"search-intent-input",attrs:{"slot":"reference","size":"small","placeholder":_vm.$t('flowComponent.searchOrSelectIntent')},on:{"input":function($event){return _vm.inputFn($event,item.action.valueText)}},slot:"reference",model:{value:(item.action.valueText),callback:function ($$v) {_vm.$set(item.action, "valueText", $$v)},expression:"item.action.valueText"}},[_c('i',{staticClass:"el-icon-arrow-down",attrs:{"slot":"suffix"},slot:"suffix"})]),_c('el-tree',{ref:"tree",refInFor:true,staticClass:"choose-intent-tree",staticStyle:{"width":"300px","height":"300px","overflow-x":"hidden","overflow-y":"auto"},attrs:{"data":_vm.intentTree4Radio,"props":{
                                                    children: 'children',
                                                    label: 'name',
                                                    id:'id',
                                                    isLeaf: 'isLeafNode',
                                                },"filter-node-method":_vm.filterNode,"node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({node,data}){return _c('span',{class:['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled'],on:{"click":function($event){return _vm.onNodeClick(data,node,item.action,formIndex,_vm.unitIndex)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('span',{class:['data-label']},[_vm._v(_vm._s(data.name))])]),(data.checked)?_c('i',{staticClass:"el-icon-check"}):_vm._e()])}}],null,true)})],1)]:_vm._e()],2)])])]),_c('div',{staticClass:"d-a-handle-box"},[(formIndex == 0)?_c('span',{staticClass:"answer-text-cell-handle-icon-add",on:{"click":_vm.addToEntityCell}},[_c('i',{staticClass:"el-icon-plus"})]):_c('span',{staticClass:"answer-text-cell-handle-icon-delete",on:{"click":function($event){return _vm.deleteToEntityCell(formIndex)}}},[_c('i',{staticClass:"el-icon-close"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"move-box"},[_c('span',{staticClass:"iconfont guoran-tongyichicun-16-10-paixu icon-handle-fast"})])
}]

export { render, staticRenderFns }