<template>
    <div class="udtui-delete-node">
        <delete-dialog @closeEvent="deleteNodeDia = false" v-if="deleteNodeDia">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">删除节点</div>
                <div>
                    删除
                    <span style="color: #fe5965">{{ deleteNodeName }}</span>
                    节点后，<br />该节点下的所有子节点都将被删除！
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    style="border-radius: 17px"
                    @click="cancelDeleteNodeDia"
                    size="small"
                    plain
                    >取 消</el-button
                >
                <el-button
                    @click="delNode"
                    style="
                        background-color: #fe5965;
                        color: white;
                        border-radius: 17px;
                    "
                    size="small"
                    >删 除</el-button
                >
            </div>
        </delete-dialog>
    </div>
</template>
<script>
import DeleteDialog from "./../../../../components/errorDialog.vue";
export default {
    components: { DeleteDialog },
    props: ["deleteNodeName", "intentId", "nodeId", "nodeTree"],
    data() {
        return {
            deleteNodeDia: false,
        };
    },
    methods: {
        delNode() {
            this.FetchDelete2(
                this.requestUrl.chatFlowNode.deleteNode +
                    "?intentId=" +
                    this.intentId +
                    "&nodeId=" +
                    this.nodeId
            ).then((res) => {
                if (res.code === "0") {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    this.nodeTree.forEach((item, skillIndex) => {
                        item.children.forEach((cell, classIndex) => {
                            cell.children.forEach((miniItem, intentIndex) => {
                                if (miniItem.id === this.intentId) {
                                    this.$emit(
                                        "closeIntentNodeList",
                                        skillIndex,
                                        classIndex,
                                        intentIndex,
                                        true,
                                        "delete"
                                    );
                                }
                            });
                        });
                    });
                    this.deleteNodeDia = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        cancelDeleteNodeDia() {
            this.deleteNodeDia = false;
        },
    },
};
</script>