<template>
    <div class="answer-audio">
        <div class="respose-video">
            <div class="album albumvideo">
                <div v-if="unit.content.url == ''" class="pic_img">
                    <div class="pic_img_box">
                        <el-upload class="upload-ask" :show-file-list="false" :http-request="((data) => {
                            return aliyunOssRequest(data, 'ask')
                        })" :before-upload="(file) => beforeUploadVideo(file, index)" :on-exceed="uploadExceed"
                            :on-success="
                                (res, file) =>
                                    handleVideoSuccess(res, file, index)
                            " :on-remove="handleRemove" :auto-upload="true" accept=".mp3,.wav" multiple>


                            <el-progress class="aa-loading" width="50" v-if="unit.content.extInfos[0].videoFlag == true"
                                type="circle" :percentage="
                                    unit.content.extInfos[0].videoUploadPercent
                                " style="margin-top: 7px"></el-progress>
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </div>
                <div class="ask-audio" v-show="unit.content.url != ''">

                    <span @click="deleteVoice" class="delete-answer-audio">
                        <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
                    </span>
                    <div class="ask-audio-title">{{ unit.content.filenName }}</div>
                    <div class="ask-audio-content">
                        <audio :id="'audioTag' + this.unit.content.extInfos[0].avCurrentTime" :src="unit.content.url"></audio>
                        <div class="audiocon">
                            <!-- 按钮 -->
                            <div class="leftBtn">
                                <span v-show="!isPlay" @click="bofang"><i class="iconfont guoran-bofang"></i></span>
                                <span v-show="isPlay" @click="bofang"><i class="iconfont guoran-zanting"></i></span>
                            </div>
                            <div class="ask_adcon">
                                <div class="time">
                                    <span>{{ time }}</span>
                                </div>
                                <div class="bar">
                                    <div class="activeLine" @click="seekTime">
                                        <div :style="{ width: currentPosi + '%' }" class="dot"></div>
                                    </div>
                                </div>
                                <div class="time">
                                    <span>{{ allTime }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-upload class="upload-ask" :show-file-list="false" :http-request="((data) => {
                        return aliyunOssRequest(data, 'ask')
                    })" :before-upload="(file) => beforeUploadVideo(file, index)" :on-exceed="uploadExceed"
                        :on-success="
                            (res, file) =>
                                handleVideoSuccess(res, file, index)
                        " :on-remove="handleRemove" :auto-upload="true" accept=".mp3,.wav" multiple>
                        <span class="reupload-answer-audio">
                            <i class="iconfont guoran-youxuan"></i>
                        </span>
                    </el-upload>

                </div>
                <p class="Upload_pictures">
                    <span></span>
                    <span>{{$t('flowComponent.uploadVoiceTip')}}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { ossConfig, multipartUpload, ossFileUrl } from "./js/AliyunIssUtilWithProcess"
export default {
    props: ["unit", "index"],
    data() {
        return {
            importFileUrl: location.origin + "/api/oss",
            active: 0,
            time: 0,
            _audio: "",
            isPlay: false,
            currentPosi: 0,
            allTime: 0,
        };
    },

    methods: {
        deleteVoice() {
            this.unit.content.url = '';
            this.unit.content.extInfos[0].videoFlag = false;
            this.$forceUpdate();
        },
        stopAudioPlay(refId){
            console.log("stop ", refId, this.unit.content.extInfos[0].avCurrentTime);
            if (refId != this.unit.content.extInfos[0].avCurrentTime) {
                this._audio.pause();
                this.isPlay = false;
            }
        },
        bofang() {
            if (this._audio.paused) {
                this._audio.play();
                this.isPlay = true;
                this.$emit("stopOtherAudio", this.unit.content.extInfos[0].avCurrentTime);
            } else {
                this._audio.pause();
                this.isPlay = false;
            }
            
        },
        transTime(time) {
            var duration = parseInt(time);
            var minute = parseInt(duration / 60);
            var sec = (duration % 60) + "";
            var isM0 = ":";
            if (minute == 0) {
                minute = "00";
            } else if (minute < 10) {
                minute = "0" + minute;
            }
            if (sec.length == 1) {
                sec = "0" + sec;
            }
            return minute + isM0 + sec;
        },
        updateProgress() {
            this.currentPosi = (this._audio.currentTime / this._audio.duration) * 100;
            this.time = this.transTime(this._audio.currentTime);
        },

        audioEnded() {
            this._audio.currentTime = 0;
            this._audio.pause();
            this.isPlay = false;
        },

        seekTime(e) {
            var rate = e.offsetX / e.target.clientWidth;
            this._audio.currentTime = this._audio.duration * rate;
            this.updateProgress();
        },


        aliyunOssRequest(data) {
            let file = data.file;
            let res = multipartUpload(ossConfig, file, (process) => {
                this.unit.content.extInfos[0].videoFlag = true;
                this.unit.content.extInfos[0].videoUploadPercent =
                    (process * 100).toFixed(0);
            }, ()=>{}, true);
            // console.debug('oss upload res', data, res);
            return res;
        },
        //上传前回调
        beforeUploadVideo(file, index) {
            // console.log(file, index);
            console.log(file.type);
            this.activeVideoIndex = index;
            var fileSize = file.size / 1024 / 1024 < 10;
            if (
                [
                    "audio/mpeg",
                    "audio/wav",
                ].indexOf(file.type) == -1
            ) {
                this.$message.error("请上传正确的音频格式");
                return false;
            }
            if (!fileSize) {
                this.$message.error("视频大小不能超过10MB");
                return false;
            }
            console.log(file);
            // this.unit.content.url = "";
            this.unit.content.extInfos[0].videoFlag = true;
            this.isShowUploadVideo = false;
        },
        //进度条
        uploadVideoProcess(event, file, fileList, activeVideoIndex) {
            this.unit.content.extInfos[0].videoFlag = true;
            this.unit.content.extInfos[0].videoUploadPercent =
                file.percentage.toFixed(0) - 0;
            console.log(file.percentage.toFixed(0) - 0);
            // this.videoFlag = true;
            // this.videoUploadPercent = file.percentage.toFixed(0) * 1;
        },
        //上传成功回调
        handleVideoSuccess(res, file, activeVideoIndex) {
            console.log(res, file);
            // this.isShowUploadVideo = true;
            this.unit.content.extInfos[0].isShowUploadVideo = true;
            this.unit.content.extInfos[0].videoFlag = false;
            this.unit.content.extInfos[0].videoUploadPercent = 0;
            // this.unit.content.videoUploadPercent = 0;
            // this.videoFlag = false;
            // this.videoUploadPercent = 0;
            this.currentPosi = 0;

            //前台上传地址
            if (file.status == "success") {
                // this.videoForm.showVideoPath = file.url;
                this.unit.content.url = ossFileUrl(ossConfig, res.name);
                this.unit.content.filenName = file.name;
            } else {
                this.$message.error("上传失败，请重新上传");
            }

            //后台上传地址
            // if (res.code == 0) {
            //     // this.videoForm.showVideoPath = res.data;
            //     this.unit.content.url = ossFileUrl(ossConfig, res.name);
            // } else {
            //     this.$message.error(res.message);
            // }
        },
    },
    mounted() {
        console.log(120);
        this.$nextTick(() => {
            setTimeout(() => {
                console.log(this._$(`#audioTag${this.unit.content.extInfos[0].avCurrentTime}`)[0]);
                this._audio = this._$(`#audioTag${this.unit.content.extInfos[0].avCurrentTime}`)[0];
                this.time = "00:00";
                console.log(this._audio.duration);
                this.allTime = this.transTime(this._audio.duration);
                // this.unit.content.extInfos.duration = this._audio.duration;
                this._$(`#audioTag${this.unit.content.extInfos[0].avCurrentTime}`).on("loadedmetadata", (e) => {
                    console.log(e.currentTarget);
                    this.time = "00:00";
                    console.log(e.currentTarget.duration);
                    this.allTime = this.transTime(e.currentTarget.duration);
                    // this._audio.play();
                    // this.isPlay = true;
                    this.unit.content.extInfos[0].duration = e.currentTarget.duration;

                    console.log(this.unit.content.extInfos);
                    this.currentPosi = 0;
                    this.isPlay = false;
                });

                document.addEventListener(
                    "WeixinJSBridgeReady",
                    function () {
                        this._audio.play();
                    },
                    false
                );

                this._$(`#audioTag${this.unit.content.extInfos[0].avCurrentTime}`).on("timeupdate", () => {
                    this.updateProgress();
                });

                this._$(`#audioTag${this.unit.content.extInfos[0].avCurrentTime}`).on("timeupdate", () => {
                    this.updateProgress();
                });

                this._$(`#audioTag${this.unit.content.extInfos[0].avCurrentTime}`).on("ended", () => {
                    this.audioEnded();
                });
            }, 500);

        });
    },
    activated() {
        // this.isPlay = this.isMobile;
    },
};
</script>
<style lang="less" scoped>
.upload-ask {
    position: relative;

    .aa-loading {
        position: absolute;
        top: 8px;
        left: 15px;
    }
}


.ask-audio {
    height: 86px;
    padding: 0 15px;
    width: 377px;
    height: 86px;
    background: #FAFBFF;
    border: 1px solid #E0E6F7;
    border-radius: 5px;
    position: relative;

    .delete-answer-audio {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;

        i {
            color: #366AFF;
            cursor: pointer;
        }
    }

    .reupload-answer-audio {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        bottom: -5px;
        right: -25px;

        i {
            color: #366AFF;
            cursor: pointer;
        }
    }

    .ask-audio-title {
        height: 32px;
        line-height: 32px;
        margin-top: 6px;
        margin-bottom: 5px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ask-audio-content {
        .audiocon {
            display: flex;
            align-items: center;
            width: 100%;
            height: 30px;
            justify-content: space-between;
        }

        .leftBtn {
            width: 30px;

            span {
                i {
                    font-size: 30px;
                    color: #ADC0F6;
                }
            }
        }

        .ask_adcon {
            position: relative;
            z-index: 10;
            width: calc(100% - 40px);
            height: 30px;
            display: flex;
            align-items: center;

            .bar {
                width: calc(100% - 60px);
                position: relative;
                height: 30px;
                display: flex;
                align-items: center;

                .activeLine {
                    position: absolute;
                    top: 0;
                    left: 0px;
                    z-index: 10;
                    width: 96%;
                    height: 30px;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        border-radius: 4px;

                        background-color: #D5DDEC;
                    }
                }

                .dot {
                    border-radius: 4px;
                    z-index: 11;
                    pointer-events: none;
                    position: absolute;
                    height: 4px;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    background-color: #366AFF;

                    &::before {
                        content: "";
                        position: absolute;
                        top: -4px;
                        right: -3px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: white;
                        border: solid 4px #366AFF;
                    }
                }
            }

            .time {
                width: 58px;
                font-size: 14px;
                color: #222222;
                font-weight: 500;
                display: flex;
            }
        }
    }


}

.answer-audio {
    margin-bottom: 16px;

    .respose-video {
        margin-top: 18px;
        display: flex;
        justify-content: space-around;

        .video-avatar {
            border-radius: 5px;
        }

        .albumvideo {
            height: 124px;
            width: 380px;

            .pic_img {
                margin-bottom: 10px;
                margin: 10px auto 0;
                height: 77px;
                width: 77px;

                /deep/.el-upload {
                    height: 77px;
                    width: 77px;
                    background: #fafbff;
                    border: 1px solid #e0e6f7;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 77px;
                    font-size: 22px;
                    color: #87a9ff;
                }

                .el-upload:hover {
                    border: 1px dashed skyblue;
                }

                /deep/.el-icon-plus {
                    margin-top: 25px;
                    font-size: 28px;
                    color: #87a9ff;
                }
            }
        }

        .Upload_pictures {
            margin-top: 18px;
            color: #a9b3c6;
            font-size: 13px;
            position: relative;
        }
    }
}
</style>