<template>
    <ChatFlowComponent :isPart="0" :intentIds="['','']" :drawerSize="1000" />
</template>
<script>
import ChatFlowComponent from "./FlowComponent.vue";
// import ChatFlowComponent from "./TestFlowComponent.vue";
export default {
    components: { ChatFlowComponent },
    data() {
        return {};
    },
    created() {

    },
    mounted() {
        this.FetchGet(this.requestUrl.bindV2.getTagsOptions).then((res) => {
            if (res.code == 0) {
                let tagListIds = [];
                if (res.data && res.data.length != 0) {
                    tagListIds = res.data.map(item => item.id);
                }
                localStorage.setItem("ask_tagListIds", tagListIds);
            }
        })
    },
};
</script>
<style lang="less" scoped>
</style>
