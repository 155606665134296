<template>
    <popup v-if="addEffectiveTimeVisible" @closeEvent="handleVisible('close')">
        <div slot="popup-name">{{$t('effectiveTime.title')}}</div>
        <div slot="popup-tip">{{$t('effectiveTime.tip')}}</div>
        <div id="effective-time" slot="popup-con">
            <div class="effective-time-content">
            <span class="et-title">{{$t('effectiveTime.type')}}</span>
            <div class="et-content et-content-type">
                <el-radio-group @change="changeEffectiveTimeType" v-model="effectiveTimeObj.range">
                    <el-radio label="PERMANENT">{{$t('effectiveTime.PERMANENT')}}</el-radio>
                    <el-radio label="TIME">{{$t('effectiveTime.TIME')}}</el-radio>
                    <el-radio label="DATETIME">{{$t('effectiveTime.DATETIME')}}</el-radio>
                    <el-radio label="EFFECTIVE_TIME">{{$t('effectiveTime.EFFECTIVE_TIME')}}</el-radio>
                </el-radio-group>
            </div>
            <div v-show="effectiveTimeObj.range === 'TIME'" class="et-content-box">
                <span class="et-title">{{$t('effectiveTime.selectTime')}}</span>
                <div class="et-content date-content">
                    <el-time-picker size="small" is-range @change="typeTimePickerChange"
                        v-model="effectiveTimeObj.timeRange" range-separator="至" start-placeholder="开始时间"
                        end-placeholder="结束时间" placeholder="选择时间范围">
                    </el-time-picker>
                </div>
            </div>
            <div v-show="effectiveTimeObj.range === 'TIME'" class="et-content-box">
                <div class="et-title-select">
                    <span>{{$t('effectiveTime.repeat')}}</span>
                    <span>
                        <el-checkbox @change="checkedAllWekHandle" v-model="checkedAllWek">{{$t('effectiveTime.allCheck')}}</el-checkbox>
                    </span>
                </div>
                <div class="el-checkbox-group-box">
                    <el-checkbox-group v-model="effectiveTimeObj.repeat" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="city in wekList" :label="city.id" :key="city.id">{{ $t('effectiveTime.' + city.id) }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div v-show="effectiveTimeObj.range === 'DATETIME'" class="et-content-box">
                <span class="et-title">{{$t('effectiveTime.selectDateTimeRange')}}</span>
                <div class="et-content date-content">
                    <el-date-picker v-model="effectiveTimeObj.timeRange" @change="changeR" size="small"
                        type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
            </div>
            <div v-show="effectiveTimeObj.range === 'EFFECTIVE_TIME'" class="et-content-box">
                <span class="et-title">{{$t('effectiveTime.selectDateTime')}}</span>
                <div class="et-content et-content-no-range">
                    <el-date-picker v-model="effectiveTimeObj.timeRange" size="small" type="datetime"
                        :placeholder="$t('effectiveTime.selectDateTime')">
                    </el-date-picker>
                    <span class="n-range">~</span>
                    <span class="n-range">{{$t('effectiveTime.unlimited')}}</span>
                </div>
            </div>
            <div v-show="effectiveTimeObj.range !== 'PERMANENT'" class="et-content-box et-content-box-bt">
                <span>{{$t('effectiveTime.filterHolidays')}}：</span>
                <div class="et-content-type">
                    <el-radio-group v-model="effectiveTimeObj.filterHolidays">
                        <el-radio :label="true">{{$t('effectiveTime.yes')}}</el-radio>
                        <el-radio :label="false">{{$t('effectiveTime.no')}}</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div v-if="effectiveTimeObj.range == 'TIME'" class="et-content-box et-content-box-bt">
                <span>{{$t('effectiveTime.compensatoryLeave')}}：</span>
                <div class="et-content-type">
                    <el-radio-group v-model="effectiveTimeObj.balanceHolidays">
                        <el-radio :label="true">{{$t('effectiveTime.yes')}}</el-radio>
                        <el-radio :label="false">{{$t('effectiveTime.no')}}</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        </div>
        <div slot="dialog-footer">
            <el-button @click="handleVisible('close')" plain size="small">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="handleVisible('save')" size="small">{{$t('common.confirm')}}</el-button>
        </div>
    </popup>
</template>
<script>
import popup from "./../../../components/popupNew.vue";
export default {
    components: { popup },
    props: [
        "addEffectiveTimeVisible",
        "effectiveTimeObjP",
        "timeTimeRange",
        "timeRepeat",
    ],
    data() {
        return {
            name: "effective-time",
            effectiveTimeObj: {},
            wekList: [
                { lable: "周一", id: "1" },
                { lable: "周二", id: "2" },
                { lable: "周三", id: "3" },
                { lable: "周四", id: "4" },
                { lable: "周五", id: "5" },
                { lable: "周六", id: "6" },
                { lable: "周日", id: "7" },
            ],
            checkedWek: [],
            checkedAllWek: true,
            startTime: "",
        };
    },
    methods: {
        timeZone(nowDate) {
            var timezone = 8; //目标时区时间，东八区   东时区正数 西市区负数
            var offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
            // var nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
            var targetDate = new Date(
                nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000
            );
            return targetDate;
        },
        setEffectiveTimeObj(data) {
            // console.log(data);
        },
        typeTimePickerChange(value) {
            this.$set(this.effectiveTimeObj, "startTime", value[0]);
            this.$set(this.effectiveTimeObj, "endTime", value[1]);
        },
        changeR(data) {
            // console.log(data);
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
        changeEffectiveTimeType(value) {
            if (value === "TIME") {
                this.effectiveTimeObj.timeRange = [
                    new Date("Thu Jan 07 2020 00:00:00 GMT+0800"),
                    new Date("Thu Dec 07 2020 23:59:59 GMT+0800"),
                ];
            }
            if (value === "DATETIME") {
                this.effectiveTimeObj.timeRange = [
                    this.timestampToTime(new Date().getTime()),
                    this.timestampToTime(new Date(new Date().setDate(new Date().getDate() + 30)).getTime()),
                ];

                // console.log(this.effectiveTimeObj.timeRange);
            }
            if (value === "EFFECTIVE_TIME") {
                this.effectiveTimeObj.timeRange = new Date();
            }
        },
        handleVisible(type) {
            if (
                type == "save" &&
                this.effectiveTimeObj.range != "PERMANENT" &&
                this.effectiveTimeObj.timeRange == null
            ) {
                this.$message.warning("请选择生效时间");
                return false;
            }
            if (this.effectiveTimeObj.range == "EFFECTIVE_TIME") {
                this.effectiveTimeObj.startTime = this.effectiveTimeObj.timeRange;
            } else {
                if (
                    this.effectiveTimeObj.timeRange &&
                    this.effectiveTimeObj.timeRange.length > 0
                ) {
                    if (this.effectiveTimeObj.range == "DATETIME") {
                        this.effectiveTimeObj.startTime = this.timeZone(
                            new Date(
                                this.effectiveTimeObj.timeRange[0]
                            ).getTime()
                        );
                        this.effectiveTimeObj.endTime = this.timeZone(
                            new Date(
                                this.effectiveTimeObj.timeRange[1]
                            ).getTime()
                        );
                    } else {
                        this.effectiveTimeObj.startTime = this.effectiveTimeObj.timeRange[0];
                        this.effectiveTimeObj.endTime = this.effectiveTimeObj.timeRange[1];
                    }
                }
            }
            if (this.effectiveTimeObj.range == "PERMANENT") {
                this.effectiveTimeObj.startTime = null;
                this.effectiveTimeObj.endTime = null;
            }
            this.$emit("addEffectiveTimeVisibleHandle", {
                status: false,
                type: type,
                data: this.effectiveTimeObj,
            });
            setTimeout(() => {
                this.checkedAllWek = true;
            }, 500)
        },
        handleCheckedCitiesChange(value) {
            this.checkedWek = value;
        },
        checkedAllWekHandle(value) {
            this.effectiveTimeObj.repeat = value
                ? ["1", "2", "3", "4", "5", "6", "7"]
                : [];
        },
    },
    watch: {
        checkedWek: function (newValue, oldValue) {
            this.checkedAllWek = newValue.length == 7;
        },
        effectiveTimeObjP: function (newValue, oldValue) {
            this.effectiveTimeObj = this.effectiveTimeObjP;
            if (this.effectiveTimeObj.repeat.length != 7) {
                this.checkedAllWek = false;
            }
        },
    },
    computed: {},
    created() {
        this.effectiveTimeObj = this.effectiveTimeObjP;
    },
};
</script>
<style lang="less" scoped>
#effective-time {
    .effective-time-content {

        // color: tomato;
        .et-title {
            display: block;
            text-align: left;
            margin: 6px 4px 6px 0px;
        }

        .date-content {
            .el-range-editor {
                width: 100%;
            }
        }

        .et-title-select {
            display: flex;
            justify-content: space-between;
            margin: 12px 4px 12px 0;
        }

        .el-checkbox-group-box {
            background-color: #fbfcfd;
            border-radius: 5px;
            padding: 6px;
            text-align: left;
            .el-checkbox-group {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
            }
        }

        .et-content-type {
            background-color: #fbfcfd;
            border-radius: 5px;
            padding: 6px;
            text-align: left;
        }

        .et-content-box-bt {
            text-align: left;
            margin: 12px 0px 4px;
            .et-content-type {
                margin-top: 8px;
                background-color: #fbfcfd;
                border-radius: 5px;
                padding: 6px;
                text-align: left;
            }
        }

        .et-content-no-range {
            display: flex;
            justify-content: flex-start;

            .n-range {
                margin-left: 16px;
                line-height: 32px;
                height: 32px;
            }
        }
    }
}
</style>