let skillItem = {
    children: [],
    display: true,
    exampleQuestion: null,
    ext: null,
    hasProcess: false,
    id: "S1014",
    isLeafNode: false,
    name: "108 组件类型",
    show: true,
}
let intentItem = {
    id: "3063",
    name: "吴用 answer_card_template",
    isLeafNode: true,
    hasProcess: true,
    exampleQuestion: "智多星",
    children: [],
    display: false,
    ext: { "isShare": 0, "isDefault": false, "isOpen": true },
    label: "吴用 answer_card_template",
    value: "3063",
    show: true
}

export { skillItem, intentItem }