<template>
    <div id="copy-intent-baili">
        <popup @closeEvent="closeCopyIntent" v-if="visible" :haveTips="false"  :isNeedScroll="false">
            <div slot="popup-name">{{$t('botIntent.copyIntent')}}</div>
            <div slot="popup-con">
                <div class="intent-tree-content">
                    <!-- <span class="copy-intent-title">从现有意图中复制</span> -->
                    <div class="intent-tree-box">
                        <el-tree
                            ref="allIntentTree"
                            :data="intentOptions"
                            :default-expanded-keys="defaultExpandedKeys"
                            show-checkbox
                            node-key="id"
                            :empty-text="$t('botIntent.noIntentText')"
                            :current-node-key="currentNodeKey"
                            :props="defaultProps"
                        >
                            <span style="justify-content: flex-start;" class="custom-tree-node" slot-scope="{ node, data }">
                                <span
                                    v-if="data.type == 'BOT'"
                                    class="pre-icon-type iconfont guoran-tongyichicun-jiqirentouxiang"
                                ></span>
                                <span
                                    v-if="data.type == 'SKILL'"
                                    class="pre-icon-type iconfont guoran-tongyichicun-zujianzhishiku"
                                ></span>
                                <span
                                    v-if="data.type == 'TYPE'"
                                    class="pre-icon-type iconfont guoran-tongyichicun-morenfenlei"
                                ></span>
                                <span
                                    v-if="data.type == 'INTENT'"
                                    class="pre-icon-type el-icon-tickets"
                                ></span>
                                <span>{{ node.label }}</span>
                            </span>
                        </el-tree>
                    </div>
                </div>
                <div class="intent-selected-content">
                    <span class="copy-intent-title">{{$t('botIntent.chooseIntentionalLocation')}}</span>
                    <div class="intent-selected-box">
                        <el-cascader
                            v-model="deraction"
                            size="small"
                            :placeholder="$t('botIntent.chooseIntentionalLocation')"
                            :options="skillList"
                            :props="optionProps"
                        ></el-cascader>
                    </div>
                    <!-- <div class="copy-intent-footer">
                        <el-button @click="closeCopyIntent" size="small" round class="cancel-btn"
                            >关闭</el-button
                        >
                        <el-button @click="submitAdd" size="small" type="primary" round circle="confirm-btn"
                            >导入</el-button
                        >
                    </div> -->
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeCopyIntent" round class="cancel-btn" >{{$t('common.close')}}</el-button>
                <el-button type="primary" @click="submitAdd" round class="confirm-btn" >{{$t('common.import')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import popup from '../../../components/popupNew.vue'
export default {
    props:['visible','botId'],
    components:{popup},
    data() {
        return {
            intentOptions: [],
            skillList: [],
            deraction: "0",
            optionProps: {
                value: "id",
                label: "name",
                children: "children",
                expandTrigger: "hover",
            },
            defaultProps: {
                children: "children",
                label: "name",
            },
            currentNodeKey: [],
            defaultExpandedKeys:[]
        };
    },
    watch:{
        visible:{
            handler(n){
                if(n){
                    this.deraction = '0';
                    this.getSkillListC(this.$route.query.id);
                }
            }
        }
    },
    methods: {
        submitAdd() {
            if (this.$refs.allIntentTree.getCheckedKeys(true).length == 0) {
                this.$message.warning("请至少选择一个现有意图");
                return false;
            }
            let deraction = "";
            if (this.deraction instanceof Array) {
                deraction = this.deraction[this.deraction.length - 1];
            } else {
                deraction = this.deraction;
            }
            console.log(deraction);
            this.FetchPost(this.requestUrl.intent.copyIntent, {
                intentIds: this.$refs.allIntentTree.getCheckedKeys(true),
                targetTypeId:
                    deraction.length > 1
                        ? deraction.substring(1, 20)
                        : deraction,
                targetBotId: this.$route.query.id || this.botId,
            }).then((res) => {
                if (res.code === "0") {
                    this.$emit("closeCopyIntent");
                    this.$emit("switchSkillCopy");
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        closeCopyIntent() {
            this.$emit("closeCopyIntent");
        },
        getIntentTree(bId) {
            this.FetchGet(this.requestUrl.bot.botSkillIntentTree, {
                isShare: 1,
            }).then((res) => {
                if (res.code === "0") {
                    let data = [];
                    res.data.forEach(item => {
                        if (item.id != bId) {
                            data.push(item);
                        }
                    })
                    this.intentOptions = data;
                    // 部分冗余代码，待整理
                    this.intentOptions.forEach((bot) => {
                        if (bot.children == null) {
                            bot.disabled = true;
                        } else {
                            if (
                                bot.children.length == 1 &&
                                bot.children[0].children == null
                            ) {
                                bot.disabled = true;
                            }
                            bot.children.forEach((skill) => {
                                if (skill.children == null) {
                                    skill.disabled = true;
                                } else {
                                    if (
                                        skill.children.length == 1 &&
                                        skill.children[0].children == null
                                    ) {
                                        skill.disabled = true;
                                    }
                                    skill.children.forEach((type_) => {
                                        if (type_.children == null) {
                                            type_.disabled = true;
                                        }
                                    });
                                }
                            });
                        }
                    });
                    this.intentOptions.forEach((bot) => {
                        if (bot.children != null) {
                            bot.children.forEach((skill) => {
                                if (skill.children != null) {
                                    let flag = true;
                                    skill.children.forEach((type_) => {
                                        if (!type_.disabled) {
                                            flag = false;
                                        }
                                    });
                                    skill.disabled = flag;
                                }
                            });
                        }
                    });
                    this.intentOptions.forEach((bot) => {
                        if (bot.children != null) {
                            let flag = true;
                            bot.children.forEach((skill) => {
                                if (!skill.disabled) {
                                    flag = false;
                                }
                            });
                            bot.disabled = flag;
                        }
                    });
                    this.defaultExpandedKeys = [data[0].id]
                    console.log(this.intentOptions,222222222);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取技能list
        getSkillListC(botId) {
            this.FetchGet(this.requestUrl.intent.getIntentTree, {
                bId: botId,
            }).then((res) => {
                if (res.code === "0") {
                    let data = [];
                    res.data.forEach(item => {
                        if (item.name != '特殊规则') {
                            data.push(item);
                        }
                    })
                    this.skillList = data;
                    this.skillList.forEach((item) => {
                        if (item.children != null) {
                            item.children.forEach((cell) => {
                                cell.children = null;
                            });
                        }
                    });
                    this.skillList.push({
                        children: null,
                        display: true,
                        hasProcess: true,
                        id: "0",
                        isLeafNode: true,
                        name: "跟随意图",
                        type: "QA",
                    });
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
    mounted() {
        this.getIntentTree(this.$route.query.id);
        
    },
};
</script>

<style lang="less" scoped>
#copy-intent-baili {
    /deep/ .intent-tree-content {
        background-color: #FBFCFD;
        // height: 363px;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #616161;
        overflow-x: hidden;
        overflow-y: auto;
      
        .el-tree-node__expand-icon{
            color: #616161;
        }
        .el-tree-node__expand-icon.is-leaf {
            color: transparent;
            cursor: default
        }
        .el-tree-node__expand-icon,.custom-tree-node{
            color:#616161 ;
        }
        .pre-icon-type {
            color: #A1B9FF;
            display: inline-block;
            margin: 0 8px;
            font-size: 14px;
        }
        .arsenal_icon{
            font-size: 14px;
        }
        .copy-intent-title {
            display: block;
            height: 42px;
            line-height: 42px;
            text-align: left;
            font-weight: 600;
            background: #F3F6F8;
            font-size: 16px;
            text-align: center;
            margin-bottom: 20px;
            color: #000;
        }
        .intent-tree-box {
            max-height: calc(84vh - 350px);
            min-height: 160px;
            overflow-y: scroll;
            padding: 0 30px;
            .el-tree{
               background-color: #FBFCFD; 
            }
        }
    }
    .intent-selected-content {
        .copy-intent-title {
            display: block;
            line-height: 38px;
            text-align: left;
            font-size: 14px;
            color: #616161;
        }
        .intent-selected-box {
            text-align: left;
            .el-cascader {
                width: 100%;
            }
        }
        .copy-intent-footer {
           text-align: center;
            margin-top: 30px;
            .el-button{
                width: 99px;
                height: 37px;
                border-radius: 19px;
                border-color: #E0E6F7;
                padding: 0;
                text-align: center;
                font-size: 14px;
                &.el-button--primary{
                    border-color: #366AFF;
                    background-color: #366AFF;
                }

            }
        }
    }
}
</style>