<template>
    <div>
        <popup
            id="open-super-intnet"
            v-if="addIntentDiaVisible"
            @closeEvent="addIntentDiaVisible = false"
        >
            <div slot="popup-name">{{$t('addIntent.title')}}</div>
            <div slot="popup-tip">{{$t('addIntent.tip')}}</div>
            <div id="superIntentConfig-intent-add" slot="popup-con">
                <div style="text-align: left" class="copy-intent-btn">
                    <el-button
                        style="line-height: 20px;font-size:12px"
                        @click="copyIntentDia"
                        icon="guoran-tongyichicun-fuzhi iconfont"
                        size="mini"
                        plain
                        round
                        > {{$t('addIntent.copy')}}</el-button
                    >
                </div>
                <ul class="addIntentInfo">
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title"
                                >{{$t('addIntent.intentName')}} <span class="red-text">*</span>
                            </span>
                            <el-input
                                v-model="intentName"
                                :maxlength="maxLengthLimit"
                                :placeholder="$t('addIntent.intentNamePla')"
                            ></el-input>
                        </div>
                    </li>
                    <li class="item-config">
                      <div class="block-add-intent">
                        <span class="title">{{$t('addIntent.intentDes')}}</span>
                        <el-input
                            type="textarea"
                            :rows="3"
                            v-model="intentDescription"
                            :placeholder="$t('addIntent.desPlaceholder')"
                        ></el-input>
                      </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title"
                                >{{$t('addIntent.exampleQuestion')}}<span class="red-text">*</span></span
                            >
                            <el-input
                                v-model="exampleQuestion"
                                :maxlength="maxLengthLimit"
                                :placeholder="$t('addIntent.exampleQuestionTip1') + maxLengthLimit + $t('addIntent.exampleQuestionTip2')"
                            ></el-input>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent share-radio">
                            <span class="title">{{$t('addIntent.sharingIntent')}}</span>
                            <el-radio-group v-model="isShare">
                                <el-radio :label="1">{{$t('addIntent.yes')}}</el-radio>
                                <el-radio :label="0">{{$t('addIntent.no')}}</el-radio>
                            </el-radio-group>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="classification">
                            <span class="title">{{$t('addIntent.knowledgeBaseAndClassification')}}<span class="red-text">*</span></span>
                            <el-cascader
                                :disabled="isDefault"
                                v-if="addIntentDiaVisible"
                                v-model="classification"
                                style="width: 100%"
                                :props="{ emitPath: false }"
                                :options="classificationOption"
                                @expand-change="handleChange2"
                                @change="changeClassification"
                            ></el-cascader>
                        </div>
                    </li>
                </ul>
                <ul class="superIntentConfig-add-inetent">
                    <li class="item-config">
                        <div class="block-add-intent open-super-intent">
                            <span class="title">{{$t('addIntent.openSuperIntent')}}</span>
                            <div class="right">
                                <div class="evaluate-table-switch">
                                    <el-switch
                                        @click.native.prevent="
                                            changeEnable('switch')
                                        "
                                        :width="42"
                                        v-model="currentSuperIntentList.enable"
                                        active-color="#366AFF"
                                        inactive-color="#E2E2E2"
                                    >
                                        >
                                    </el-switch>
                                    <span
                                        class="switch-open-icon"
                                        @click="changeEnable('open')"
                                        v-if="currentSuperIntentList.enable"
                                        ><i class="iconfont guoran-a-16-17"></i
                                    ></span>
                                    <span
                                        class="switch-close-icon"
                                        @click="changeEnable('close')"
                                        v-if="!currentSuperIntentList.enable"
                                        ><i class="arsenal_icon arsenalcuo1"></i
                                    ></span>
                                </div>
                                <div class="desc">
                                  {{$t('addIntent.openSuperIntentTip')}}
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item-config">
                        <div
                            class="block-add-intent"
                            v-show="currentSuperIntentList.enable"
                        >
                            <span class="title">
                                <i
                                    class="iconfont guoran-tongyichicun-shili"
                                ></i>
                                {{$t('addIntent.selectType')}}</span
                            >
                            <el-select
                                :key="intentClassKey"
                                v-model="selectData.name"
                                @visible-change="selectVisibleChange($event)"
                                @focus="superIntentFocus()"
                                @change="selectTrigger()"
                                filterable
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    :class="[
                                        clearStatus ? 'clearStatus' : 'no',
                                    ]"
                                    v-for="item in superIntentList"
                                    :key="item.id"
                                    :value="item.name"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </li>
                    <li
                        class="exmplebox item-config"
                        v-show="
                            currentSuperIntentList.enable &&
                            selectData.examples != ''
                        "
                    >
                        <span class="title">{{$t('addIntent.superIntentExample')}}</span>
                        <div class="exmple">
                            <div
                                v-for="item in selectData.examples"
                                :key="item.id"
                                class="text"
                            >
                                <div></div>
                                {{ item }}
                            </div>
                        </div>
                    </li>
                    <li
                        class="item-config"
                        v-show="
                            currentSuperIntentList.enable &&
                            selectData.examples != ''
                        "
                    >
                        <div class="blockdes">
                            <span class="title">
                                <i
                                    class="iconfont guoran-tongyichicun-shili"
                                ></i>
                                {{$t('addIntent.superIntentExampleTip1')}}
                            </span>
                            <span class="des"
                                >{{$t('addIntent.superIntentExampleTip2')}}</span
                            >
                        </div>
                        <div class="config">
                            <div
                                class="pattern"
                                v-for="(item, index) in selectData
                                    .templateConfig.pattern"
                                :key="index"
                            >
                                <span class="keyword-recommend">
                                    <el-input
                                        @click.stop.native="
                                            changeInputSuper(
                                                $event,
                                                item,
                                                index,
                                                'focus'
                                            )
                                        "
                                        @input="
                                            changeInputSuper(
                                                $event,
                                                item,
                                                index
                                            )
                                        "
                                        v-model.trim="item.value"
                                        size="small"
                                        :placeholder="$t('addIntent.keyword')"
                                        v-show="
                                            item.isFillIn == true &&
                                            item.type != 1
                                        "
                                    ></el-input>
                                    <botKeywordRecommend
                                        :key="'superIntentConfigs' + index"
                                        :showBotKeywordRecommend.sync="
                                            item.showBotKeywordRecommend
                                        "
                                        :dataObj="item"
                                        :dataIndex="index"
                                        :keyword="item.value"
                                        :left="0"
                                        :positionTop="36"
                                        @showBotKeywordRecommend="
                                            showBotKeywordRecommendFn
                                        "
                                        @closeBotKeywordRecommend="
                                            closeBotKeywordRecommend(item)
                                        "
                                        @addToInput="
                                            addToInputSuper($event, item, index)
                                        "
                                    >
                                    </botKeywordRecommend>
                                </span>
                                <span
                                    class="word"
                                    v-show="
                                        item.type == 1 && item.isFillIn == false
                                    "
                                    >{{ item.words[0] }}</span
                                >
                            </div>
                        </div>
                        <div class="pattern config-example">
                            <div class="title"><span>{{$t('addIntent.configExample')}}</span></div>
                            <div class="bottom-example">
                                <div
                                    :class="[
                                        'pattern',
                                        item.isFillIn ? 'border' : '',
                                    ]"
                                    v-for="(item, index) in selectData
                                        .templateConfig.example"
                                    :key="index"
                                >
                                    <span
                                        :class="[
                                            item.type != '1'
                                                ? 'word input'
                                                : 'word',
                                        ]"
                                        v-for="(
                                            todo, cindex
                                        ) in selectData.templateConfig.example[
                                            index
                                        ].words.join(',')"
                                        :key="cindex"
                                        >{{ todo }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="addIntentDiaVisible = false"
                    plain
                    round
                    class="cancel-btn"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="submitAddBot"
                    round
                    :loading="addIntentLoading"
                    class="confirm-btn"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
        <!-- 可能与已有的以下意图相似 -->
        <popup
            @closeEvent="closeSimilIntentDialogVisible"
            v-show="similIntentDialogVisible"
            :popZindex="2510"
            :haveTips="false"
            id="same-intnet-go-look"
        >
            <div slot="popup-name">{{$t('addIntent.prompt')}}</div>
            <div slot="popup-con">
                <div class="intent-link-title">{{$t('addIntent.promptTip')}}</div>
                <div>
                    <div
                        v-for="intent in similIntentList"
                        :key="intent.id"
                        class="intent-link-cell"
                    >
                        <span class="intent-link-cell-name"
                            >{{ intent.name }}{{ intent.intentID }}</span
                        >
                        <el-button
                            @click="gotoNewIntentPage(intent)"
                            type="text"
                            >{{$t('addIntent.toView')}}</el-button
                        >
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="closeSimilIntentDialogVisible"
                    size="small"
                    plain
                    class="cancel-btn"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="addIntentExiSimil"
                    size="small"
                    class="confirm-btn"
                    >{{$t('addIntent.continueCreate')}}</el-button
                >
            </div>
        </popup>
        <!-- 复制意图 -->
        <CopyIntent
            :visible.sync="copyIntentDialogVisible"
            @closeCopyIntent="closeCopyIntent"
        />
    </div>
</template>
<script>
import CopyIntent from "./../../../botIndex/component/CopyIntent.vue";
import popup from "./../../../../components/popupNew.vue";
export default {
    components: { CopyIntent, popup },
    props: ["activeBot", "apiKey", "defaultClassification"],
    data() {
        return {
            addsucCallback: null,
            // 新增意图
            addIntentDiaVisible: false,
            intentName: "",
            intentDescription:"",
            exampleQuestion: "",
            isShare: 0,
            isDefault: false,
            classification: "",
            classificationOption: [],
            intentClassKey: 1,
            superIntentList: [{ id: "", name: "" }],
            currentSuperIntentList: {
                corpusTemplate: { examples: [""], example: "" },
                name: "diyige",
            },
            selectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                    ],
                },
            },
            startSelectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false,
                        },
                    ],
                },
            },
            clearStatus: true,
            isIntentFocus: false,
            skillList: [],
            superIntentInputWords: [],
            similIntentDialogVisible: false,
            similIntentList: [],
            copyIntentDialogVisible: false,
            maxLengthLimit: 50,
        };
    },
    methods: {
        closeSimilIntentDialogVisible() {
            this.similIntentDialogVisible = false;
            this.addIntentDiaVisible = true;
        },
        gotoNewIntentPage(item) {
            let routeUrl = this.$router.resolve({
                path: "/main/intentQa",
                query: {
                    bid: this.$route.query.id,
                    bName: this.$route.query.name,
                    intentId: item.id,
                    intentName: item.name,
                    refApiKey: this.$router.currentRoute.query.refApiKey,
                    isDefault: item.isDefault,
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                    "/#" +
                    routeUrl.resolved.fullPath,
                "_blank"
            );
        },
        closeCopyIntent() {
            this.copyIntentDialogVisible = false;
        },
        // 点击从现有意图复制
        copyIntentDia() {
            // 获取共享意图
            this.FetchGet(this.requestUrl.bot.botSkillIntentTree, {
                isShare: 1,
            }).then((res) => {
                if (res.code === "0") {
                    let data = [];
                    res.data.forEach((item) => {
                        if (item.id != this.$route.query.id) {
                            data.push(item);
                        }
                    });
                    if (data.length === 0) {
                        this.$message.error("暂无共享意图");
                    } else {
                        this.addIntentDiaVisible = false;
                        this.$nextTick(() => {
                            this.copyIntentDialogVisible = true;
                        });
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        handleChange2(value) {
            this.getTypeListChildren(value[0]);
        },
        changeClassification(val){
            console.log(val);
        },
        getTypeListChildren(id) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: id,
            }).then((res) => {
                if (res.code === "0") {
                    this.classificationOption.forEach((item, index) => {
                        if (item.value === id) {
                            let test = res.data.map((res) => {
                                return { label: res.name, value: res.id };
                            });
                            item.children = JSON.parse(JSON.stringify(test));
                        }
                    });
                }
            });
        },
        // 获取技能list
        getSkillList() {
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: this.activeBot,
            }).then((res) => {
                if (res.code === "0") {
                    this.skillList = res.data;
                    let list = res.data.map((res) => {
                        return { label: res.name, value: res.id, children: [] };
                    });
                    this.classificationOption = JSON.parse(
                        JSON.stringify(list)
                    );
                    if (this.defaultClassification === "1_1") {
                        // console.log(this.classificationOption);
                        // this.classification = this.classificationOption[0]
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeEnable(datas) {
            if (datas === "open") {
                this.currentSuperIntentList.enable = false;
            } else if (datas === "close") {
                this.currentSuperIntentList.enable = true;
            }
            this.intentClassKey++;
            this.getSuperIntentList();
        },
        // 获取超级意图列表
        getSuperIntentList() {
            this.FetchGet(this.requestUrl.superIntent.getSuperIntentList).then(
                (res) => {
                    if (res.code === "0") {
                        this.superIntentList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }
            );
        },
        // 超级意图列表获取焦点后清空所选内容
        superIntentFocus() {
            this.selectData = this.startSelectData;
            this.getSuperIntentList();
        },
        selectVisibleChange(callback) {
            if (callback) {
                this.clearStatus = true;
                this.selectData = this.startSelectData;
            } else {
                this.clearStatus = false;
                this.selectTrigger();
            }
        },
        // 选中超级意图执行方法
        selectTrigger(id) {
            let that = this;
            this.clearStatus = false;
            for (var i = 0; i < that.superIntentList.length; i++) {
                if (that.superIntentList[i].name == that.selectData.name) {
                    that.selectData = that.superIntentList[i];
                }
            }
            if (this.currentSuperIntentList.enable) {
                this.$nextTick(() => {
                    let ele = document.querySelector(
                        "#superIntentConfig-intent-add .popup-container-new"
                    );
                    ele.scrollTop = ele.scrollTop + 100;
                });
            }
        },
        closeBotKeywordRecommend(item) {
            item.showBotKeywordRecommend = false;
            this.$forceUpdate();
        },
        //超级意图 关键词的近义词 , 单个添加
        addToInputSuper(datas, item, index) {
            item.value = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate();
        },

        // 超级意图 显隐推荐词下拉框
        changeInputSuper(datas, item, index) {
            this.isIntentFocus = true;
            this.selectData.templateConfig.pattern.forEach((v, i) => {
                v.showBotKeywordRecommend = false;
            });
            if (item.value !== "") {
                item.showBotKeywordRecommend = true;
            }
            this.$forceUpdate();
        },
        showBotKeywordRecommendFn() {
            this.$nextTick(() => {
                let ele = document.querySelector(
                    "#superIntentConfig-intent-add .popup-container-new"
                );
                ele.scrollTop = ele.scrollTop + 100;
            });
        },
        // 提交新建意图
        submitAddBot() {
            let intentName = this.intentName;
            let exampleQuestion = this.exampleQuestion;
            let isShare = this.isShare;
            let pattern = this.selectData.templateConfig.pattern;
            this.superIntentInputWords = [];
            // return
            if (this.currentSuperIntentList.enable == true) {
                for (var i = 0; i < pattern.length; i++) {
                    if (pattern[i].words.length != 0) {
                        let content = pattern[i].words[0].split(" ");

                        this.superIntentInputWords.push({
                            varName: pattern[i].varName,
                            type: pattern[i].type,
                            words: content,
                            isFillIn: pattern[i].isFillIn,
                        });
                    } else if (pattern[i].value) {
                        let value = pattern[i].value
                            .replace(/，/gi, ",")
                            .split(",");
                        this.superIntentInputWords.push({
                            varName: pattern[i].varName,
                            type: pattern[i].type,
                            words: value,
                            isFillIn: pattern[i].isFillIn,
                        });
                    } else if (this.selectData.id == "") {
                        this.$message.error("请选择超级意图类别！");
                        return;
                    } else {
                        this.$message.error(
                            "填空处不能为空，请填写内容后再保存！"
                        );
                        return;
                    }
                }
            }

            if (
                Boolean(intentName.length) &&
                Boolean(exampleQuestion && exampleQuestion.length)
            ) {
                if (this.classification == "") {
                    this.$message.error("所属知识库及分类不能为空！");
                } else {
                    // 提交意图信息
                this.addIntentLoading = true;
                this.FetchGet(this.requestUrl.intent.getSimilarIntent, {
                    name: intentName,
                    description:this.intentDescription,
                    exampleQuestion: exampleQuestion,
                    tid: this.classification,
                    apiKey: this.apiKey,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            if (res.data.length == 0) {
                                this.addIntentExiSimil();
                            } else {
                                this.similIntentList = res.data;
                                this.addIntentDiaVisible = false;
                                this.similIntentDialogVisible = true;
                            }
                            // this.activeClass = this.classification;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .finally(() => {
                        this.addIntentLoading = false;
                    });
                }
                
            } else {
                this.$message.error("意图名称与示例问法不能为空！");
            }
        },
        addIntentExiSimil() {
            let intentName = this.intentName;
            let exampleQuestion = this.exampleQuestion;
            let templateId = this.currentSuperIntentList.enable
                ? this.selectData.id
                : "";

            this.FetchPost(this.requestUrl.intent.addIntent, {
                name: intentName,
                description:this.intentDescription,
                exampleQuestion: exampleQuestion,
                isShare: this.isShare,
                tid: this.classification,
                apiKey: this.apiKey,
                templateId: templateId,
                config: JSON.stringify(this.superIntentInputWords),
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success("意图添加成功！");
                    this.addIntentDiaVisible = false;
                    this.similIntentDialogVisible = false;
                    this.intentName = "";
                    this.intentDescription = "";
                    this.exampleQuestion = "";
                    this.isShare = 1;
                    this.intentDes = "";
                    this.classification = "";
                    if (this.addsucCallback) {
                        this.addsucCallback();
                    }
                    this.$emit("uploadIntentList", res.data, 'empty');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 添加意图
        addIntent(addsucCallback) {
            if (addsucCallback) {
                this.addsucCallback = addsucCallback;
            }
            this.intentName = "";
            this.intentDescription = "";
            this.exampleQuestion = "";
            this.isDefault = false;
            this.handleTypeC = "add";
            this.currentSuperIntentList.enable = false;
            this.selectData.name = "";
            this.clearStatus = true;
            this.intentClassKey = 1;
            this.selectData.examples = [];
            let templateConfig = {
                pattern: [
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false,
                    },
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false,
                    },
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false,
                    },
                ],
                example: [
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false,
                    },
                ],
            };
            this.selectData.templateConfig = templateConfig;
            this.addIntentDiaVisible = true;
            let mainId = localStorage.getItem("_mainId");
            if (
                (mainId === "90df4764122240de939331d372546c28" ||
                    mainId === "e2f80aecaf7c11eabdcd506b4b2f3acc" ||
                    mainId === "1419b09cb2ce11ea8f0900163e0964d5") &&
                this.settingList.refApiKey
            ) {
                this.isShare = 1;
            } else {
                this.isShare = 0;
            }
            this.getSuperIntentList();
            
        },
    },
    mounted(){
        // 用户英文意图POC 白名单处理
        if (localStorage.getItem('_mainId') == '6fc7f7344ba046f68de662c021214eb3' || localStorage.getItem('_mainId') == 'ab0f90737c8b4f2d85ba2157e4473110') {
            this.maxLengthLimit = 200;
        }
        this.$nextTick(() => {
            this.getSkillList();
        })
    }
};
</script>
<style lang="less" scoped>
#superIntentConfig-intent-add {
    .guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe {
        color: #ff9555;
        font-size: 50px;
    }
    .tips-dialog-title {
        margin-top: 10px;
    }

    .open-super-intent .right {
        height: 40px;
        background: #fbfcfd;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 12px;

        .desc {
            color: #a9b3c6;
            margin-left: 12px;
            height: 40px;
            //line-height: 40px;
            font-size: 12px;
            display: flex;
            align-items: center;
            text-align: left;
        }
    }
    .evaluate-table-switch {
        display: flex;
        flex-direction: row;
        height: 25px;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        text-align: center;
        align-items: center;

        .el-switch {
            text-align: center;
        }

        .switch-open-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;

            i {
                color: white;
                font-size: 16px;
            }
        }

        .switch-close-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 21px;
            i {
                color: #6e6b7b;
                font-size: 14px;
                font-weight: 600;
            }
        }

        i {
            color: #366aff;
            font-size: 18px;
        }
    }

    .item-config {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 20px 0px;
        font-size: 14px;
        .title {
            color: #616161;
        }
        .guoran-tongyichicun-shili {
            color: #366aff;
        }

        .share-radio{
            /deep/.el-radio-group{
                text-align: left;
            }
        }
        .block-add-intent {
            width: 100%;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 10px;
                display: flex;
                align-items: centers;
            }
        }
        .blockdes {
            width: 100%;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 4px;
                width: auto;
                color: #616161;
                i {
                    margin-right: 5px;
                }
            }
            .des {
                color: #a9b3c6;
                text-align: left;
            }
            .arsenal_icon {
                color: #366aff;
            }
        }
        .config {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            background: #f6f8fd;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            position: relative;
            .pattern {
                text-align: left;
                font-size: 14px;
                margin-right: 0;
                padding: 3px 0;
                margin-left: 6px;
                /deep/.el-input {
                    flex: auto;
                    min-width: 120px;
                    max-width: 350px;
                }
                .el-input__inner {
                    // flex: auto;
                    // border-top: none;
                    // border-left: none;
                    // border-right: none;
                    // border-radius: 5px 5px 0 0;
                    // border-color: #366AFF;
                    border-bottom: solid 2px #366aff;
                }
                .word {
                    font-size: 12px;
                    flex: none;
                    text-align: left;
                    white-space: nowrap;
                    margin: 0 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .keyword-recommend {
                    position: relative;
                }
            }
        }
        .example {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            color: #aaaaaa;

            .pattern {
                width: auto;
                text-align: left;
                margin-right: 20px;
                .word {
                    text-align: left;
                    margin-top: 20px;
                }
                .input {
                    //text-decoration:underline;
                    font-size: 14px;
                    border-bottom: 1px solid #aaaaaa;
                }
            }
        }
        .classification {
            width: 100%;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 10px;
            }
        }

        .switch {
            text-align: left;
            flex: none;
            width: 150px;
        }
        .titleDes {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            em {
                text-align: right;
                margin-right: 45px;
                font-size: 12px;
            }
        }
        .config-example {
            margin-top: 30px;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            .title {
                height: 35px;
                border-bottom: 1px solid #e0e6f7;
                line-height: 35px;
                text-align: center;
                width: 100%;
            }
        }
        .bottom-example {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            .pattern {
                height: 33px;
                line-height: 33px;
                padding: 0 12px;
                margin: 0 3px;
                &.border {
                    background: #f6f8fd;
                    border: 1px solid #acc2ff;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #366aff;
                }
            }
        }
    }
    .line {
        margin: 30px 0;
        height: 2px;
        border-bottom: 1px dotted #e0e6f7;
    }

    .exmplebox {
        background: #ffffff;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 14px;
        .title {
            width: 100%;
            text-align: center;
            height: 35px;
            line-height: 35px;
            border-bottom: 1px solid #e0e6f7;
        }
        .exmple {
            text-align: left;
            flex: none;
            height: 120px;
            overflow-y: scroll;
            overflow-x: hidden;
            width: calc(100% - 20px);
            padding: 10px;
            .text {
                margin: 0px 0px 10px 0px;
                color: #a9b3c6;
                display: flex;
                height: 30px;
                align-items: center;
                > div {
                    width: 6px;
                    height: 6px;
                    background: #a9b3c6;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        }
    }
    .flexrow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .guoran-tongyichicun-fuzhi {
        font-size: 13px;
    }
    .red-text {
        color: red;
        font-size: 14px;
        margin-left: 3px;
    }
}
</style>