var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow-component-list"},[_c('div',{staticClass:"cl-tab"},_vm._l((_vm.componentsTypes),function(item,index){return _c('span',{key:index,class:[
                'cl-teb-cell',
                _vm.activeTab === index ? 'cl-teb-cell-active' : '',
                index == 1 ? 'font-size-26' : '',
            ],on:{"click":function($event){return _vm.switchComponentsTypes(index)}}},[_c('i',{class:item.icon})])}),0),_c('div',{staticClass:"cl-list"},[_c('div',{staticClass:"cl-list-name"},[_vm._v(" "+_vm._s(_vm.$t('flowComponent.' + _vm.componentsTypes[_vm.activeTab].name))+" ")]),_c('div',{staticClass:"cl-list-content"},_vm._l((_vm.componentsTypes[_vm.activeTab].components),function(cell,cellIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showComponent(cell.id, cell.disable, _vm.nodeId)),expression:"showComponent(cell.id, cell.disable, nodeId)"}],key:'com_' + cellIndex,staticClass:"clc-cell",style:({
                        opacity:
                            cell.id == 'action_webhook' && _vm.botVersion == 2
                                ? 0.5
                                : 1,
                    }),on:{"click":function($event){return _vm.addComponents(cell.id)}}},[_c('div',{staticClass:"clc-cell-box",style:(_vm.$i18n.locale === 'en' ? 'height:90px' : '')},[(cell.id == 'answer_rich_text' && _vm.nodeId !== 'start' &&  _vm.nodeId !== 'end')?_c('div',{staticClass:"gtp_title"},[_vm._v("GPT生成答案")]):_vm._e(),_c('span',{staticClass:"clc-cell-img"},[_c('img',{attrs:{"width":"32px","src":cell['icon-image'],"alt":"","srcset":""}})]),_c('span',{staticClass:"clc-cell-name"},[_vm._v(_vm._s(_vm.$t('flowComponent.' + cell.id)))])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }