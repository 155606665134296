<template>
    <!-- 内部节点 -->
    <div class="node-node-list">
        <div
            :class="[
                'node-node',
                childNodeItem.children.length == 1
                    ? 'node-node-border-l-empty'
                    : 'node-node-border-l',
            ]"
            v-for="(childNodeItem, childNodeIndex) in JSON.parse(list)"
            :key="childNodeIndex"
        >
            <div
                v-if="childNodeItem.children.length == 1"
                class="intent-node-node-pre-line"
            ></div>
            <div
                v-if="childNodeItem.isLastAndHasChi"
                class="intent-node-node-pre-line-2"
            ></div>
            <div class="node-node-inner-top">
                <div
                    :class="[
                        'node-pre-i',
                        childNodeIndex + 1 != JSON.parse(list).length
                            ? 'node-pre-i-border-left'
                            : 'node-pre-i-border-left-none',
                    ]"
                >
                    <div class="node-pre-i-inner"></div>
                </div>
                <div @click="
                        configAction(
                            intentId,
                            childNodeItem.id,
                            childNodeItem.name
                        )" class="node-node-name-inner">
                    <span class="node-name-c">{{$t('trainList.questionList.node')}}</span>
                    <div
                        class="name-text csp"
                    >
                        {{ childNodeItem.name }}
                    </div>
                </div>
                <div class="node-node-inner-handle">
                    <span
                        v-show="
                            !childNodeItem.isAddEditing &&
                            ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !isOtherSkill) ||
                                (isOtherSkill &&
                                    RP_Visible('OPEN_PROCESS_OTHER')))
                        "
                        class="first-node-handle-cell add-text"
                        @click="addNodeHandle(childNodeItem.id)"
                    >
                        <el-tooltip
                            :content="$t('flowComponent.addChildNode')"
                            effect="light"
                            placement="top"
                        >
                            <i class="el-icon-plus"></i> </el-tooltip
                    ></span>
                    <span
                        v-show="
                            !(
                                (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                    !isOtherSkill) ||
                                (isOtherSkill &&
                                    RP_Visible('OPEN_PROCESS_OTHER'))
                            )
                        "
                        class="first-node-handle-cell add-text-forb"
                    >
                        <i class="el-icon-plus"></i
                    ></span>
                    <div v-show="childNodeItem.isAddEditing" class="add-input-last">
                        <el-input
                            :ref="'add_input_' + childNodeItem.id"
                            v-model="nodeNameValue"
                            maxlength="20"
                            size="small"
                            placeholder="请输入子节点名称"
                            style="border:none"
                            @keyup.enter.native="confirmAdd(childNodeItem.id)"
                        ></el-input>
                        <span
                            class="el-icon-success add-icon"
                            @click="confirmAdd(childNodeItem.id)"
                        ></span>
                        <span
                            class="el-icon-error delete-icon"
                            @click="concelAddNodeHandle(childNodeItem.id)"
                        ></span>
                    </div>
                    <div
                        v-show="
                            ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !isOtherSkill) ||
                                (isOtherSkill &&
                                    RP_Visible('OPEN_PROCESS_OTHER'))) &&
                            isPart != 1
                        "
                        class="first-node-handle-cell first-node-handle-cell-h"
                        @click="
                            deleteNodeHandle(
                                childNodeItem.id,
                                childNodeItem.name
                            )
                        "
                    >
                        <el-tooltip
                            content="删除"
                            effect="light"
                            placement="top"
                        >
                            <i class="el-icon-delete delete-icon-node"></i>
                        </el-tooltip>
                    </div>

                    <span
                        class="
                            first-node-handle-cell-h first-node-handle-cell
                            add-text
                        "
                        @click="getNodeListByIntent(childNodeItem.id)"
                    >
                        <el-tooltip
                            content="变更节点关系"
                            effect="light"
                            placement="top"
                        >
                            <i class="el-icon-sort"></i> </el-tooltip
                    ></span>
                </div>
            </div>

            <node-item
                :list="JSON.stringify(childNodeItem.children)"
                :intentId="intentId"
                :skillId="skillId"
                :indexArr="indexArr"
                :nodeTree="nodeTree"
                :activeBot="activeBot"
                :isOtherSkill="isOtherSkill"
                v-if="hasChildren(childNodeItem.children)"
            ></node-item>
        </div>
        <popup v-if="changeNodeDialog" @closeEvent="changeNodeDialog = false">
            <div slot="popup-name" class="popup-name">变更节点关系</div>
            <!-- <div slot="popup-tip">您当前的版本无法使用该功能</div> -->
            <div slot="popup-con">
                <div class="sa-answer-radio-cell">
                    <div class="select-cell">
                        <span class="select-cell-title">请选择父节点</span>
                        <el-select
                            v-model="activeMoveToNode"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in moveToOptions"
                                :key="item.id"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" @click="changeNodeDialog = false" plain
                    >取 消</el-button
                >
                <el-button
                    size="small"
                    @click="confirmchangeNoderelation"
                    type="primary"
                    >保 存</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Bus from "./bus.js";
import Popup from "@/components/popup";
export default {
    name: "NodeItem",
    components: { Popup },
    props: {
        list: "",
        intentId: "",
        skillId: "",
        isOtherSkill: false,
        indexArr: {
            type: String,
            required: true,
        },
        isPart: false,
        activeBot: "",
        nodeTree: [],
    },
    data() {
        return {
            nodeNameValue: "",
            // 变更节点关系
            changeNodeDialog: false,
            activeMoveToNode: "",
            activeMoveNodeId: "",
            activeMoveNode: null,
            activeMoveNodeIndexArr: [0, 0, 0, 0],
            moveToOptions: [],
            activeInitNode: {},
        };
    },
    methods: {
        checkActionListChangedMoveNode(nodeId) {
            this.activeMoveNodeId = nodeId;
            let indexArr = this.indexArr;

            if (nodeId !== "start" && nodeId !== "end") {
                if (Array.isArray(indexArr)) {
                    this.activeMoveNodeIndexArr = indexArr;
                } else {
                    if (Array.isArray(JSON.parse(indexArr))) {
                        this.activeMoveNodeIndexArr = JSON.parse(indexArr);
                    }
                }
            }

            console.log(this.nodeTree);
            let activeFirstNode =
                    this.nodeTree[this.activeMoveNodeIndexArr[0]].children[
                        this.activeMoveNodeIndexArr[1]
                    ].children[this.activeMoveNodeIndexArr[2]].children[
                        this.activeMoveNodeIndexArr[3]
                    ],
                moveToOptions = [];

            let moToExtractNodeAsList = (target, node) => {
                if (
                    node.id !== this.activeMoveNodeId &&
                    node.childrenNode !== null &&
                    node.childrenNode.length > 0
                ) {
                    node.childrenNode.forEach((item, index) => {
                        if (item.id == this.activeMoveNodeId) {
                            this.activeMoveNode = item;
                            this.activeMoveToNode = node.id;
                        } else {
                            if (
                                item.childrenNode !== null &&
                                item.childrenNode.length > 0
                            ) {
                                moToExtractNodeAsList([], item);
                            }
                        }
                    });
                } else {
                    this.activeMoveNode = node;
                }
            };
            moToExtractNodeAsList([], activeFirstNode);

            let extractNodeAsList = (target, node, parentNum) => {
                if (
                    node.childrenNode !== null &&
                    node.childrenNode.length > 0
                ) {
                    parentNum++;
                    node.childrenNode.forEach((item, index) => {
                        if (
                            item.childrenNode !== null &&
                            item.childrenNode.length > 0
                        ) {
                            if (item.id !== this.activeMoveNode.id) {
                                target.push({
                                    name: item.name,
                                    id: item.id,
                                    parentNum: parentNum,
                                    classPop: "__",
                                });
                                extractNodeAsList(target, item, parentNum);
                            } else {
                                node.childrenNode.splice(index, 1);
                            }
                        } else {
                            if (item.id !== this.activeMoveNode.id) {
                                target.push({
                                    name: item.name,
                                    id: item.id,
                                    parentNum: parentNum,
                                    classPop: "oo",
                                });
                                extractNodeAsList(target, item, parentNum);
                            } else {
                                node.childrenNode.splice(index, 1);
                            }
                        }
                    });
                } else {
                    node.hasLeaf = false;
                }
                return target;
            };
            this.moveToOptions = extractNodeAsList([], this.motoInitNode, 1);

            setTimeout(() => {
                this.moveToOptions = extractNodeAsList(
                    [],
                    this.motoInitNode,
                    1
                );
                this.moveToOptions = [...this.moveToOptions];
                this.changeNodeDialog = true;
                this.moveToOptions.unshift(this.activeInitNode);
            }, 200);
        },
        cancelchangeNoderelation() {},
        // 变更父节点
        confirmchangeNoderelation() {
            this.FetchPost(
                this.requestUrl.chatFlowNode.moveNode,
                {
                    fromIntentId: this.intentId,
                    from: this.activeMoveNodeId,
                    to: this.activeMoveToNode,
                },
                { emulateJSON: true }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("节点移动成功！");
                    this.changeNodeDialog = false;
                    this.activeMoveToNode = "";
                    // this.getIntentListByBotId(this.activeBot, { type: "" });
                    Bus.$emit("confirmMoveNodeNew", this.indexArr);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        hasChildren(children) {
            for (var i in children) {
                if (children[i]) {
                    if (children[i].show != "undefined") {
                        return true;
                    } else {
                        return children[i].show;
                    }
                }
            }
            return false;
        },
        confirmAdd(id) {
            Bus.$emit(
                "confirmAddNodeHandle",
                this.intentId,
                id,
                JSON.parse(this.indexArr),
                this.nodeNameValue
            );
        },
        addNodeHandle(id) {
            this.nodeNameValue = "";
            
            Bus.$emit(
                "addNodeHandle",
                this.intentId,
                id,
                JSON.parse(this.indexArr)
            );

            this.$nextTick(() => {
                let tampStr = `add_input_${id}`;
                this.$refs[tampStr][0].focus();
            })
        },
        concelAddNodeHandle(id) {
            Bus.$emit(
                "concelAddNodeHandle",
                this.intentId,
                id,
                JSON.parse(this.indexArr)
            );
        },
        deleteNodeHandle(id, name) {
            Bus.$emit("deleteNodeHandle", this.intentId, id, name);
        },
        configAction(intentId, id, name) {
            let isInitNode = "1";
            console.log(
                this.intentId,
                id,
                this.skillId,
                name,
                this.indexArr,
                1
            );
            Bus.$emit(
                "configAction",
                this.intentId,
                id,
                this.skillId,
                name,
                this.indexArr,
                isInitNode
            );
        },
        // 获取意图节点
        getNodeListByIntent(nodeId) {
            this.FetchGet(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId: this.intentId,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.motoInitNode = res.data.initNode;

                        this.activeInitNode = {
                            name: res.data.initNode.name,
                            id: res.data.initNode.id,
                            parentNum: 1,
                            classPop: "__",
                        };
                        this.checkActionListChangedMoveNode(nodeId);
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    console.debug(err);
                    this.$message.error("获取意图节点失败！");
                });
        },
    },
    mounted() {},
};
</script>

<style lang="less">
.node-node-list {
    margin-left: 100px;
    .sa-answer-radio-cell {
        width: 100%;
        height: 100px;
        .select-cell {
            padding: 6px 8px;
            display: flex;
            flex-direction: column;
            .select-cell-title {
                text-align: left;
                line-height: 26px;
            }
            .silent-content-time {
                display: flex;
                .el-input {
                    flex: auto;
                }
                .el-select {
                    flex: none;
                    width: 80px;
                    margin-left: 8px;
                }
            }
        }
    }
    .node-node-border-l {
        border-left: solid 2px #749bff;
        position: relative;
    }
    .node-node-border-l-empty {
        border-left: solid 2px rgba(0, 0, 0, 0);
        position: relative;
    }
    .node-node {
        margin: 14px 150px 20px 0;
        border-radius: 0 0 0 0;
        .intent-node-node-pre-line-2 {
            height: 100%;
            position: absolute;
            left: -84px;
            top: 20px;
            width: 2px;
            background-color: #f5f7fb;
        }
        .intent-node-node-pre-line {
            width: 2px;
            background-color: #749bff;
            height: 70px;
            position: absolute;
            margin-left: -2px;
            margin-top: 18px;
        }
        .node-node-inner-top:hover {
            .node-node-inner-handle {
                .first-node-handle-cell-h {
                    display: inline-block;
                }
            }
        }
        .node-node-inner-top {
            display: flex;
            justify-content: flex-start;
            position: relative;
            .node-pre-i-border-left {
                border-left: solid 2px #749bff;
            }
            .node-pre-i-border-left-none {
                border-left: solid 2px #f5f7fb;
            }
            .node-pre-i {
                // background-color: white;
                height: 40px;
                width: 80px;
                margin-top: 3px;
                margin-left: -84px;

                text-align: center;
                color: #aaa;
                font-size: 12px;

                .node-pre-i-inner {
                    margin-left: -2px;
                    height: 20px;
                    border-radius: 0px 0 0 30px;
                    border-left: solid 2px #749bff;
                    border-bottom: solid 2px #749bff;
                }
            }
            .node-node-name-inner {
                cursor: pointer;
                padding-right: 16px;
                margin-top: 0px;
                margin-left: -20px;
                height: 41px;
                line-height: 41px;
                border: solid 1px rgba(116, 155, 255, 0.4);
                background-color: white;
                border-radius: 23px;
                max-width: 364px;
                display: flex;
                justify-content: flex-start;
                .node-name-c {
                    font-size: 12px;
                    font-weight: 500;
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    width: 30px;
                    margin-left: 4px;
                    margin-top: 3px;
                    text-align: center;
                    border: solid 2px #749bff;
                    border-radius: 21px;
                    background-color: #749bff;
                    color: #fff;
                }
                .name-text {
                    flex: auto;
                    padding-left: 8px;
                }
            }
            .node-node-inner-handle {
                margin-left: 6px;
                display: flex;
                margin-top: 6px;
                margin-bottom: -20px;
                // display: none;
                justify-content: flex-start;
                cursor: pointer;
                .first-node-handle-cell-h {
                    display: none;
                }
                .first-node-handle-cell {
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    color: #749bff;
                    text-align: center;
                    background: #ffffff;
                    border: 1px solid rgba(116, 155, 255, 0.4);
                    border-radius: 50%;
                    margin-right: 4px;
                    i {
                        font-weight: 700;
                    }
                }
                .add-input-last {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: -1px;
                    margin-right: 6px;
                    height: 34px;
                    border: solid 1px rgba(116, 155, 255, 0.4);
                    border-radius: 5px;
                    padding-right: 6px;
                    background-color: white;
                    // margin-top: -46px;
                    .el-input {
                        margin-right: 6px;
                        .el-input__inner {
                            border: none !important;
                        }
                    }
                    .delete-icon {
                        color: #fe5965;
                        font-size: 20px;
                        cursor: pointer;
                    }
                    .add-icon {
                        font-size: 20px;
                        color: #749bff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>