<template>
    <div class="select-addnode">
        <div :class="!showBorder?'select-cell-inner':'select-cell-border'">
            <el-cascader :placeholder="$t('flowComponent.conditionConfigPopup.selectAddEntity')" :popper-append-to-body="false" size="small"  :show-all-levels="false" v-model="entityValue"
                :options="entityOptions.filter(item => {
                    if (item.children.length > 0) {
                        return item;
                    }
                })" @change="handleChangeEntity" @visible-change="
                (v) => visibleChange(v, entitySelectARef, cascaderClick)" @focus="nodeSelectFocus" @blur="nodeSelectBlur"
                :ref="entitySelectARef" :props="{}" :key="entitySelectARef" filterable>
            </el-cascader>
        </div>

        <add-entity ref="add_entity_ponennt" v-if="showAddEntity" @afterSave="afterSave" :entity="currentEntity" :isAdd="true" :isEdit="false" :isBotLink="isBotLink" :isStartOrEnd="isStartOrEnd"></add-entity>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import AddEntity from "../other/AddEntity.vue";

export default {
    // entityValue 选择实体Id
    // entitySelectARef select ref 由于使用于下拉选输入回显dom选择，需保证页面中存在唯一性
    // entityOptions 实体树 JSON
    props: [
        "entityValue",
        "entitySelectARef",
        "entityOptions",
        "showBorder",
        "skillId",
        "isBotLink",
        "isStartOrEnd"
    ],
    components: { popup, AddEntity },
    data() {
        return {
            currentEntity: {
                id: null,
                skillId: "",
                type: "DICTIONARY",
                isDefault: false,
                content: [{ standardWord: '', synonyms: '', showBotKeywordRecommend: false }]
            },
            showAddEntity: false,
            nodePreName: "",
            nodeParentId: "",
        };
    },
    mounted() { },
    methods: {
        afterSave(entity){
            let entityId = entity.id;
            this.$emit("getAllEntityBySkill", entityId);
        },
        handleChangeEntity(e){
            console.log(e);
            this.$emit("handleChangeEntity", e);
        },
        /**
         * 为element-ui的Select和Cascader添加弹层底部操作按钮
         * @param visible
         * @param refName  设定的ref名称
         * @param onClick  底部操作按钮点击监听
         */
        visibleChange(visible, refName, onClick) {
            if (visible) {
                const ref = this.$refs[refName];
                let popper = ref.$refs.popper;
                if (popper.$el) popper = popper.$el;
                if (
                    !Array.from(popper.children).some(
                        (v) => v.className === "el-cascader-menu__list"
                    )
                ) {
                    const el = document.createElement("ul");
                    el.className = "el-cascader-menu__list";
                    el.style =
                        "border-top: solid 1px #E4E7ED; padding:0; color: #606266;";
                    el.innerHTML = `<li class="el-cascader-node el-cascader-node-createnode" style="height:38px;line-height: 38px; padding: 0 16px;color: #366aff"><span class="el-cascader-node__label">创建实体</span> <span ref="ecn_prename" class="el-cascader-node__prename ${this.entitySelectARef}">${this.nodePreName}</span></li>`;
                    popper.appendChild(el);
                    el.onclick = () => {
                        // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
                        onClick && onClick();

                        // 以下代码实现点击后弹层隐藏 不需要可以删掉
                        if (ref.toggleDropDownVisible) {
                            ref.toggleDropDownVisible(false);
                        } else {
                            ref.visible = false;
                        }
                    };
                }
            }
        },
        cascaderClick() {
            this.showAddEntity = true;
            this.$nextTick(() => {
                this.$refs.add_entity_ponennt.addEntityDiaVisible = true;
                this.$refs.add_entity_ponennt.entityObject.name = this.nodePreName;
                this.$refs.add_entity_ponennt.entityObject.skillId = Number(this.skillId);
            })
        },
        nodeSelectFocus(e) {
            setTimeout(() => {
                this.addNodeTimer = setInterval(() => {
                    let el = document.getElementsByClassName(
                        this.entitySelectARef
                    )[0];
                    this.nodePreName =
                        this.$refs[
                            this.entitySelectARef
                        ].$children[0].$refs.input.value;
                    el.innerHTML = `<span>${this.nodePreName}</span>`;
                }, 500);
            }, 1);
        },
        nodeSelectBlur() {
            clearTimeout(this.addNodeTimer);
        },
    },
};
</script>
<style lang="less" scoped>
.select-addnode {
    .select-cell-border {
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }

        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
    }
    .select-cell-inner {
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }

        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }

        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }

        /deep/.el-input__suffix {
            display: none;
        }
    }

    .el-select {
        width: 100%;
    }

    .add-node-cell {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        /deep/.el-input__inner {
            border: 1px solid #dcdfe6 !important;
            padding-right: 0;
        }

        /deep/.el-input__inner:focus {
            border: 1px solid #366aff !important;
            padding-right: 0;
        }

        /deep/.el-input__suffix {
            display: block !important;
        }

        .add-node-cell-title {
            margin-bottom: 6px;
            text-align: left;

            .add-node-cell-title-pre {
                color: red;
            }
        }

        .add-node-cell-content {
            /deep/.el-select {
                width: 100%;
            }
        }
    }

    /deep/.el-cascader-node-createnode {
        display: flex !important;
        justify-content: flex-start !important;

        .el-cascader-node__label {
            flex: none;
            width: 60px;
            color: #366aff;
            padding: 0;
        }

        .el-cascader-node__prename {
            height: 38px;
            flex: auto;
            text-align: left;
            padding-left: 16px;
        }
    }
}
</style>