<template>
    <div class="condition-config">
        <div class="cc-cell">
            <span class="cc-cell-title">{{$t('flowComponent.conditionConfigPopup.conditionType')}}</span>
            <div class="cc-cell-content">
                <el-radio-group @change="conditionTypeChange" v-model="conditionType">
                    <el-radio
                        v-for="item in conditionTypesOptions"
                        :key="item.value"
                        :label="item.value"
                        >{{ $t('flowComponent.webhook.' + item.value) }}</el-radio
                    >
                </el-radio-group>
            </div>
        </div>
        <div class="entity-content" v-if="conditionType === 'ENTITY'">
            <div class="select-cell-et">
                <!-- <el-cascader
                    placeholder="请搜索选择实体"
                    size="small"
                    key="CCscee"
                    v-model="entityValue"
                    :options="entityOptions"
                    @change="handleChangeEntity"
                    ref="cascader"
                    filterable
                >
                </el-cascader> -->
                <select-addentity
                    :isBotLink="isBotLink"
                    :showBorder="false"
                    :entityOptions="entityOptions"
                    :entityValue="entityValue"
                    :skillId="skillId"
                    :isStartOrEnd="isStartOrEnd"
                    entitySelectARef="conditionconfig_selectaddentity"
                    @handleChangeEntity="handleChangeEntity"
                    @getAllEntityBySkill="getAllEntityBySkill"
                ></select-addentity>
            </div>
            <div class="select-cell-eteq select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionValue"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectType')"
                >
                    <el-option
                        v-for="item in conditionTypes[0].relations"
                        :key="item.value"
                        :label="$t('common.' + item.value)"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div class="select-cell-etv select-cell-inner">
                <el-autocomplete
                    size="small"
                    class="inline-input"
                    key="CCsceeA"
                    v-model="activeEntityValue"
                    :fetch-suggestions="querySearchEntityValue"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectAddEntity')"
                ></el-autocomplete>
            </div>
        </div>
        <div
            class="intent-content"
            v-if="conditionType === 'WEB_HOOK_RESPONSE'"
        >
            <div class="select-cell-rp select-cell-inner">
                <el-cascader
                    :key="'__' + new Date()"
                    size="small"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectValue')"
                    v-model="conditionResponseValue"
                    :options="expBaseCascList"
                    :props="{ label: 'name' }"
                ></el-cascader>
            </div>
            <div class="select-cell-rpt select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionResponseType"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectType')"
                >
                    <el-option
                        v-for="item in [
                            { name: '个数', value: 'LENGTH' },
                            { name: '值', value: 'VALUE' },
                        ]"
                        :key="item.value"
                        :label="$t('common.' + item.value)"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div class="select-cell-rpeq select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionResponseCondit"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectCondition')"
                >
                    <el-option
                        v-for="item in webhookConditionTypes"
                        :key="item.value"
                        :label="$t('common.' + item.value)"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div
                v-if="conditionResponseType == 'VALUE'"
                class="select-cell-rpv select-cell-inner"
            >
                <el-input
                    size="small"
                    v-model="conditionResponseNum"
                    :placeholder="$t('flowComponent.conditionConfigPopup.inputValue')"
                ></el-input>
            </div>
            <div
                v-if="conditionResponseType == 'LENGTH'"
                class="select-cell-rpv select-cell-inner"
            >
                <el-input
                    size="small"
                    v-model="conditionResponseNum"
                    type="number"
                    :placeholder="$t('flowComponent.conditionConfigPopup.inputNumValue')"
                ></el-input>
            </div>
        </div>
        <div class="entity-content" v-if="conditionType === 'USER_ATTRIBUTE'">
            <div class="select-cell-attr select-cell-inner">
                <!-- <el-cascader
                    placeholder="请选择属性"
                    size="small"
                    key="CCscUa"
                    v-model="userAttributeType"
                    :options="userAttributeOptions"
                    @change="handleChangeUserAttributeType"
                >
                </el-cascader> -->
                <el-popover
                    ref="searchIntentPoprver"
                    placement="bottom-start"
                    width="300"
                    v-model="visible"
                    popper-class="choose-condition-config-poprver"
                    :visible-arrow="false">
                    <div :class="['choose-user-attrible one-column-ellipsis',userAttributeTypeNameText.length === 0 ? 'empty-user-attrible' : '']" slot="reference">
                        <span v-if="userAttributeTypeNameText.length === 0">请选择属性</span>
                        <span v-else>
                            <span v-for="(_items,_index) in userAttributeTypeNameText" :key="_index">
                                {{_items}} {{_index + 1 < userAttributeTypeNameText.length ? '/' : ''}}
                            </span>
                        </span>
                    </div>
                    <el-tree
                        style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                        :data="userAttributeOptions"
                        default-expand-all
                        node-key="id"
                        @node-click="onNodeClickAttr"
                        ref="userAttributeTree">
                        <span :class="['custom-tree-node',  data.value === 'staff'  ? 'disabled' : '']" slot-scope="{node,data}">
                            <span class="custom-tree-node-left">
                                <span class="data-label">{{ data.label }}</span>
                            </span>
                        </span>
                    </el-tree>
                </el-popover>
            </div>
            <div class="select-cell-attreq select-cell-inner">
                <el-select
                    size="small"
                    v-if="userAttributeCustomizeField.type != 'DATE'"
                    v-model="conditionValue"
                    @change="changeConditionValue"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectType')"
                >
                    <el-option
                        v-for="item in attrRelations"
                        :key="item.value"
                        :label="$t('condition_common.' + item.value)"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-select
                    size="small"
                    v-else
                    v-model="conditionValue"
                    @change="changeConditionValue"
                    :placeholder="$t('flowComponent.conditionConfigPopup.selectType')"
                >
                    <el-option
                        v-for="item in dateTimeAttrRelations"
                        :key="item.value"
                        :label="$t('common.' + item.value)"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div
                @click="selectStaffCustomer"
                :class="['select-cell-attrv select-cell-inner',(userAttributeType.indexOf('tags') !== -1 || userAttributeType.indexOf('personalJobLevel') !== -1 || userAttributeCustomizeField.source == 'tongdun') ? 'no-need-pad' : '']"
                v-if="conditionValue !== 'EQUAL' && conditionValue !== 'NOT_EQUAL'"
            >
                <!-- <el-select 
                    style="width:100%;"
                    v-if="userAttributeType.indexOf('tags') !== -1"
                    v-model="tagsValue" 
                    multiple
                    collapse-tags
                    size="small"
                    filterable 
                    :placeholder="$t('common.selectPlaceholder')" >
                    <el-option
                        v-for="item in tagsOptions"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select> -->
                <el-select
                    style="width:100%;"
                    multiple
                    collapse-tags
                    size="small"
                    filterable 
                    v-if="userAttributeType.indexOf('tags') !== -1"
                    v-model="tagsValue"
                    @change="selectScopeChange"
                    placeholder="请选择">
                  <el-option-group
                    v-for="group in tampTagsOptions"
                    :key="group.value"
                    :label="group.label">
                    <el-option
                      v-for="item in group.children"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-select 
                    style="width:100%;"
                    v-else-if="userAttributeType.indexOf('personalJobLevel') !== -1"
                    v-model="personalJobLevelValue" 
                    multiple
                    collapse-tags
                    size="small"
                    filterable 
                    :placeholder="$t('common.selectPlaceholder')" >
                    <el-option
                        v-for="item in personalJobLevelOptions"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
                <div v-else-if="userAttributeCustomizeField.source == 'tongdun'" class="">
                    <el-input
                        v-show="userAttributeCustomizeField.type == 'SELECT'"
                        size="small"
                        style="width:100%;"
                        v-model="userAttributeCustomizeFieldValue"
                        :placeholder="$t('common.inputPlaceholder')"
                    ></el-input>
                    <el-date-picker
                        v-show="userAttributeCustomizeField.type == 'DATE' && (conditionValue == 'BEFORE' || conditionValue == 'AFTER')"
                        v-model="userAttributeCustomizeFieldValue"
                        type="date"
                        class="choose-tag-select"
                        size="small"
                        :placeholder="$t('flowComponent.conditionConfigPopup.selectDate')">
                    </el-date-picker>
                    <el-date-picker
                        v-show="userAttributeCustomizeField.type == 'DATE' && (conditionValue == 'BETWEEN' || conditionValue == 'NOT_BETWEEN')"
                        v-model="userAttributeCustomizeFieldValue"
                        size="small"
                        type="daterange"
                        class="choose-tag-select"
                        range-separator="至"
                        :start-placeholder="$t('common.startDate')"
                        :end-placeholder="$t('common.endDate')">
                    </el-date-picker>
                </div>
                
                <template v-else>
                    <span
                        v-if="userAttributeType.length != 0 && userAttributeNameValue.length == 0"
                        class="select-cell-attrv-placeholder">
                        {{$t('common.selectPlaceholder')}}
                    </span>
                    <div v-else class="attr-value">
                        <span
                            v-for="(uavCell, uavCellIndex) in userAttributeObj"
                            :key="uavCellIndex"
                            class="attr-value-cell">
                            <span v-if="!uavCell.isThirdData">{{ uavCell.name }}</span> 
                            <open-data v-else :type="userAttributeType.indexOf('name') === -1 ? 'departmentName' : 'userName'" :openid="uavCell.name"></open-data>
                            <em v-show="userAttributeObj.length !=  uavCellIndex + 1">,</em>
                        </span>
                    </div>
                </template>
            </div>
        </div>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
            <div slot="popup-name">{{$t('flowComponent.conditionConfigPopup.chooseMember')}}</div>
            <div slot="popup-con" class="member-contanier">
                <staff-customer
                    :memberInfo4Client="memberInfo4Client"
                    :memberList="memberList"
                    :clientType="clientType"
                    :selectClient="customerList"
                    :clientValue="checkClientId"
                    @onNodeClick="onNodeClick"
                    :isMultiple="isMultiple"
                    :hasClientNext="hasClientNext"
                    :companyConfig="false"
                    @loadMore="lookMore"
                    ref="staffCustomer"
                    :isShowUpDown="isShowUpDown"
                    :isShowStaff="isShowStaff"
                ></staff-customer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogMemberTree = false" plain size="small"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" @click="preserStaff" size="small"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
        <popup v-if="selectDepartment" @closeEvent="selectDepartment = false">
            <div slot="popup-name">{{$t('flowComponent.conditionConfigPopup.chooseDep')}}</div>
            <div slot="popup-con">
                <department-tree
                    :isMultiple="isMultipleDepartment"
                    :selectDepartment="selectDepartment"
                    :updownCheckedList="updownCheckedList"
                    :staffCheckedList="staffCheckedList"
                    ref="departmentTree"
                    :isShowUpDown="isShowUpDown"
                    :isShowStaff="isShowStaff"
                    :activeName="activeDepName"
                    @selectDepartmentNode="selectDepartmentNode"
                ></department-tree>
            </div>
            <div slot="dialog-footer">
                <el-button @click="selectDepartment = false" plain size="small"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" @click="saveDepartment" size="small"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import StaffCustomer from "./staffCustomer";
import OpenData from "@/components/openData";
import DepartmentTree from "./departmentTree";
import SelectAddentity from "./SelectAddentity.vue";
export default {
    props: ["skillId", "expBaseCascList", "isStartOrEnd","isBotLink"],
    components: { StaffCustomer, OpenData, popup, DepartmentTree, SelectAddentity },
    data() {
        return {
            dialogMemberTree: false,
            memberInfo4Client: {
                type: "",
                memberId: "",
                name: "",
                clientInfo: [],
            },
            memberList: [],
            checkMemberList: [],
            clientType: "",
            customerList: [],
            checkClientId: "",
            isMultiple: true,
            hasClientNext: false,
            isShowUpDown: true,
            isShowStaff: true,
            staffCustomer: {
                clientInfo: [],
                type: "",
            },
            // 咨询者属性
            userAttributeType: "",
            userAttributeValue: [],

            userAttributeCustomizeField: '', // 区分咨询者自定义属性公司 由于测试环境数量限制 目前为名称 后续可扩展为mainid
            userAttributeCustomizeFieldValue: "", // 自定义字段值

            userAttributeObj: [],
            userAttributeNameValue: [],
            userAttributeOptions: [
                {
                    value: "staff",
                    label: "员工",
                    children: [
                        {
                            value: "name",
                            label: "姓名",
                        },
                        {
                            value: "department",
                            label: "部门",
                        },
                        {
                            value: "tags",
                            label: "标签",
                            type: "SELECT",
                        }
                        // {
                        //     value: "departments",
                        //     label: "部门(包含子部门)",
                        // },
                       
                    ],
                },
                // {
                //     value: "updown",
                //     label: "上下游",
                //     children: [
                //         {
                //             value: "name",
                //             label: "姓名",
                //         },
                //         {
                //             value: "department",
                //             label: "部门",
                //         },
                //     ],
                // },
            ],
            userAttributeTypeNameObj: {
                staff: "员工",
                updown: "上下游",
                name: "姓名",
                department: "部门",
                tags:'标签'
                // departments: "部门(包含子部门)",
            },
            attrRelations: [
                { name: "为空", value: "EQUAL" },
                { name: "不为空", value: "NOT_EQUAL" },
                { name: "包含任意", value: "CONTAINS_ANY" },
                { name: "不包含任意", value: "NOT_CONTAINS_ANY" },
            ],
            dateTimeAttrRelations: [
                { name: "早于", value: "BEFORE" },
                { name: "晚于", value: "AFTER" },
                { name: "介于", value: "BETWEEN" },
                { name: "不介于", value: "NOT_BETWEEN" },
            ],
            userAttributeTypeValue: [],

            selectDepartment: false,
            isMultipleDepartment: true,
            updownCheckedList: [],
            staffCheckedList: [],

            conditionType: "ENTITY", // 条件配置类型
            conditionTypesOptions: [
                {
                    // 条件配置类型option
                    value: "ENTITY",
                    label: "实体",
                },
                {
                    value: "WEB_HOOK_RESPONSE",
                    label: "返回值",
                },
                {
                    value: "USER_ATTRIBUTE",
                    label: "咨询者属性",
                },
            ],
            entityValue: [],
            entityOptions: [],
            entityValueVal: "",
            conditionValue: "EQUAL",

            // 条件列表
            conditionTypes: [
                { relations: [] },
                { relations: [] },
                { relations: [] },
            ],

            // 实体
            allEntityValue: [],
            activeEntityValue: "",

            // 返回值配置
            webhookConditionTypes: [],
            conditionResponseValue: "",
            conditionResponseType: "VALUE",
            conditionResponseCondit: "",
            conditionResponseScene: "",
            conditionResponseNum: "",

            conditionEditing: {}, // 当前编辑条件obj
            userAttributeTypeNameText:"",
            visible:false,
            tagsOptions:[], //标签选项
            tagsValue:[],

            tampTagsOptions: [],

            personalJobLevelOptions: [], //  个人职级选项
            personalJobLevelValue: [], // 个人职级选值
        };
    },
    mounted() {
        this.getTagsOptions();
        if (this.isStartOrEnd) {
            this.getAllEntityByBot();
        } else {
            this.getAllEntityBySkill();
        }

        // 条件列表
        // 条件列表
        this.FetchGet(this.requestUrl.chatFlowComponent.getConditionList, {})
            .then((res) => {
                if (res.code === "0") {
                    this.conditionTypes = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
        this.FetchGet(this.requestUrl.chatFlowComponent.getConditionList, {
            scope: "WEB_HOOK_RESPONSE",
            type: "LENGTH",
        })
            .then((res) => {
                if (res.code === "0") {
                    this.webhookConditionTypes = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
    },
    methods: {
        selectScopeChange(value){
            console.log(value);
        },
        // 获取自定义标签数据
        getTagsOptions(){
            this.FetchGet(this.requestUrl.bindV2.getCustomizeFields).then((res) => {
                if (res.code == 0) {
                    console.log(res,'res');

                    //  是否展示标签 是否展示 个人职级  用于过滤是否需要请求相关的option接口，减少请求次数
                    // let isTaoboLabel = false;
                    let isMengniuPersonalJobLevel = false;
                    let isLibang = false;

                    if(res.data && res.data.length > 0){
                        res.data.forEach(dataItem => {
                            // 滔搏-标签
                            // if(dataItem.enName === 'label'){
                            //     isTaoboLabel = true;
                            //     this.userAttributeOptions.forEach(item => {
                            //         if(item.value === 'staff'){
                            //             item.children.push({
                            //                 value:"tags",
                            //                 label:dataItem.name,
                            //                 type: dataItem.type,
                            //                 source: 'taobo'
                            //             })
                            //         }
                            //     })
                            // } else
                             if(dataItem.enName === 'personalJobLevel'){  // 蒙牛-personalJobLevel_个人职级
                                isMengniuPersonalJobLevel = true;
                                this.userAttributeOptions.forEach(item => {
                                    if(item.value === 'staff'){
                                        item.children.push({
                                            value: "personalJobLevel",
                                            label: dataItem.name,
                                            type: dataItem.type,
                                            source: 'mengniu'
                                        })
                                    }
                                })
                            } else { // 同盾自定义字段
                                isLibang = true;
                                this.userAttributeOptions.forEach(item => {
                                    if(item.value === 'staff'){
                                        item.children.push({
                                            value: dataItem.enName,
                                            label: dataItem.name,
                                            type: dataItem.type,
                                            source: 'tongdun'
                                        })
                                        this.userAttributeTypeNameObj[dataItem.enName] = dataItem.name;
                                    }
                                })
                            }
                        })

                        this.userAttributeOptions[0].children = [...this.userAttributeOptions[0].children];
                        console.log(this.userAttributeOptions[0].children);
                        this.userAttributeTypeNameObj['tags'] = '标签';
                        this.userAttributeTypeNameObj['personalJobLevel'] = '个人职级';

                        console.log(this.userAttributeTypeNameObj);
                        // 滔搏-标签
                        // isTaoboLabel && this.FetchGet(this.requestUrl.bindV2.getTagsOptions).then((res) => {
                        //     if (res.code == 0) {
                        //         this.tagsOptions = res.data;
                        //     }
                        // })

                        // 蒙牛-personalJobLevel_个人职级
                        isMengniuPersonalJobLevel && this.FetchGet(this.requestUrl.bindV2.getPersonalJobLevelOptions + '?fieldName=personalJobLevel').then((res) => {
                            if (res.code == 0) {
                                let arr = [];
                                res.data.forEach(item => {
                                    arr.push({
                                        id: item,
                                        label: item
                                    })
                                })
                                this.personalJobLevelOptions = arr;
                            }
                        })
                        // 预留立邦自定义字段下拉选请求
                        // isLibang && 
                    }
                }
            })

            // 韬博定制功能转通用
            this.FetchGet(this.requestUrl.bindV2.getTagsOptions).then((res) => {
                if (res.code == 0) {
                    this.tagsOptions = res.data;
                    // 创建一个对象来存储每个corpId对应的子元素数组
                    const corpIdMap = {};

                    this.tagsOptions.forEach(item => {
                        const { corpId, id, corpName } = item;

                        // 如果corpId不存在于corpIdMap中，则创建一个新的对象
                        if (!corpIdMap[corpId]) {
                            corpIdMap[corpId] = {
                                corpId,
                                children: [],
                                label: corpName,
                                value: corpId
                            };
                        }

                        // 将当前元素添加到对应corpId的子元素数组中
                        corpIdMap[corpId].children.push(item);
                    });

                    // 将corpIdMap中的值转换为数组形式，得到优化后的tampTagsOptions数组
                    const tampTagsOptions = Object.values(corpIdMap);
                    this.tampTagsOptions = tampTagsOptions;
                }
            })
        },
        changeConditionValue(val){
            this.userAttributeValue = [];
            this.userAttributeObj = [];
            this.userAttributeNameValue = [];
        },
        saveDepartment() {
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
            if (this.userAttributeType[0] == "staff") {
                this.staffCheckedList.forEach((item) => {
                    this.userAttributeValue.push(
                        item.id ? item.id : item.memberId
                    );
                    this.userAttributeNameValue.push(item.label);
                    item.name = item.label;
                    item.isThirdData = (item.isWeWorkThirdData || (item.originData && item.originData.wechatBotDTO && item.originData.wechatBotDTO.bindType===0&&item.originData.wechatBotDTO.wechatType===0))
                    this.userAttributeObj.push(item);
                });
            } else {
                this.updownCheckedList.forEach((item) => {
                    this.userAttributeValue.push(
                        item.id ? item.id : item.memberId
                    );
                    this.userAttributeNameValue.push(item.name);
                    this.userAttributeObj.push(item);
                });
            }
            this.selectDepartment = false;
        },
        selectDepartmentNode(list) {
            if (this.userAttributeType[0] == "staff") {
                this.staffCheckedList = [...list];
            } else {
                this.updownCheckedList = [...list];
            }
        },
        selectStaffCustomer() {
            if (this.userAttributeType.length == 0) {
                this.$message.error("请先选择属性");
            } else {
                if (
                    this.userAttributeType[0] == "staff" &&
                    this.userAttributeType[1] == "name"
                ) {
                    this.clientType = "";
                    this.isShowUpDown = false;
                    this.isShowStaff = true;
                    this.memberInfo4Client.clientInfo = this.userAttributeObj;
                    this.memberInfo4Client.memberId = this.userAttributeValue;
                    this.dialogMemberTree = true;
                }
                if (this.userAttributeType[0] == "staff" && (this.userAttributeType[1] == "department" || this.userAttributeType[1] == "departments") ) {
                    this.clientType = "";
                    this.isShowUpDown = false;
                    this.isShowStaff = true;
                    this.activeDepName = "staffDept";
                    this.staffCheckedList = this.userAttributeObj;
                    this.selectDepartment = true;
                    this.$nextTick(() => {
                        this.$refs.departmentTree.checkDepartmentList =
                            this.staffCheckedList;
                    });
                }
                if (this.userAttributeType[0] == "updown" && this.userAttributeType[1] == "name") {
                    this.clientType = "";
                    this.isShowUpDown = true;
                    this.isShowStaff = false;
                    this.memberInfo4Client.clientInfo = this.userAttributeObj;
                    this.memberInfo4Client.memberId = this.userAttributeValue;
                    this.dialogMemberTree = true;
                    // this.$nextTick(() => {
                    //     this.$refs.staffCustomer.checkMemberList = this.userAttributeObj;
                    // })
                }
                if (
                    this.userAttributeType[0] == "updown" &&
                    this.userAttributeType[1] == "department"
                ) {
                    this.clientType = "";
                    this.isShowUpDown = true;
                    this.isShowStaff = false;
                    this.activeDepName = "upDown";
                    this.updownCheckedList = this.userAttributeObj;
                    this.selectDepartment = true;
                    this.$nextTick(() => {
                        this.$refs.departmentTree.checkDepartmentList =
                            this.updownCheckedList;
                    });
                }
            }
        },
        handleChangeUserAttributeType() {
            this.memberInfo4Client.clientInfo = [];
            this.memberInfo4Client.memberId = [];
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
        },
        onNodeClickAttr(data,node){
            console.log(data, node);
            if(data.value === "staff") return false;
            if(node.parent){
                this.userAttributeCustomizeField = data;
                this.userAttributeType = [node.parent.data.value,data.value];
                this.userAttributeTypeNameText = [node.parent.data.label,data.label];
            }
            if (data.type == "DATE") {
                this.conditionValue = "BEFORE";
                this.userAttributeCustomizeFieldValue = "";
            } else {
                this.conditionValue = "EQUAL";
            }
            //  this.userAttributeTypeName = data.label;
            // this.userAttributeType = data.value;
            this.memberInfo4Client.clientInfo = [];
            this.memberInfo4Client.memberId = [];
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
            this.visible = false;
        },
        //关闭报单人弹窗
        preserStaff() {
            let staffList = this.staffCustomer.clientInfo;
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
            staffList.forEach((item) => {
                this.userAttributeValue.push(item.id ? item.id : item.memberId);
                this.userAttributeNameValue.push(item.name);
                this.userAttributeObj.push(item);
            });
            this.dialogMemberTree = false;
        },
        onNodeClick(node, type, memberInfo) {
            let isThirdData = (memberInfo.isWeWorkThirdData || (memberInfo.originData.wechatBotDTO.bindType===0&&memberInfo.originData.wechatBotDTO.wechatType===0));
            this.staffCustomer.type = type;

            this.staffCustomer.clientInfo = [];
            let staffCustomer = {
                name: "",
                memberId: "",
                clientType: "",
            };
            let memberList = [];
            let clientInfo = [];
            if (!this.isMultiple) {
                this.$refs.staffCustomer.checkMemberList = [];
                if (type === "customer" || type === "updown") {
                    staffCustomer.name = node.name;
                    staffCustomer.memberId = node.id;
                    if (type === "updown") {
                        this.upDownStreamMemberInfo = memberInfo;
                        this.$emit("chooseUpdown", this.upDownStreamMemberInfo);
                    }
                } else {
                    staffCustomer.name = node.label;
                    staffCustomer.memberId = node.id;
                    staffCustomer.isThirdData = isThirdData;
                }
                staffCustomer.clientType =
                    node.type && node.type === "updown"
                        ? "ASKBOT_UP_DOWN_MEMBER"
                        : "";
                this.staffCustomer.clientInfo = staffCustomer;
            } else {
                // 多选
                let list = [];
                if (type === "customer") {
                    this.$refs.staffCustomer.ergodicMemberTree2(
                        this.$refs.staffCustomer.selectClient,
                        ""
                    );
                    list = this.handlerArr(
                        this.$refs.staffCustomer.checkMemberList
                    );
                    memberList = list;
                    clientInfo = memberList.map((item) => {
                        let info = {
                            name: item.name || item.label,
                            memberId: item.id,
                            clientType:
                                item.type && item.type === "updown"
                                    ? "ASKBOT_UP_DOWN_MEMBER"
                                    : "",
                            isThirdData: isThirdData,
                        };
                        return info;
                    });
                } else if (type === "updown") {
                    this.$refs.staffCustomer.ergodicMemberTree2(
                        this.$refs.staffCustomer.updownDateTree,
                        ""
                    );
                    list = this.handlerArr(
                        this.$refs.staffCustomer.checkMemberList
                    );
                    this.checkMemberList =
                        this.$refs.staffCustomer.checkMemberList;
                    memberList = list;
                    clientInfo = memberList.map((item) => {
                        let info = {
                            isThirdData: isThirdData,
                            name: item.name || item.label,
                            memberId: item.id,
                            clientType:
                                item.type && item.type === "updown"
                                    ? "ASKBOT_UP_DOWN_MEMBER"
                                    : "",
                                
                        };
                        return info;
                    });
                } else {
                    let obj = JSON.parse(JSON.stringify(node));
                    obj.memberId = obj.id;
                    if (node.checked) {
                        this.memberInfo4Client.clientInfo.push(obj);
                    } else {
                        this.memberInfo4Client.clientInfo =
                            this.memberInfo4Client.clientInfo.filter(
                                (client) => {
                                    return client.id != obj.id;
                                }
                            );
                    }
                    clientInfo = this.memberInfo4Client.clientInfo.map(
                        (item) => {
                            let info = {
                                isThirdData: isThirdData,
                                name: item.label || item.name,
                                memberId: item.id,
                                id: item.id,
                                clientType:
                                    item.type && item.type === "updown"
                                        ? "ASKBOT_UP_DOWN_MEMBER"
                                        : "",
                            };
                            return info;
                        }
                    );
                }
                this.staffCustomer.clientInfo = clientInfo;
            }
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                }
            }
            return result;
        },
        lookMore(type) {
            if (type === "com") {
                let id =
                    this.tertiaryNode.company[
                        this.tertiaryNode.company.length - 1
                    ].id;
                this.getCompanyList(id);
            } else {
                let id = this.customerList[this.customerList.length - 1].id;
                this.getClientList(id);
            }
        },
        /**
         * 为element-ui的Select和Cascader添加弹层底部操作按钮
         * @param visible
         * @param refName  设定的ref名称
         * @param onClick  底部操作按钮点击监听
         */
        // visibleChange(visible, refName, onClick) {
        //     if (visible) {
        //         const ref = this.$refs[refName];
        //         let popper = ref.$refs.popper;
        //         if (popper.$el) popper = popper.$el;
        //         if (
        //             !Array.from(popper.children).some(
        //                 (v) => v.className === "el-cascader-menu__list"
        //             )
        //         ) {
        //             const el = document.createElement("ul");
        //             el.className = "el-cascader-menu__list";
        //             el.style =
        //                 "border-top: solid 1px #E4E7ED; padding:0; color: #606266;";
        //             el.innerHTML = `<li class="el-cascader-node" style="height:38px;line-height: 38px"><span class="el-cascader-node__label">创建实体</span>${this.entityValue}</li>`;
        //             popper.appendChild(el);
        //             el.onclick = () => {
        //                 // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
        //                 onClick && onClick();

        //                 // 以下代码实现点击后弹层隐藏 不需要可以删掉
        //                 if (ref.toggleDropDownVisible) {
        //                     ref.toggleDropDownVisible(false);
        //                 } else {
        //                     ref.visible = false;
        //                 }
        //             };
        //         }
        //     }
        // },
        // 条件配置 - 选择类型改变
        conditionTypeChange(value) {
            if (value === "USER_ATTRIBUTE") {
                if (this.userAttributeCustomizeField.type != 'DATE') {
                    this.conditionValue = "EQUAL";
                } else {
                    this.conditionValue = "BEFORE";
                }
            }
        },
        conditionEcho_chi(type, data, tampObj) {
            console.log(tampObj,'tampObj');
            setTimeout(() => {
                let tampArr = JSON.parse(JSON.stringify(this.userAttributeOptions));
                switch (type) {
                    case "edit":
                        this.conditionEditing = {
                            type: type,
                            activeObj: tampObj,
                            index: data,
                        };
                        this.conditionType = tampObj.scope;
                        this.conditionValue = tampObj.relation;
                        if (tampObj.scope === "ENTITY") {
                            this.entityValue = tampObj.ext.entityValue;
                            this.activeEntityValue = tampObj.value;
                        }
                        if (tampObj.scope === "WEB_HOOK_RESPONSE") {
                            this.conditionResponseNum = tampObj.value;
                            this.conditionResponseType = tampObj.scopeType;
                            this.conditionResponseValue = tampObj.ext.variable;
                            this.conditionResponseCondit = tampObj.relation;
                        }
                        if (tampObj.scope === "USER_ATTRIBUTE") {
                            if (tampObj.variable == 'staff_name' || tampObj.variable == 'staff_tags' || tampObj.variable == 'staff_department' || tampObj.variable == 'staff_personalJobLevel') {
                                this.userAttributeType = tampObj.variable.split("_");
                            } else {
                                this.userAttributeType = ['staff' ,tampObj.variable];
                            }
                            
                            this.userAttributeTypeNameText = [];
                            tampArr.forEach((_value,_index) => {
                                // if(this.userAttributeType.indexOf(_value.value) !== -1){
                                //     this.userAttributeTypeNameText.push(_value.label);
                                // }
                                this.userAttributeTypeNameText.push("员工");
                                _value.children.forEach((childrenItem) => {
                                    if(this.userAttributeType.indexOf(childrenItem.value) !== -1){
                                        this.userAttributeTypeNameText.push(childrenItem.label);
                                    }
                                })

                            })
                            if (tampObj.relation == 'BEFORE' || tampObj.relation == 'AFTER') {
                                this.userAttributeValue = tampObj.value;
                            } else {
                                this.userAttributeValue = JSON.parse(tampObj.value);
                            }
                            
                            this.userAttributeNameValue =
                                tampObj.ext.userAttributeNameValue;
                            this.userAttributeObj = tampObj.ext.userAttributeObj;
                            this.userAttributeCustomizeField = tampObj.ext.userAttributeCustomizeField?tampObj.ext.userAttributeCustomizeField:{};
                            if (tampObj.relation == 'BEFORE' || tampObj.relation == 'AFTER') {
                                this.userAttributeCustomizeFieldValue = tampObj.value;
                            } else {
                                this.userAttributeCustomizeFieldValue = JSON.parse(tampObj.value);
                            }
                            
                            if(tampObj.variable.indexOf('tags') !== -1){

                                let tagListIds = [];
                                if (this.tagsOptions && this.tagsOptions.length != 0) {
                                    tagListIds = this.tagsOptions.map(item => item.id);
                                }

                                console.log(1061, tampObj.value);
                                this.tagsValue = JSON.parse(tampObj.value).filter(cell => tagListIds.includes(cell));
                                this.tagsValue = [...new Set(this.tagsValue)];
                                // this.tagsValue = JSON.parse(tampObj.value)
                                console.log("this.tagsValue", this.tagsValue);
                            };
                            if(tampObj.variable.indexOf('personalJobLevel') !== -1){
                                this.personalJobLevelValue = JSON.parse(tampObj.value)
                            };
                        }
                        break;
                    case "addOr":
                        this.conditionType = "ENTITY";
                        this.entityValue = [];
                        this.conditionValue = "EQUAL";
                        this.activeEntityValue = "";
                        this.conditionResponseValue = "";
                        this.conditionResponseNum = "";
                        this.conditionResponseCondit = "";
                        this.conditionEditing = {
                            type: type,
                            activeObj: this.initConfigData,
                            index: data,
                        };
                        break;
                    case "addAnd":
                        this.conditionType = "ENTITY";
                        this.entityValue = [];
                        this.conditionValue = "EQUAL";
                        this.activeEntityValue = "";
                        this.conditionResponseValue = "";
                        this.conditionResponseNum = "";
                        this.conditionResponseCondit = "";
                        this.conditionEditing = {
                            type: type,
                            activeObj: this.initConfigData,
                            index: data,
                        };
                        break;
                }
            },400);
            
        },
        getRelationNameAttr(value) {
            let tampValue = "";
            this.attrRelations.concat(this.dateTimeAttrRelations).forEach((cell) => {
                if (cell.value === value) {
                    tampValue = cell.name;
                }
            });
            return tampValue;
        },
        // 根据id返回意图分类名称
        getRelationName(value) {
            let tampValue = "";
            this.conditionTypes.forEach((item) => {
                item.relations.forEach((cell) => {
                    if (cell.value === value) {
                        tampValue = cell.name;
                    }
                });
            });
            return tampValue;
        },
        // 根据id获取webhook名称和返回值名称
        getWebhookNameAndResName(ids) {
            let obj = {
                webhook: "",
                response: "",
            };
            this.expBaseCascList.forEach((item) => {
                if (item.id == ids[0]) {
                    obj.webhook = item.name;
                    item.configs.forEach((cell) => {
                        if (cell.value == ids[1]) {
                            obj.response = cell.name;
                        }
                    });
                }
            });
            return obj;
        },
        confirmSaveCondiConfig_Chi() {
            let paramObj = { ext: {} };
            paramObj.scope = this.conditionType;
            paramObj.relation = this.conditionValue;

            if (this.conditionType === "ENTITY") {
                paramObj.variable = this.entityValue[1];
                paramObj.value = this.activeEntityValue;
                paramObj.ext = { entityValue: this.entityValue };
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionValue
                );
            }

            if (this.conditionType === "WEB_HOOK_RESPONSE") {
                paramObj.variable =
                    this.conditionResponseValue[0] +
                    "." +
                    this.conditionResponseValue[1];
                paramObj.value = this.conditionResponseNum;
                paramObj.relation = this.conditionResponseCondit;
                paramObj.scopeType = this.conditionResponseType;
                paramObj.ext = this.getWebhookNameAndResName(
                    this.conditionResponseValue
                );
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionResponseCondit
                );
                paramObj.ext.variable = this.conditionResponseValue;
            }
            if (this.conditionType === "USER_ATTRIBUTE") {
                console.log(this.userAttributeType);
                if (
                    this.userAttributeType[0] == 'staff' && (this.userAttributeType[1] == 'name' || this.userAttributeType[1] == 'department' || this.userAttributeType[1] == 'tags' || this.userAttributeType[1] == 'personalJobLevel')
                ) {
                    // 兼容staff name department label personallevel
                    paramObj.variable = this.userAttributeType.join("_");
                } else {
                    paramObj.variable = this.userAttributeType[1];
                }
                
                paramObj.ext.userAttributeTypeName =
                    this.userAttributeTypeNameObj[this.userAttributeType[0]] +
                    this.userAttributeTypeNameObj[this.userAttributeType[1]];
                paramObj.value = JSON.stringify(this.userAttributeValue);
                paramObj.ext.userAttributeNameValue =
                    this.userAttributeNameValue;
                paramObj.ext.userAttributeObj = this.userAttributeObj;
                paramObj.ext.relationName = this.getRelationNameAttr(
                    this.conditionValue
                );
                if(paramObj.relation === "EQUAL" || paramObj.relation === "NOT_EQUAL") {
                    paramObj.value = "空";
                }
                if(this.userAttributeType.indexOf('tags') !== -1){
                    paramObj.value = JSON.stringify(this.tagsValue);
                    paramObj.ext.userAttributeNameValue = [];
                    paramObj.ext.userAttributeObj = [];
                    this.tagsOptions.forEach(tagsItem => {
                        if(this.tagsValue.indexOf(tagsItem.id) !== -1){
                            paramObj.ext.userAttributeObj.push(tagsItem);
                            paramObj.ext.userAttributeNameValue.push(tagsItem.label)
                        }
                    })
                };
                if(this.userAttributeType.indexOf('personalJobLevel') !== -1){
                    paramObj.value = JSON.stringify(this.personalJobLevelValue);
                    paramObj.ext.userAttributeNameValue = [];
                    paramObj.ext.userAttributeObj = [];
                    this.personalJobLevelOptions.forEach(tagsItem => {
                        if(this.personalJobLevelValue.indexOf(tagsItem.id) !== -1){
                            paramObj.ext.userAttributeObj.push(tagsItem);
                            paramObj.ext.userAttributeNameValue.push(tagsItem.label)
                        }
                    })
                };
                if(this.userAttributeCustomizeField.source == 'tongdun'){
                    if (this.userAttributeCustomizeField.type == 'SELECT') {
                        if(Array.isArray(this.userAttributeCustomizeFieldValue)){
                            paramObj.value =  JSON.stringify(this.userAttributeCustomizeFieldValue);
                            paramObj.ext.userAttributeNameValue = this.userAttributeCustomizeFieldValue;
                            paramObj.ext.userAttributeObj = [{name: this.userAttributeCustomizeFieldValue[0]}];
                        } else {
                            paramObj.value =  JSON.stringify([this.userAttributeCustomizeFieldValue]);
                            paramObj.ext.userAttributeNameValue = [this.userAttributeCustomizeFieldValue];
                            paramObj.ext.userAttributeObj = [{name: this.userAttributeCustomizeFieldValue}];
                        }
                        if(paramObj.relation === "EQUAL" || paramObj.relation === "NOT_EQUAL") {
                            paramObj.value = "空";
                        }
                    }
                    if (this.userAttributeCustomizeField.type == 'DATE') {
                        
                        paramObj.ext.userAttributeNameValue = [this.userAttributeCustomizeFieldValue];
                        if ((paramObj.relation == 'BEFORE' || paramObj.relation == 'AFTER') && !!this.userAttributeCustomizeFieldValue) {
                            paramObj.value =  this.userAttributeCustomizeFieldValue;
                            paramObj.ext.userAttributeObj = [{name: new Date(this.userAttributeCustomizeFieldValue).Format("yyyy-MM-dd")}];
                        }
                        console.log(paramObj.relation, this.userAttributeCustomizeFieldValue.length);
                        if ((paramObj.relation == 'BETWEEN' || paramObj.relation == 'NOT_BETWEEN') && this.userAttributeCustomizeFieldValue.length == 2) {
                            console.log(this.userAttributeCustomizeFieldValue);
                            paramObj.value =  JSON.stringify(this.userAttributeCustomizeFieldValue);
                            paramObj.ext.userAttributeObj = [{name: new Date(this.userAttributeCustomizeFieldValue[0]).Format("yyyy-MM-dd") + ' 至 ' + new Date(this.userAttributeCustomizeFieldValue[1]).Format("yyyy-MM-dd")}];
                        }
                    }
                };
                paramObj.ext.userAttributeCustomizeField = this.userAttributeCustomizeField;
                console.log(paramObj,'paramObj');
            }

            let conditionEditing = this.conditionEditing;
            this.$emit("confirmSaveCondiConfig", {
                paramObj,
                conditionEditing,
            });
        },
        getAllEntityByBot() {
            let url = this.requestUrl.entity.getAllEntityBySkill
            if(this.isBotLink){
                url += '?startNode=true'
            } 
            this.FetchGet(url, {
                botId: this.skillId || null,
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                            });
                        });
                        this.entityOptions = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取指定知识库下所有实体
        getAllEntityBySkill(entityId) {
            this.FetchGet(this.requestUrl.entity.getAllEntityBySkill, {
                skillId: this.skillId 
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                                if (entityId == cell.id) {
                                    this.handleChangeEntity([item.label, cell.label]);
                                }
                            });
                        });
                        this.entityOptions = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 选择实体
        handleChangeEntity(e) {
            this.entityValue = e;
            let tampArr = [];
            this.entityOptions.forEach((item) => {
                if (item.value === e[0]) {
                    item.children.forEach((cell) => {
                        if (cell.value === e[1]) {
                            tampArr = cell.content;
                        }
                    });
                }
            });
            tampArr.forEach((item) => {
                item.value = item.standardWord;
                item.label = item.standardWord;
            });
            this.allEntityValue = [...tampArr];
            this.activeEntityValue = "";
        },
        // 实体值选择
        querySearchEntityValue(queryString, cb) {
            if (this.allEntityValue.length == 0) {
                let tampArr = [],
                    e = this.entityValue;
                this.entityOptions.forEach((item) => {
                    if (item.value === e[0]) {
                        item.children.forEach((cell) => {
                            if (cell.value === e[1]) {
                                tampArr = cell.content;
                            }
                        });
                    }
                });
                tampArr.forEach((item) => {
                    item.value = item.standardWord;
                    item.label = item.standardWord;
                });
                this.allEntityValue = [...tampArr];
            }
            var allEntityValue = Array.from(this.allEntityValue);
            allEntityValue.unshift({ label: "空", value: "空" });
            var results = queryString
                ? allEntityValue.filter(
                      this.createFilterEntityValue(queryString)
                  )
                : allEntityValue;
            cb(results);
        },
        // 实体值筛选
        createFilterEntityValue(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        // 实体筛选
        querySearch(queryString, cb) {
            var allEntitys = this.allEntitys;
            var results = queryString
                ? allEntitys.filter(this.createFilter(queryString))
                : allEntitys;
            cb(results);
        },
        // 实体筛选
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        loadAllEntity() {
            return [];
        },
    },
};
</script>
<style lang="less">
.el-popover.choose-condition-config-poprver{
    z-index: 3333 !important;
    padding: 0;
    .el-tree{
        overflow-y: auto;
        color: #606266;
        >.el-tree-node{
            .el-tree-node__content{
                line-height: 33px;
                height: auto;
                min-height: 30px;
                display: flex;
                align-items: center;
            }
            .el-tree-node__children{
                .el-tree-node__content {
                    .el-tree-node__expand-icon{
                        display: none !important;
                    }
                    .data-label{
                        padding-left: 10px;
                    }
                }
            }
        }
        
        .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            line-height: 26px;
            height: auto;
            color:#606266;
            white-space: normal;
            .data-label{
                line-height: 26px;
            }
            &.disabled{
                cursor: not-allowed;
            }
        }
    }

}
</style>
<style lang="less" scoped>
.condition-config {
    .cc-cell {
        margin-bottom: 12px;
        .cc-cell-title {
            margin-bottom: 6px;
            display: block;
            text-align: left;
        }
        .cc-cell-content {
            background-color: #fbfcfd;
            border-radius: 5px;
            padding: 6px;
            text-align: left;
        }
    }
    .select-cell-inner {
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: none;
        }
    }
    .entity-content {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        .select-cell-et {
            flex: none;
            width: 200px;
        }
        .select-cell-eteq {
            flex: none;
            width: 90px;
        }
        .select-cell-etv {
            flex: auto;
        }
        .select-cell-attr {
            flex: none;
            width: 110px;
            display: flex;
            align-items: center;
            .choose-user-attrible{
                text-align: left;
                width: 100%;
                height: 100%;
                cursor: pointer;
                padding: 0 15px;
                color: #606266;
                &.empty-user-attrible{
                    color: #d5d5d5;
                }
            }
        }
        .select-cell-attreq {
            flex: none;
            width: 100px;
            display: flex;
            align-items: center;
        }
        .select-cell-attrv {
            flex: auto;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 6px;
            &.no-need-pad{
                padding: 0;
            }
            .attr-value {
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            .select-cell-attrv-placeholder {
                color: #c0c4cc;
                cursor: pointer;
            }
        }
    }
    .intent-content {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        .select-cell-rp {
            flex: auto;
        }
        .select-cell-rpt {
            flex: none;
            width: 60px;
        }
        .select-cell-rpeq {
            flex: none;
            width: 110px;
        }
        .select-cell-rpv {
            flex: none;
            width: 100px;
        }
    }
}
</style>