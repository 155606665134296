var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-video"},[_c('div',{staticClass:"respose-video"},[_c('div',{staticClass:"album albumvideo"},[_c('div',[_c('div',{staticClass:"pic_img"},[_c('div',{staticClass:"pic_img_box"},[_c('el-upload',{staticClass:"upload-ask",attrs:{"show-file-list":false,"http-request":((data) => {
                                return _vm.aliyunOssRequest(data)
                            }),"before-upload":(file) => _vm.beforeUploadVideo(file, _vm.index),"on-exceed":_vm.uploadExceed,"on-success":(res, file) =>
                                        _vm.handleVideoSuccess(res, file, _vm.index),"on-remove":_vm.handleRemove,"auto-upload":true,"accept":".mp4,.MP4,.ogg,.OGG,.avi,.AVI,.mov,.MOV,.flv,.FLV,.wmv,.WMV","multiple":""}},[(_vm.unit.content.url != '' &&
                                    !_vm.unit.content.videoFlag
                                    )?_c('video',{staticClass:"avatar video-avatar",attrs:{"width":"248px","height":"148px","controlslist":"nodownload","object-fit":"fill","src":_vm.unit.content.url,"muted":"true","controls":"controls"},domProps:{"muted":true}},[_vm._v(" "+_vm._s(_vm.$t('flowComponent.noSupportVideo'))+" ")]):(_vm.unit.content.url == '' &&
                                    !_vm.unit.content.videoFlag
                                    )?_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}):_vm._e(),(_vm.unit.content.videoFlag == true)?_c('el-progress',{staticStyle:{"margin-top":"7px"},attrs:{"type":"circle","percentage":_vm.unit.content.videoUploadPercent}}):_vm._e()],1)],1)])]),_c('p',{staticClass:"Upload_pictures"},[_c('span'),_c('span',[_vm._v(_vm._s(_vm.$t('flowComponent.uploadVideoTip')))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }