<template>
    <div class="version-tip">
        <div class="vt-name">
            <span class="arsenal_icon arsenalshiticengguanli"></span
            >{{ tipList[Number(tipIndex)].name }}
        </div>
        <div v-html="tipList[Number(tipIndex)].content" class="vt-content">{{ tipList[Number(tipIndex)].content }}</div>
        <div class="vt-point">{{ tipList[Number(tipIndex)].point }}</div>
    </div>
</template>
<script>
export default {
    props: ["tipIndex"],
    data() {
        return {
            tipList: [
                {
                    name: "我的知识库模板",
                    content:
                        "用于管理企业自有知识库，可将企业自有知识库作为基础模板供其他机器人使用，知识库模板更新会自动同步到其他机器人中，常用于不同大区意图相同但答案不同等场景。",
                },
                {
                    name: "WebHook",
                    content:
                        "可实现企业业务系统的数据与机器人打通，通过机器人调用业务系统的某些功能。应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "WebHook组件",
                    content:
                        "可在对话流程中调用WebHook接口，实现企业业务系统的数据与机器人打通，通过机器人调用业务系统的某些功能。<br/>应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "机器人管理",
                    content:
                        `您当前的版本只能创建${localStorage.getItem("_bot_version_num")}个机器人，如您想要创建更多机器人，请联系我们~`,
                },
                {
                    name: "字段管理与表单管理",
                    content:
                        "可配置表单的字段与表单字段布局，与WebHook配合使用。应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "提交表单组件",
                    content:
                        "可将机器人的数据通过表单提交给业务系统。<br/>应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "意图管理",
                    content:
                        `您当前的版本只能创建${localStorage.getItem("_bot_version_intent_num")}个意图，如您想要创建更多意图，请联系我们~`,
                    point: "注：可将已关闭的意图删除释放可创建意图数。",
                },
            ],
        };
    },
};
</script>
<style lang="less" scoped>
.version-tip {
    .vt-name {
        height: 48px;
        line-height: 48px;
        background: #f6f8fd;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        color: #366aff;
        .arsenal_icon {
            display: inline-block;
            font-size: 15px;
            margin-right: 6px;
        }
    }
    .vt-content {
        margin-top: 24px;
        text-align: left;
    }

    .vt-point {
        margin-top: 16px;
        font-size: 12px;
        color: #A9B3C6;
        text-align: left;
        margin-bottom: 24px;
    }
}
</style>