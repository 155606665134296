<template>
    <div class="copy_compontent">
        <!-- <el-input type="textarea" ref="textarea" class="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入/粘贴答案 后再转换成组件" v-model="copyHtml">
        </el-input> -->
        <ckeditor class="textarea" :editor="ckeditor.editor" ref="ckeditor" v-model="copyHtml" :config="desEditorConfig" @ready="readyFn"
            @focus="(zh, editor) => detailCurrentEditor = editor">
        </ckeditor>
        <ul class="file_list">
            <li v-for="(file, index) in  fileList" :key="index">
                <span @click="delFile">
                    <i class="iconfont guoran-a-16-09"></i>
                </span>
                <section>
                    {{ file.name }}
                </section>
            </li>
        </ul>
        <div class="btn_footer">
            <!-- <el-button type="primary" size="mini" @click="getClipboardContents">
                粘贴
            </el-button> -->
            <div></div>
            <el-button type="primary" size="mini" @click="addComponnent">
                {{$t('flowComponent.generateAnswers')}}
            </el-button>
        </div>
    </div>
</template>
<script>
import MyFileUpload from './component/answer/js/plugin_file';
import { MyCustomUploadAdapterPlugin } from "./component/answer/js/ckeditor";
import CKEDITOR from "ckeditor";
import { v4 as uuidv4 } from "uuid";
import { multipartUpload, ossFileUrl } from "./component/answer/js/AliyunIssUtil";
// MyCustomUploadAdapterPlugin
export default {
    data () {
        return {
            ckeditor: {
                editor: CKEDITOR.ClassicEditor,
            },
            desEditorConfig: {
                placeholder: this.$t('common.desEditorConfig'),
                extraPlugins: [MyFileUpload, MyCustomUploadAdapterPlugin],
                toolbar:[]
            },
            copyHtml: '',
            fileList: [],
            copyConetnt: {
                text: '',
                textHtml: '',
                textRtf: false,
                richFlag:false
            },
            detailCurrentEditor: null
        }
    },
    methods: {
        readyFn(editor) {
            this.detailCurrentEditor = editor;
            this.detailCurrentEditor.editing.view.document.on('paste', (evt, data) => {
                // evt.stop();
                //     if (data.dataTransfer._native.effectAllowed === "move") { // 拖动阻止生成富文本内容
                //         evt.stop();
                //     }
                //     let { files } = data.dataTransfer
                //     console.log(files);
                //     if (files.length > 0) {
                //         for (let index = 0; index < files.length; index++) {
                //             if (files[index].type.indexOf('video') != -1) {
                //                 if (this.fileVerification('video', files[index])) {
                //                     this.fileList.push(files[index])
                //                     evt.stop();
                //                 }
                //             } else if (files[index].type.indexOf('image') != -1) {
                //                 if (this.fileVerification('image', files[index])) {
                //                     this.fileList.push(files[index])
                //                     evt.stop();
                //                 }
                //             } else {
                //                 this.fileList.push(files[index])
                //                 evt.stop();
                //             }
                //         }
                //     }
            })
        },
        openCopyTeartext () {
        },
        async getClipboardData (event) {
            let { files, items } = event.clipboardData
            console.log(files);
            if (files.length > 0) {
                for (let index = 0; index < files.length; index++) {
                    if (files[index].type.indexOf('video') != -1) {
                        if (this.fileVerification('video', files[index])) {
                            this.fileList.push(files[index])
                            event.preventDefault()
                        }
                    } else if (files[index].type.indexOf('image') != -1) {
                        if (this.fileVerification('image', files[index])) {
                            this.fileList.push(files[index])
                            event.preventDefault()
                        }
                    } else {
                        this.fileList.push(files[index])
                        event.preventDefault()
                    }
                }
            } else {
                this.copyConetnt.text = event.clipboardData.getData('text/plain')
                this.copyConetnt.textHtml = event.clipboardData.getData('text/html')
                this.copyConetnt.textRtf = !!event.clipboardData.getData('text/rtf')
                if (this.copyConetnt.textRtf) {
                    this.$message.warning('粘贴图片失败。请单独选中原图复制粘贴')
                    this.copyConetnt.textRtf = false
                }
                let tableReg = /<table[^>]*>((?!table).)*<\/table>/gi
                let liReg = /<li[^>]*>(.*?)<\/li>/gi
                let aReg = /<a[^>]*>(.*?)<\/a>/g
                // 符合条件为富文本
                if(tableReg.test(this.copyConetnt.textHtml) || liReg.test(this.copyConetnt.textHtml) || aReg.test(this.copyConetnt.textHtml)) {
                    this.copyConetnt.richFlag = true
                } else {
                    this.copyHtml = this.copyConetnt.text
                    event.preventDefault()
                }
            }
        },
        async getClipboardContents () {
            try {
                const clipboardItems = await navigator.clipboard.read();
                let textHtml = ''
                let textPlain = ''
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        const item = await clipboardItem.getType(type);
                        if (type == 'text/html') {
                            textHtml = await item.text();
                        } else if (type == 'text/plain') {
                            textPlain = await item.text();
                        }
                    }
                }
                this.copyConetnt.text = textPlain
                this.copyConetnt.textHtml = textHtml
                if(this.copyConetnt.textHtml) {
                    this.copyHtml = this.copyConetnt.textHtml
                } else {
                    this.copyHtml = this.copyConetnt.text
                }
            } catch (err) {
                this.$message.warning('粘贴失败，请单独选中内容粘贴')
                console.error(err.name, err.message);
            }
        },
        fileVerification (type, file) {
            if (type == 'image') {
                if(!['image/JPG','image/JPEG','image/PNG','image/jpeg','image/jpg','image/png','image/GIF','image/gif'].includes(file.type)) {
                    this.$message.error(
                        "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                    );
                    return false
                }
                if (file.size / 1024 / 1024 > 2) {
                    this.$message.error(
                        "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                    );
                    return false
                }
                return true
            }
            if (type == 'video') {
                if (!["video/mp4","video/ogg","video/flv","video/avi","video/wmv","video/rmvb","video/mov","video/quicktime",].includes(file.type)) {
                    this.$message.error("请上传正确的视频格式");
                    return false;
                }
                if (file.size / 1024 / 1024 > 500) {
                    this.$message.error("视频大小不能超过500MB");
                    return false;
                }
                return true
            }
        },
        delFile (index) {
            this.fileList.splice(index, 1)
        },
        addComponnent() {
            // 如果只包含图片 生成图片节点
            // 如果只包含视频 生成视频节点
            // 有内容直接赋值给富文本
            // 其余都生成富文本节点
            let str = this.detailCurrentEditor.getData().replace(/<[^>]*>/ig,"")
            if(str) {
                console.log('富文本');
                this.$emit('addRichText', this.fileList, this.copyHtml)
            } else {
                // 没有内容有图片的情况生成图片节点
                let imgStrs = this.detailCurrentEditor.getData().match(/<img.*?>/g)
                if(this.fileList.length != 0) {
                    if(this.fileList.some(file =>{return !file.type.includes('video')})) {
                        console.log('富文本');
                        this.$emit('addRichText', this.fileList, this.copyHtml)
                        // 富文本
                    } else if(imgStrs && imgStrs.length != 0) {
                        console.log('富文本');
                        this.$emit('addRichText', this.fileList, this.copyHtml)
                        // 富文本
                    } else {
                        // 视频
                        console.log('视频');
                        this.$emit('addCopyitem', 'video', this.fileList)
                    }
                } else if(imgStrs && imgStrs.length > 0){ // 有图片
                    let urls = imgStrs.map(url=>{
                        return url.match(/\ssrc=['"](.*?)['"]/)[1]
                    })
                    // 富文本
                    console.log('图片');
                    this.$emit('addCopyitem', 'image', urls)
                }
            }
            this.fileList = []
            this.copyHtml = ''
            
        }
    },
    mounted () {
        // this.$nextTick(() =>{
            
        // })
        setTimeout(() => {
            let Editor = document.querySelector('.copy_compontent .ck-content')
            Editor.addEventListener('paste', (e)=>{
                let { files, items } = e.clipboardData
                // console.log(files);
                if (files.length > 0) {
                    for (let index = 0; index < files.length; index++) {
                        if (files[index].type.indexOf('video') != -1) {
                            if (this.fileVerification('video', files[index])) {
                                this.fileList.push(files[index])
                                e.preventDefault();
                            }
                        } else if (files[index].type.indexOf('image') != -1) {
                            // if (this.fileVerification('image', files[index])) {
                            //     this.fileList.push(files[index])
                            //     evt.stop();
                            // }
                        } else {
                            this.fileList.push(files[index])
                            e.preventDefault();
                        }
                    }
                }
                let str = e.clipboardData.getData('text/html')
                if(str) {
                    let rtfContent = str
                    rtfContent = rtfContent.replace(/<!--[\s\S]*?-->/gmi, '');
                    rtfContent = rtfContent.replace(/ style=".*?"/g, '')
                    let r = /<img.*?>/g
                    if(r.test(rtfContent)) {
                        rtfContent = rtfContent.replace(/<img.*?>/g, '')
                        this.$message.warning('粘贴图片失败。请单独选中原图复制粘则')
                    }
                    if(rtfContent.includes('v:imagedata')) {
                        this.$message.warning('粘贴图片失败。请单独选中原图复制粘则')
                    }
                    // let viewFragment = this.detailCurrentEditor.data.processor.toView(rtfContent);
                    // let modelFragment = this.detailCurrentEditor.data.toModel( viewFragment );
                    // this.detailCurrentEditor.model.insertContent( modelFragment );
                    e.preventDefault();
                }
            })
        }, 100);
    }
}
</script>

<style lang="less" scoped>
.copy_compontent {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    /deep/.ck-content {
        min-height: 38px;
        max-height: 260px;
        border: 1px dashed #366AFF !important;
        border-radius: 10px !important;
    }
    /deep/ .ck-toolbar {
        display: none;
    }
    /deep/.ck-placeholder:before {
        color: #ccc;
    }
    .file_list {
        margin-top: 10px;
        width: 100%;

        li {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;

            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                line-height: 25px;
                cursor: pointer;

                i {
                    color: #366AFF;
                }
            }

            section {
                text-indent: 8px;
                text-align: left;
                color: #636363;
                width: calc(100% - 25px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .btn_footer {
        margin-top: 10px;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        box-sizing: border-box;
        .el-button {
            border-radius: 17px;
        }
        .btn_left {
            display: flex;
            align-content: flex-end;
            .iconfont {
                margin-left: 8px;
                color: #636363;
            }
        }
    }

}
</style>