<template>
    <div class="input-insert-entity">
        <ckeditor v-if="placeholderType == 'usual-text-placeholder'" class="gr-answer-text-ckeditor" :editor="editorInline"
            :config="editorConfig" v-model="unit.content.list[textCellInd]" @focus="onEditorFocus" @blur="onEditorBlur"
            @ready="(editor) => currentEditor = editor">
        </ckeditor>
        <ckeditor v-if="placeholderType == 'usual-aq-placeholder'" class="gr-answer-text-ckeditor" :editor="editorInline"
            :config="editorConfig" v-model="unit.content.questions[textCellInd]" @focus="onEditorFocus" @blur="onEditorBlur"
            @ready="(editor) => currentEditor = editor">
        </ckeditor>
        <span @click="templateConfigVisibleHandle(placeholderType, textCellInd)" class="insert-var">
            <i class="iconfont guoran-tongyichicun-bianliang"></i>
        </span>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
    props: ["unit", "unitIndex", "textCellInd", "placeholderType"],
    data() {
        return {
            editorDataInline: "",
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: ["undo", "redo"],
            },
            currentEditorId: "",
            currentEditorPosition: "",
            currentEditor: null
        }
    },
    methods: {
        onEditorFocus(zh, editor) {
            this.currentEditorPosition = this.currentEditor.model.document.selection.getFirstPosition();
        },
        onEditorBlur() {
            console.log('onEditorBlur');
        },
        templateConfigVisibleHandle(type, cellIndex) {
            if (this.currentEditorPosition == '') {
                this.currentEditor.model.change((writer) => {
                    writer.setSelection(writer.createPositionAt(this.currentEditor.model.document.getRoot(), 'end'))
                })
            }
            // this.currentEditorPosition = this.currentEditor.model.document.selection.getFirstPosition();
            this.$emit("templateConfigVisibleHandle", { type: type, cellIndex: cellIndex })
        },
        addConfirmDataResponse() {
            let command = this.currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: "单个webhook返回值",
                    editable: false,
                    data: webHookDataHolder(51, "data.name"),
                },
            });
        },

        addWebHookSinglePlace(data, webhookId) {
            let command = this.currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },

        addConfirmDataEntity(data, editable) {
            let command = this.currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: entityDataHolder(data.name),
                },
            });
        },
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.input-insert-entity {
    width: 100%;
    display: flex;
    align-items: center;

    .gr-answer-text-ckeditor {
        flex: auto;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding-right: 48px;
    }

    .ck-focused {
        border: 1px solid #366aff;
    }

    .insert-var {
        flex: none;
        color: black;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background-color: #DCDFE6;
        border-radius: 4px;
        cursor: pointer;
        margin-left: -38px;
        margin-right: 6px;
        text-align: center;
    }
}
</style>